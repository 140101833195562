import { Component, OnInit } from '@angular/core'
import { Sdk } from 'src/app/core/domain/models/sdk.model'
import { GetAllSdksAndCheckUserPermission } from 'src/app/core/domain/usecases/get-all-sdks-and-check-user-permission'
import { ChartHelper } from 'src/app/core/helpers/chart.helper'
import { GetSdkStatus } from '../../domain/usecases/get-sdk-status'

@Component({
  selector: 'app-status-sdk',
  templateUrl: './status-sdk.component.html',
  styleUrls: ['./status-sdk.component.css'],
})
export class StatusSdkComponent implements OnInit {
  sdkList: Sdk[] = []
  withoutPermission: string

  constructor(
    private chartHelper: ChartHelper,
    private getAllSdksAndCheckUserPermission: GetAllSdksAndCheckUserPermission,
    private getSdkStatus: GetSdkStatus
  ) {}

  ngOnInit(): void {
    this.listAllSdks()
  }

  listAllSdks() {
    this.getAllSdksAndCheckUserPermission.execute().subscribe((data) => {
      if (!data.hasPermission) {
        this.withoutPermission = 'Sem permissão para listar sdks!'
        return
      }

      this.sdkList = data.sdks
      let self = this

      this.getSdkStatus.execute(this.sdkList).subscribe((sdks) => {
        self.sdkList = sdks
      })
    })
  }

  formatPercNumber(value: string): number {
    if (value) value = value.replace(',', '.')
    return Number(value)
  }

  getKnobChartColor(value: number): string {
    return this.chartHelper.getKnobChartColor(value)
  }
}
