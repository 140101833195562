import { Component, OnInit } from '@angular/core'
import { ConvertIsoDateToString } from 'src/app/core/domain/usecases/convert-iso-date-to-string'
import { ConvertIsoStringToDate } from 'src/app/core/domain/usecases/convert-iso-string-to-date'
import { GetParamFromUrl } from 'src/app/core/domain/usecases/get-param-from-url'
import { SetParamOnUrl } from 'src/app/core/domain/usecases/set-param-on-url'
import { TransactionsSummary } from 'src/app/modules/transactions/domain/models/transactions-summary.model'
import { Company } from '../../../companies/domain/models/company.model'
import { FilterTransactionsSummary } from '../../domain/models/filter-transactions-summary'
import { TransactionType } from '../../domain/models/transaction-type.enum'
import { TransactionsSummaryChartData } from '../../domain/models/transactions-summary-chart-data'
import { GetTransactionsSummary } from '../../domain/usecases/get-transactions-summary'
import { MapTransactionSummaryToChartData } from '../../domain/usecases/map-transaction-summary-to-chart-data'
import { BioAnalyticsService } from '../../services/bio-analytics.service'
import { AddDayMonthYearToDate } from 'src/app/core/domain/usecases/add-day-month-year-to-date'

@Component({
  selector: 'app-transactions-summary',
  templateUrl: './transactions-summary.component.html',
  styleUrls: ['./transactions-summary.component.css'],
})
export class TransactionsSummaryComponent implements OnInit {
  summary: TransactionsSummaryChartData = undefined

  filterParams: FilterTransactionsSummary = undefined

  maxDateValue?: Date

  activeViewMode: number

  activeCompany: string

  private defaultDateRange = this.getDefaultDateRange()

  constructor(
    private bioAnalyticsService: BioAnalyticsService,
    private mapTransactionSummaryToChartData: MapTransactionSummaryToChartData,
    private getTransactionsSummary: GetTransactionsSummary,
    private getParamFromUrl: GetParamFromUrl,
    private setParamOnUrl: SetParamOnUrl,
    private convertStringToDate: ConvertIsoStringToDate,
    private convertIsoDateToString: ConvertIsoDateToString,
    private addDayMonthYearToDate: AddDayMonthYearToDate
  ) {}

  ngOnInit(): void {
    this.activeCompany = this.getParamFromUrl.execute('company')
    this.setMaxDateValue()
    this.firstFilterTransactionsCall()
  }

  private setMaxDateValue() {
    this.maxDateValue = new Date()
    this.maxDateValue.setDate(this.maxDateValue.getDate() - 1)
  }

  private firstFilterTransactionsCall() {
    this.filterParams = this.getFilterParamsFromUrl()
    this.callGetTransactionsSummary(this.filterParams)
  }

  private callGetTransactionsSummary(filterParams: FilterTransactionsSummary) {
    this.getTransactionsSummary
      .execute(filterParams)
      .subscribe((response: TransactionsSummary) => {
        this.setSummary(response)
      })
  }

  private setSummary(response: TransactionsSummary) {
    this.summary = this.mapTransactionSummaryToChartData.execute(response)
  }

  private getFilterParamsFromUrl(): FilterTransactionsSummary {
    let dateFrom = this.getDateFromUrl('dateFrom')
    if (!dateFrom) {
      dateFrom = this.defaultDateRange.dateFrom
    }

    let dateTo = this.getDateFromUrl('dateTo')
    if (!dateTo) {
      dateTo = this.defaultDateRange.dateTo
    }

    this.setParamOnUrl.execute({
      dateFrom: this.convertIsoDateToString.execute(dateFrom),
      dateTo: this.convertIsoDateToString.execute(dateTo),
    })

    return {
      dateTo: dateTo,
      dateFrom: dateFrom,
      transactionType: this.getTransactionTypeFromUrl(),
      companyNumber: this.getParamFromUrl.execute('company'),
    }
  }

  private getDateFromUrl(param: string): Date {
    return this.convertStringToDate.execute(this.getParamFromUrl.execute(param))
  }

  private getDefaultDateRange() {
    const dateTo = this.addDayMonthYearToDate.execute({ days: -1 })

    const dateFrom = this.addDayMonthYearToDate.execute({
      days: -1 * this.bioAnalyticsService.getDefaultDateRangeInDays,
    })

    return {
      dateFrom,
      dateTo,
    }
  }

  setDateRange(range: any) {
    const dateTo = this.convertIsoDateToString.execute(range.dateTo)
    const dateFrom = this.convertIsoDateToString.execute(range.dateFrom)

    this.setParamOnUrl.execute({
      dateTo,
      dateFrom,
    })

    this.filterParams.dateTo = range.dateTo
    this.filterParams.dateFrom = range.dateFrom
  }

  filter() {
    this.callGetTransactionsSummary(this.filterParams)
  }

  private getTransactionTypeFromUrl(): TransactionType {
    const type = this.getParamFromUrl.execute('transactionType')
    return TransactionType[
      type as keyof typeof TransactionType
    ] as TransactionType
  }

  selectedCompany(data: { id: string }): void {
    this.filterParams.companyNumber = data.id
    this.setParamOnUrl.execute({ company: data.id })
  }

  setActiveViewMode(activeViewMode: number): void {
    this.activeViewMode = activeViewMode
  }
}
