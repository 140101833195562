import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { ResourceService } from '../../services/resource.service'
import { Resource } from '../models/resource.model'

@Injectable({ providedIn: 'root' })
export class SaveUserPermission
  implements UseCase<Resource, Observable<string>>
{
  constructor(private resourceService: ResourceService) {}

  execute(permission: Resource): Observable<string> {
    return this.resourceService.saveResource(permission)
  }
}
