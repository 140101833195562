import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ListCompaniesComponent } from './pages/list-companies/list-companies.component'
import { CreateCompanyComponent } from './pages/create-company/create-company.component'
import { EditCompanyComponent } from './pages/edit-company/edit-company.component'
import { AuthGuard } from 'src/app/core/security/auth.guard'

const routes: Routes = [
  { path: '', canActivate: [AuthGuard], component: ListCompaniesComponent },
  {
    path: 'create',
    canActivate: [AuthGuard],
    component: CreateCompanyComponent,
  },
  {
    path: 'companies/:id',
    canActivate: [AuthGuard],
    component: EditCompanyComponent,
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompaniesRoutingModule {}
