import { NgModule } from '@angular/core'
import { AccordionModule } from 'primeng/accordion'
import { ConfirmationService, MessageService } from 'primeng/api'
import { ButtonModule } from 'primeng/button'
import { CalendarModule } from 'primeng/calendar'
import { CardModule } from 'primeng/card'
import { ChartModule } from 'primeng/chart'
import { CheckboxModule } from 'primeng/checkbox'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DialogModule } from 'primeng/dialog'
import { DividerModule } from 'primeng/divider'
import { DropdownModule } from 'primeng/dropdown'
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog'
import { FieldsetModule } from 'primeng/fieldset'
import { FileUploadModule } from 'primeng/fileupload'
import { InputMaskModule } from 'primeng/inputmask'
import { InputNumberModule } from 'primeng/inputnumber'
import { InputSwitchModule } from 'primeng/inputswitch'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { KnobModule } from 'primeng/knob'
import { ListboxModule } from 'primeng/listbox'
import { MenubarModule } from 'primeng/menubar'
import { MultiSelectModule } from 'primeng/multiselect'
import { PaginatorModule } from 'primeng/paginator'
import { PanelModule } from 'primeng/panel'
import { PanelMenuModule } from 'primeng/panelmenu'
import { PasswordModule } from 'primeng/password'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { RadioButtonModule } from 'primeng/radiobutton'
import { RippleModule } from 'primeng/ripple'
import { SidebarModule } from 'primeng/sidebar'
import { TableModule } from 'primeng/table'
import { TabViewModule } from 'primeng/tabview'
import { ToastModule } from 'primeng/toast'
import { ToggleButtonModule } from 'primeng/togglebutton'
import { ToolbarModule } from 'primeng/toolbar'
import { TabMenuModule } from 'primeng/tabmenu'

@NgModule({
  exports: [
    CardModule,
    ChartModule,
    DropdownModule,
    InputTextareaModule,
    ButtonModule,
    PanelModule,
    InputMaskModule,
    PanelMenuModule,
    MenubarModule,
    DividerModule,
    ToolbarModule,
    SidebarModule,
    DialogModule,
    ConfirmDialogModule,
    TableModule,
    CheckboxModule,
    AccordionModule,
    CalendarModule,
    MultiSelectModule,
    KnobModule,
    FieldsetModule,
    ToastModule,
    RadioButtonModule,
    TabViewModule,
    FileUploadModule,
    PasswordModule,
    ListboxModule,
    PaginatorModule,
    InputSwitchModule,
    ToggleButtonModule,
    ProgressSpinnerModule,
    RippleModule,
    InputNumberModule,
    DynamicDialogModule,
    TabMenuModule,
  ],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class PrimeNgModule {}
