<app-title title="CONTROLE DE LOGS"></app-title>

<div class="p-col" style="max-width: 100%;">
    <p-card>
        <div class="card" id="table-card">
            <p-table [value]="logs" [scrollable]="true" [resizableColumns]="true" [rows]="5" [rowHover]="true"
                dataKey="id" [showCurrentPageReport]="true">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <!--<h4 class="p-m-0">Busca</h4>-->
                        <span class="p-input">
                            <label for="filterDescription" class="p-mr-2">Descrição</label>
                            <input pInputText id="filterDescription" [(ngModel)]="logFilter.description" type="text"
                                (keyup.enter)="searchLogs()" />
                        </span>
                        <span class="p-input">
                            <label for="filterDatetimeFrom" class="p-mr-2">De</label>
                            <p-calendar id="filterDatetimeFrom" appendTo="body" dateFormat="dd/mm/yy" hourFormat="HH:mm"
                                [(ngModel)]="logFilter.logDateFrom" showTime="true" showButtonBar="true"
                                (onClose)="searchLogs()"></p-calendar>
                        </span>
                        <span class="p-input">
                            <label for="filterDatetimeTo" class="p-mr-2">Até</label>
                            <p-calendar id="filterDatetimeTo" appendTo="body" dateFormat="dd/mm/yy" hourFormat="HH:mm"
                                [(ngModel)]="logFilter.logDateTo" showTime="true" showButtonBar="true"
                                (onClose)="searchLogs()"></p-calendar>
                        </span>
                        <span class="p-input">
                            <label for="filterUser" class="p-mr-2">Usuário</label>
                            <p-dropdown id="filterUser" [options]="usersFilter" optionLabel="name"
                                [(ngModel)]="logFilter.user" (onChange)="searchLogs()"></p-dropdown>
                        </span>
                        <button pButton pRipple class="primaryButton p-button-rounded" (click)="searchLogs()"
                            label="Filtrar" icon='pi pi-filter'></button>
                    </div>
                </ng-template>
                <ng-template pTemplate="colgroup">
                    <colgroup>
                        <col style="width: 20px">
                        <col style="width: 200px">
                        <col style="width: 50px">
                        <col style="width: 50px">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th>Descrição</th>
                        <th>Usuário</th>
                        <th>Data/hora</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-log>
                    <tr>
                        <td><button pButton pRipple icon="pi pi-search-plus"
                                title="Clique para visualizar o log completo"
                                class="secundaryButton p-button-rounded p-button-sm"
                                (click)="showFullLog($event, log)"></button>
                        </td>
                        <td>{{log.description}}</td>
                        <td>{{log.user.name}}</td>
                        <td>{{log.logDate | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" *ngIf="withoutPermission">
                    <tr>
                        <td colspan="8" style="text-align: center">{{withoutPermission}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Total: {{ count }} log{{ count > 1 ? 's' : '' }}
                    </div>
                </ng-template>
            </p-table>
            <p-paginator [rows]="10" (onPageChange)=changePage($event) [totalRecords]="count"></p-paginator>
        </div>
    </p-card>
</div>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
<p-toast></p-toast>