import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { ColorGeneratorHelper } from 'src/app/core/helpers/color-generator.helper'
import { RejectedTransactionsLineChartData } from '../../domain/models/rejected-transactions-line-chart-data'

@Component({
  selector: 'app-rejected-transactions-rate-line-chart',
  templateUrl: './rejected-transactions-rate-line-chart.component.html',
  styleUrls: ['./rejected-transactions-rate-line-chart.component.css'],
})
export class RejectedTransactionsRateLineChartComponent implements OnChanges {
  @Input() data: RejectedTransactionsLineChartData

  _data: any
  _options = {
    yAxes: {
      interval: [0, 100],
      formatLabel: (label: number) => {
        return `${label}%`
      },
    },
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.setData(changes.data.currentValue)
    }
  }

  private setData(data: RejectedTransactionsLineChartData): void {
    const colorGenerator = new ColorGeneratorHelper()
    this._data = {
      labels: data.labels,
      datasets: data.datasets.map((ds) => {
        const color = colorGenerator.getNextColor()
        return {
          label: ds.name,
          data: ds.values,
          fill: false,
          borderColor: color,
          backgroundColor: color,
        }
      }),
    }
  }
}
