import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import { SdkType } from '../../domain/models/sdk-release'

interface SdkTypeSelectData {
  id: string
  name: string
  type?: SdkType
}

@Component({
  selector: 'app-sdk-type-select',
  templateUrl: './sdk-type-select.component.html',
  styleUrls: ['./sdk-type-select.component.css'],
})
export class SdkTypeSelectComponent implements OnChanges {
  @Input() initialType?: SdkType
  @Output() onSelect = new EventEmitter<SdkType>()

  sdkTypes: SdkTypeSelectData[] = [
    { id: '0', name: 'Nenhum' },
    {
      id: '1',
      name: SdkType.friendlyName(SdkType.BROWSER),
      type: SdkType.BROWSER,
    },
    {
      id: '2',
      name: SdkType.friendlyName(SdkType.ANDROID),
      type: SdkType.ANDROID,
    },
    { id: '3', name: 'iOS', type: SdkType.IOS },
    {
      id: '4',
      name: SdkType.friendlyName(SdkType.SERVER),
      type: SdkType.SERVER,
    },
  ]

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialType?.currentValue) {
      this.initialType = changes.initialType.currentValue
    }
  }

  onSelectedType(sdkType: SdkTypeSelectData): void {
    if (sdkType.id && sdkType.id != this.sdkTypes[0].id) {
      this.onSelect.emit(SdkType[sdkType.type])
    }
  }
}
