import { Injectable } from '@angular/core'
import { CompanyNew } from 'src/app/modules/companies/domain/models/company-new.model'
import { CompanyHelper } from '../../helpers/company.helper'
import { Constants } from '../../helpers/constants'
import { UseCase } from './usecase'

@Injectable({ providedIn: 'root' })
export class GetLoggedUserCompany implements UseCase<void, CompanyNew> {
  constructor(private companyHelper: CompanyHelper) {}

  execute(_: void): CompanyNew {
    const c = this.companyHelper.getCompanyFromLoggedUser()
    const company = new CompanyNew()
    company.id = c.id
    company.name = c.name
    company.cnpj = c.cnpj || Constants.certisignCNPJ
    return company
  }
}
