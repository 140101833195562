import { Injectable } from '@angular/core'
import { UseCase } from 'src/app/core/domain/usecases/usecase'

type Password = string

@Injectable({ providedIn: 'root' })
export class GenerateRandomPassword implements UseCase<void, Password> {
  private characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  execute(_: void): string {
    const length = 5
    let result = ''

    const charactersLength = this.characters.length
    let counter = 0
    while (counter < length) {
      result += this.characters.charAt(
        Math.floor(Math.random() * charactersLength)
      )
      counter += 1
    }

    return result
  }
}
