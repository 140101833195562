import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SdkReleaseFormComponent } from './components/sdk-release-form/sdk-release-form.component'
import { SdkReleaseNoteListComponent } from './components/sdk-release-note-list/sdk-release-note-list.component'
import { SdkReleaseComponent } from './components/sdk-release/sdk-release.component'
import { SdkTypeSelectComponent } from './components/sdk-type-select/sdk-type-select.component'
import { SdksTabMenuComponent } from './components/sdks-tab-menu/sdks-tab-menu.component'
import { CreateReleaseComponent } from './pages/create-release/create-release.component'
import { LatestReleasesComponent } from './pages/latest-releases/latest-releases.component'
import { ListSdksComponent } from './pages/list-sdks/list-sdks.component'
import { StatusSdkComponent } from './pages/status-sdk/status-sdk.component'
import { SDKsRoutingModule } from './sdks-routing.module'
import { CoreModule } from 'src/app/core/core.module'

@NgModule({
  declarations: [
    ListSdksComponent,
    StatusSdkComponent,
    SdksTabMenuComponent,
    CreateReleaseComponent,
    SdkTypeSelectComponent,
    SdkReleaseComponent,
    SdkReleaseFormComponent,
    SdkReleaseNoteListComponent,
    LatestReleasesComponent,
  ],
  imports: [CoreModule, CommonModule, SDKsRoutingModule],
})
export class SdksModule {}
