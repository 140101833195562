import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { TransactionsSummaryChartData } from '../../domain/models/transactions-summary-chart-data'

@Component({
  selector: 'app-transactions-summary-general-view-mode',
  templateUrl: './transactions-summary-general-view-mode.component.html',
  styleUrls: ['./transactions-summary-general-view-mode.component.css'],
})
export class TransactionsSummaryGeneralViewModeComponent implements OnChanges {
  @Input() summary: TransactionsSummaryChartData

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.summary?.currentValue) {
      this.summary = changes.summary.currentValue
    }
  }
}
