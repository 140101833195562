<div class="p-p-4 p-d-flex p-jc-center">
    <app-text [text]="releaseNote?.type" size="14" classes=""></app-text>
</div>
<div class="p-grid vertical-container p-pt-4 p-pl-3">
    <div id="version-session" class="p-col-7">
        <table id="version-data">
            <tr>
                <th>
                    <app-text text="Versão" size="12" classes="p-text-bold"></app-text>
                </th>
                <td class="p-text-center">
                    <app-text [text]="releaseNote?.version" size="12"></app-text>
                </td>
            </tr>
            <tr>
                <th>
                    <app-text text="Data da versão" size="12" classes="p-text-bold"></app-text>
                </th>
                <td class="p-text-center">
                    <app-text [text]="releaseNote?.versionDate | date:'dd/MM/yyyy'" size="12"></app-text>
                </td>
            </tr>
            <tr>
                <th>
                    <app-text text="Data da disponibilização" size="12" classes="p-text-bold"></app-text>
                </th>
                <td class="p-text-center">
                    <app-text [text]="releaseNote?.releaseDate | date:'dd/MM/yyyy'" size="12"></app-text>
                </td>
            </tr>
        </table>
    </div>
    <div id="download-session" class="p-col p-as-center p-jc-center">
        <div *ngIf="showDownloadButton()" class="p-d-flex p-flex-column p-ai-center p-text-center">
            <app-button id="download-button" [title]="releaseNote?.download" icon="download" label="" theme="secondary"
                (onClick)="openLink(releaseNote?.download)"></app-button>
            <app-text text="Faça aqui o download da versão" classes="p-text-bold"></app-text>
        </div>
    </div>
</div>
<div id="notes-session" class="p-col p-mt-5 p-pb-6">
    <div class="p-p-2">
        <app-text text="Notas de Atualização" classes="p-text-bold"></app-text>
        <ul>
            <li class="p-p-2" *ngFor="let note of releaseNote?.contents">
                <app-text [text]="note" size="12"></app-text>
            </li>
        </ul>
    </div>
</div>