import { Component, OnInit } from '@angular/core'
import { MenuItem } from 'primeng/api'
import { HasLoggedUserConfigManagerPermission } from '../../domain/usecases/has-logged-user-config-manager-permission'
import { IsLoggedUserAdministrator } from '../../domain/usecases/is-logged-user-administrator'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  items: MenuItem[]

  constructor(
    private isLoggedUserAdministrator: IsLoggedUserAdministrator,
    private hasLoggedUserConfigManagerPermission: HasLoggedUserConfigManagerPermission
  ) {}

  ngOnInit() {
    this.setPublicMenus()

    // if user has special permissions (activated by ADM Back endpoint)
    if (this.hasLoggedUserConfigManagerPermission.execute()) {
      this.setConfigManagerUserMenus()
    }

    if (this.isLoggedUserAdministrator.execute()) {
      this.setAdmUserMenus()
    }
  }

  private setConfigManagerUserMenus(): void {
    this.items.push({
      label: 'Configurações',
      icon: 'pi pi-cog',
      items: [
        {
          label: 'Recursos',
          icon: 'pi pi-fw pi-bookmark',
          routerLink: ['/users/permissions'],
        },
        {
          label: 'SDKs',
          icon: 'pi pi-fw pi-share-alt',
          routerLink: ['/sdks'],
        },
        {
          label: 'Traduções',
          icon: 'pi pi-fw pi-globe',
          routerLink: ['/translations'],
        },
      ],
    })
  }

  private setAdmUserMenus(): void {
    // shows SDK menu if user don´t have company
    this.items.push({
      label: 'Controle de Cadastros',
      icon: 'pi pi-fw pi-microsoft',
      items: [
        {
          label: 'Companhias',
          icon: 'pi pi-fw pi-briefcase',
          routerLink: ['/companies'],
        },
      ],
    })
    const sdk = this.items.find((x) => x.label === 'SDK')
    if (sdk) {
      sdk.items.push(
        {
          label: 'Criar Release',
          icon: 'pi pi-fw pi-file',
          routerLink: ['/sdks/create-release'],
        },
        {
          label: 'Status SDK',
          icon: 'pi pi-fw pi-check-square',
          routerLink: ['/sdks/status'],
        }
      )
    }
  }

  private setPublicMenus(): void {
    this.items = [
      {
        label: 'Transações',
        icon: 'pi pi-fw pi-chart-bar',
        items: [
          {
            label: 'Dashboard',
            icon: 'pi pi-fw pi-chart-line',
            routerLink: ['/transactions/dashboard'],
          },
          {
            label: 'Listagem',
            icon: 'pi pi-fw pi-list',
            routerLink: ['/transactions'],
          },
        ],
      },
      {
        label: 'Controle de Acessos',
        icon: 'pi pi-fw pi-users',
        items: [
          {
            label: 'Usuários',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/users'],
          },
          {
            label: 'Perfis',
            icon: 'pi pi-fw pi-lock',
            routerLink: ['/users/profiles'],
          },
        ],
      },
      {
        label: 'Auditoria',
        icon: 'pi pi-briefcase',
        items: [
          {
            label: 'Logs',
            icon: 'pi pi-fw pi-calendar',
            routerLink: ['/users/audit-history'],
          },
        ],
      },
      {
        label: 'SDK',
        icon: 'pi pi-fw pi-globe',
        items: [
          {
            label: 'Novas Versões',
            icon: 'pi pi-fw pi-download',
            routerLink: ['/sdks/latest-releases'],
          },
        ],
      },
    ]
  }
}
