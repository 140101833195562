<div class="p-col p-pl- p-pt-6">
    <div class="p-grid">
        <div class="p-col p-pl-4">
            <app-card title="Total de transações" [content]="summary?.totalAmount || '0'"></app-card>
        </div>
        <div class="p-col p-pl-6">
            <app-card title="Transações positivas" [content]="summary?.successAmount || '0'"></app-card>
        </div>
        <div class="p-col p-pl-6">
            <app-card title="Transações negativas" [content]="summary?.failedAmount || '0'"></app-card>
        </div>
        <div class="p-col p-pl-6 p-pr-4">
            <app-card title="Taxa de sucesso" [content]="summary?.successRate || '0%'"></app-card>
        </div>
    </div>
</div>

<div class="p-col p-pl-4 p-pt-4">
    <div class="p-grid">
        <div class="p-col-10 p-lg-10 p-xl-9 p-pl-3" *ngIf="summary">
            <app-transactions-raw-summary-bar-chart [datasets]="summary"></app-transactions-raw-summary-bar-chart>
        </div>
        <div class="p-col-2 p-lg-2 p-xl-2 p-pl-3 p-grid p-ai-center vertical-container">
            <app-transactions-raw-summary-doughnut-chart
                [datasets]="summary?.totalRawData"></app-transactions-raw-summary-doughnut-chart>
        </div>
    </div>
</div>

<div class="p-col p-pl-4 p-pt-4">
    <div class="p-grid">
        <div class="p-col-10 p-lg-10 p-xl-9 p-pl-3" *ngIf="summary">
            <app-transactions-percentage-summary-bar-chart [data]="summary">
            </app-transactions-percentage-summary-bar-chart>
        </div>
        <div class="p-col-2 p-lg-2 p-xl-2 p-pl-3 p-grid p-ai-center vertical-container">
            <app-transactions-percentage-summary-doughnut-chart
                [datasets]="summary?.totalPercentageData"></app-transactions-percentage-summary-doughnut-chart>
        </div>
    </div>
</div>