import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ListTransactionsComponent } from './pages/list-transactions/list-transactions.component'
import { TransactionDetailComponent } from './pages/transaction-detail/transaction-detail.component'
import { TransactionDashboardComponent } from './pages/transaction-dashboard/transaction-dashboard.component'
import { AuthGuard } from '../../core/security/auth.guard'
import { TransactionsSummaryComponent } from './pages/transactions-summary/transactions-summary.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: ListTransactionsComponent,
  },
  {
    path: 'detail',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: TransactionDetailComponent,
  },
  {
    path: 'dashboard',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: TransactionDashboardComponent,
  },
  {
    path: 'summary',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: TransactionsSummaryComponent,
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransactionRoutingModule {}
