import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'
import { SnackBarService } from '../components/snack-bar.service'
import { HasLoggedUserConfigManagerPermission } from '../domain/usecases/has-logged-user-config-manager-permission'
import { IsAccessTokenValidAndUserHasPermission } from '../domain/usecases/is-access-token-valid-and-user-has-permission'

@Injectable({
  providedIn: 'root',
})
export class AuthManagerGuard implements CanActivate {
  constructor(
    private isAccessTokenValidAndUserHasPermission: IsAccessTokenValidAndUserHasPermission,
    private hasLoggedUserConfigManagerPermission: HasLoggedUserConfigManagerPermission,
    private router: Router,
    private snackBar: SnackBarService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    let { authorized } = this.isAccessTokenValidAndUserHasPermission.execute({
      route,
      state,
    })

    if (authorized) {
      authorized = this.hasLoggedUserConfigManagerPermission.execute()

      if (!authorized) {
        console.log(
          'Token de acesso especial não é válido. Redirecionando...',
          state.url
        )
        this.snackBar.warn(
          'Token de acesso especial não é válido. Redirecionando...',
          5
        )
        this.router.navigate(['/transactions/dashboard'])
      }
    }
    return authorized
  }
}
