import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { Profile } from '../models/profile.model'
import { ProfileService } from '../../services/profile.service'

@Injectable({ providedIn: 'root' })
export class GetAllUserProfiles
  implements UseCase<void, Observable<Profile[]>>
{
  constructor(private profileService: ProfileService) {}

  execute(_: void): Observable<Profile[]> {
    return this.profileService.listAllProfiles()
  }
}
