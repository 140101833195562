import { User } from './user.model'

export interface LogList {
  count: number
  list: Log[]
}

export class Log {
  description: string
  logDate: string
  user: User

  logDateFrom: Date // for filter
  logDateTo: Date // for filter
}
