import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import { Constants } from '../../helpers/constants'

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css'],
})
export class TextComponent implements OnInit, OnChanges {
  @Input() text!: string
  @Input() size?: number = 16
  @Input() color?: string = Constants.appPrimaryColor
  @Input() classes?: string = ''

  ngOnInit(): void {}

  getStyle(): string {
    return `font-size: ${this.size}pt; color: ${this.color}`
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text?.currentValue) {
      this.text = changes.text.currentValue
    }
    if (changes.size?.currentValue) {
      this.size = changes.size.currentValue
    }
    if (changes.color?.currentValue) {
      this.color = changes.color.currentValue
    }
    if (changes.classes?.currentValue) {
      this.classes = changes.classes.currentValue
    }
  }
}
