export class Contact {
  id: number
  name: string
  email: string
  phone: string
  department: string
  creationDate: string

  static copy(contact: Contact): Contact {
    const { id, name, email, phone, department, creationDate } = contact
    return { id, name, email, phone, department, creationDate }
  }
}
