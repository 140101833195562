<p-dialog [(visible)]="_showContactDialog" [style]="{width: '400px'}" [header]="_formTitle" [modal]="true"
    styleClass="p-fluid" (onHide)="cancelForm()">
    <ng-template pTemplate="content">
        <div class="p-grid p-flex-column p-m-3">
            <app-label class="p-pb-2" label="Nome" size="12" classes="p-text-bold"></app-label>
            <app-input type="text" customStyle="width: 100%" [(ngModel)]="_contact.name"></app-input>
            <small class="p-error" *ngIf="_contactSubmitted && !_contact.name">Nome do contato é obrigatório.</small>
        </div>
        <div class="p-grid p-flex-column p-m-3">
            <app-label class="p-pb-2" label="E-mail" size="12" classes="p-text-bold"></app-label>
            <app-input customStyle="width: 100%" type="text" placeHoldText="example@domain.com"
                [(ngModel)]="_contact.email"></app-input>
            <!-- <small class="p-error" *ngIf="_contactSubmitted && !_contact.email">Email é obrigatório.</small> -->
        </div>
        <div class="p-grid p-flex-column p-m-3">
            <app-label class="p-pb-2" text="Telefone" size="12" classes="p-text-bold"></app-label>
            <div class="p-d-flex p-pt-2">
                <app-radio-button initialValue="Móvel" [value]="_phoneType" label="Móvel"
                    (onSelect)="setPhoneType($event)"></app-radio-button>

                <app-radio-button class="p-pl-3" initialValue="Fixo" [value]="_phoneType" label="Fixo"
                    (onSelect)="setPhoneType($event)"></app-radio-button>
            </div>
            <app-input customStyle="width: 100%" type="masked"
                [mask]="_phoneType == 'Móvel' ? '(99) 9 9999-9999': '(99) 9999-9999'"
                [(ngModel)]="_contact.phone"></app-input>
            <!-- <small class="p-error" *ngIf="_contactSubmitted && !_contact.phone">Telefone é obrigatório.</small> -->
        </div>
        <div class="p-grid p-flex-column p-m-3">
            <app-label class="p-pb-2" label="Departamento" size="12" classes="p-text-bold"></app-label>
            <app-input customStyle="width: 100%" type="text" maxlength="45"
                [(ngModel)]="_contact.department"></app-input>
            <small class="p-error" *ngIf="_contactSubmitted && !_contact.department">Departamento do contato é
                obrigatório.</small>
        </div>
        <div class="p-d-flex p-m-3" *ngIf="_contact.creationDate">
            <app-label class="p-pb-2" label="Data/hora criação" size="12" classes="p-text-bold"></app-label>
            <label>{{_contact.creationDate | date:'dd/MM/yyyy HH:mm:ss'}}</label>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <div class="p-d-flex p-jc-end">
            <app-button (onClick)="cancelForm()" type="rounded" theme="secondary" label="Cancelar"></app-button>
            <app-button (onClick)="saveContact()" type="rounded" theme="primary" label="Salvar"></app-button>
        </div>
    </ng-template>
</p-dialog>