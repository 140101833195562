import { Injectable } from '@angular/core'
import { UseCase } from './usecase'
import { StorageHelper } from '../../helpers/storage.helper'
import { JwtHelperService } from '@auth0/angular-jwt'

@Injectable({ providedIn: 'root' })
export class IsAccessTokenValid implements UseCase<void, boolean> {
  jwtHelper: JwtHelperService
  constructor(private storageHelper: StorageHelper) {
    this.jwtHelper = new JwtHelperService()
  }

  execute(_: void): boolean {
    const token = this.storageHelper.getBy('token')

    return token && !this.jwtHelper.isTokenExpired(token)
  }
}
