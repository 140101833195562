import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class ResponsiveService {
  private notSupportedScreens = [
    Breakpoints.Tablet,
    Breakpoints.TabletLandscape,
    Breakpoints.TabletPortrait,
    Breakpoints.Handset,
    Breakpoints.HandsetLandscape,
    Breakpoints.HandsetPortrait,
    Breakpoints.XSmall,
    Breakpoints.Small,
    Breakpoints.Small,
  ]

  constructor(private responsive: BreakpointObserver) {}

  get screenSizeChanges(): Observable<BreakpointState> {
    return this.responsive.observe(this.notSupportedScreens)
  }
}
