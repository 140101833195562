import { NgModule } from '@angular/core'
import { CoreModule } from 'src/app/core/core.module'
import { ListUserProfilesComponent } from './pages/list-user-profiles/list-user-profiles.component'
import { ListUsersComponent } from './pages/list-users/list-users.component'
import { UsersRoutingModule } from './users-routing.module'
import { ListUserAuditHistoryComponent } from './pages/list-user-audit-history/list-user-audit-history.component'
import { ListPermissionsComponent } from './pages/list-permissions/list-permissions.component'
import { LoginComponent } from './pages/login/login.component'

@NgModule({
  declarations: [
    ListUsersComponent,
    ListUserProfilesComponent,
    ListUserAuditHistoryComponent,
    ListPermissionsComponent,
    LoginComponent,
  ],
  imports: [CoreModule, UsersRoutingModule],
})
export class UsersModule {}
