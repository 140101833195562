<p-table *ngIf="notes?.length" [value]="notes">
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 50%;"><app-text text="Notas de Atualização" size="11"></app-text></th>
            <th style="width: 10%;"></th>
            <th style="width: 10%;"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-note>
        <tr>
            <td>{{note}}</td>
            <td>
                <app-button label="" theme="secondary" (onClick)="edit(note)" icon="edit" type="rounded"></app-button>
            </td>
            <td>
                <app-button label="" theme="secondary" (onClick)="remove(note)" icon="delete"
                    type="rounded"></app-button>
            </td>
        </tr>
    </ng-template>
</p-table>