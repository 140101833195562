import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Page } from '../../../core/domain/models/page.dto'
import { Pageable } from '../../../core/domain/models/pageable'
import {
  SDkReleaseUpdate,
  SdkRelease,
  SdkReleaseCreate,
} from '../domain/models/sdk-release'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
}

type SdkReleaseId = number

@Injectable({ providedIn: 'root' })
export class SdkReleaseService {
  private path = `${environment.apiUrl}releases`

  constructor(private http: HttpClient) {}

  getLatestReleases(): Observable<SdkRelease[]> {
    return this.http.get<SdkRelease[]>(`${this.path}/latest`, httpOptions)
  }

  getById(id: SdkReleaseId): Observable<SdkRelease> {
    return this.http.get<SdkRelease>(`${this.path}/${id}`, httpOptions)
  }

  getPage(pageable: Pageable): Observable<Page<SdkRelease>> {
    const params = pageable.toRequestParams({ startWith: '?' })
    return this.http.get<Page<SdkRelease>>(`${this.path}${params}`)
  }

  create(release: SdkReleaseCreate): Observable<SdkReleaseId> {
    return this.http.post<SdkReleaseId>(this.path, release, httpOptions)
  }

  update(release: SDkReleaseUpdate): Observable<void> {
    return this.http.put<void>(this.path, release, httpOptions)
  }

  delete(id: SdkReleaseId): Observable<SdkRelease> {
    return this.http.delete<SdkRelease>(`${this.path}/${id}`, httpOptions)
  }
}
