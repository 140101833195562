import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { Resource } from '../models/resource.model'
import { ResourceService } from '../../services/resource.service'

@Injectable({ providedIn: 'root' })
export class GetAllUserPermissions
  implements UseCase<void, Observable<Resource[]>>
{
  constructor(private resourceService: ResourceService) {}

  execute(_: void): Observable<Resource[]> {
    return this.resourceService.listAllResources()
  }
}
