import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { CompanyNew } from 'src/app/modules/companies/domain/models/company-new.model'
import { GetCompanyById } from '../../domain/usecases/get-company-by-id'
import { UpdateCompany } from '../../domain/usecases/update-company'

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.css'],
})
export class EditCompanyComponent implements OnInit {
  _company: CompanyNew = new CompanyNew()

  constructor(
    private snackBar: SnackBarService,
    private router: Router,
    private route: ActivatedRoute,
    private getCompanyById: GetCompanyById,
    private updateCompany: UpdateCompany
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id')
    this.getCompanyById.execute(Number(id)).subscribe(
      (company) => {
        this._company = company
      },
      (error) => {
        console.log(error)
        this.snackBar.error(
          `Não foi possível carregar a companhia pelo id ${id}!`,
          8,
          'top',
          'right'
        )
      }
    )
  }

  saveCompany(company: CompanyNew): void {
    this.updateCompany.execute(company).subscribe((_) => {
      this.snackBar.success('Companhia Atualizada!', 5, 'top', 'right')
      this.routeToCompanies()
    })
  }

  cancelForm(): void {
    this.routeToCompanies()
  }

  private routeToCompanies(): void {
    this.router.navigate(['/companies'])
  }
}
