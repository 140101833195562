import { Component, OnInit } from '@angular/core'
import { ConfirmationService, PrimeNGConfig } from 'primeng/api'
import { HasLoggedUserPermission } from 'src/app/core/domain/usecases/has-logged-user-permission'
import { IsLoggedUserAdministrator } from 'src/app/core/domain/usecases/is-logged-user-administrator'
import { Constants } from 'src/app/core/helpers/constants'
import { DateHelper } from 'src/app/core/helpers/date.helper'
import { GenericHelper } from 'src/app/core/helpers/generic.helper'
import { CompanyService } from 'src/app/core/service/company.service'
import { CompanyNew } from 'src/app/modules/companies/domain/models/company-new.model'
import { Log, LogList } from 'src/app/modules/users/domain/models/log.model'
import { User } from '../../domain/models/user.model'
import { FilterUserAuditHistory } from '../../domain/usecases/filter-user-audit-history'
import { GetAllUsers } from '../../domain/usecases/get-all-users'

@Component({
  selector: 'app-list-user-audit-history',
  templateUrl: './list-user-audit-history.component.html',
  styleUrls: ['./list-user-audit-history.component.css'],
})
export class ListUserAuditHistoryComponent implements OnInit {
  logDialog: boolean
  submitted: boolean
  valuePage: number = 0
  logFilter: Log = new Log()

  logList: LogList
  logs: Log[] = []
  log = new Log()

  usersFilter: User[] = []
  showCompanyFilter: boolean
  companiesFilter: CompanyNew[] = []

  count: number = 0

  withoutPermission: string

  constructor(
    private confirmationService: ConfirmationService,
    private config: PrimeNGConfig,
    private genericHelper: GenericHelper,
    private hasLoggedUserPermission: HasLoggedUserPermission,
    private dateHelper: DateHelper,
    private companyService: CompanyService,
    private isLoggedUserAdministrator: IsLoggedUserAdministrator,
    private getAllUsers: GetAllUsers,
    private filterUserAuditHistory: FilterUserAuditHistory
  ) {}

  ngOnInit() {
    this.config.setTranslation(this.genericHelper.getCalendarTranslations())
    this.showCompanyFilter = this.isLoggedUserAdministrator.execute()
    this.listAllLogs()
    this.listAllUsers()
    this.listAllCompanies()
  }

  listAllUsers() {
    if (this.hasLoggedUserPermission.execute('user.list')) {
      this.getAllUsers.execute().subscribe((data) => {
        this.usersFilter.push(new User(Constants.optionTodos))
        this.usersFilter = this.usersFilter.concat(data)
      })
    } else {
      console.warn('Sem permissão para listar usuários!')
    }
  }

  listAllCompanies() {
    if (this.hasLoggedUserPermission.execute('company.list')) {
      this.companyService.listAllCompanies().subscribe((data) => {
        this.companiesFilter.push(new CompanyNew(Constants.optionTodas))
        this.companiesFilter.push(new CompanyNew(Constants.optionNenhuma))
        this.companiesFilter = this.companiesFilter.concat(data.content)
      })
    } else {
      console.warn('Sem permissão para listar companhias!')
    }
  }

  listAllLogs() {
    this.logFilter = new Log()
    this.searchLogs()
  }

  searchLogs(page?: number) {
    if (this.hasLoggedUserPermission.execute('log.list')) {
      this.withoutPermission = null
      if (
        !this.logFilter.user ||
        this.logFilter.user.name == Constants.optionTodos
      )
        this.logFilter.user = new User()
      // if (!this.logFilter.user || !this.logFilter.user.company || this.logFilter.user.company.name == this.optionTodas)
      //   this.logFilter.company = new Company(); //TODO finalizar filtro de company
      // this.logFilter.nullCompany = this.logFilter.company && this.userFilter.company.name == this.optionNenhuma;
      this.filterUserAuditHistory
        .execute({ data: this.logFilter, page })
        .subscribe((data) => {
          this.logList = data
          this.logs = this.logList.list
          this.count = this.logList.count
        })
    } else {
      this.withoutPermission = 'Sem permissão para listar logs!'
    }
  }

  openForm(log: Log) {
    if (log) this.log = this.genericHelper.deepCopy(log)
    else {
      this.log = new Log()
      this.log.user = new User()
    }
    this.logDialog = true
  }

  hideDialog() {
    this.logDialog = false
    this.submitted = false
  }

  changePage(event) {
    this.searchLogs(event.page)
  }

  showFullLog(event: Event, log: Log) {
    let s = '<b>Usuário: </b>' + log.user.name + '<br>'
    s +=
      '<b>Data/hora: </b>' +
      this.dateHelper.formatDatetimeString(log.logDate) +
      '<br>'
    s += '<b>Descrição: </b>' + log.description
    this.confirmationService.confirm({
      target: event.target,
      message: s,
      acceptVisible: false,
      rejectVisible: false,
    })
  }
}
