import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SdkList } from 'src/app/core/domain/models/sdk.model'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { SdkService } from 'src/app/core/service/sdk.service'
type Page = number

@Injectable({ providedIn: 'root' })
export class GetSdksByPage implements UseCase<Page, Observable<SdkList>> {
  constructor(private sdkService: SdkService) {}

  execute(page?: Page): Observable<SdkList> {
    return this.sdkService.listSdks(page || 0)
  }
}
