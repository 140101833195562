import { Inject, Injectable } from '@angular/core'
import { UseCase } from './usecase'
import { UserCredentials } from '../models/user-credentials'
import { LoginResponse } from '../models/login-response'
import { AuthService } from '../../service/auth.service'
import { EncryptPassword } from './encrypt-password'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { SaveLoggedUserData } from './save-logged-user-data'

@Injectable({ providedIn: 'root' })
export class LogUserIn
  implements UseCase<UserCredentials, Observable<LoginResponse>>
{
  constructor(
    private auth: AuthService,
    private encryptPassword: EncryptPassword,
    private saveLoggedUserData: SaveLoggedUserData
  ) {}

  execute(user: UserCredentials): Observable<LoginResponse> {
    this.encryptUserPassword(user)

    return this.auth.logIn(user).pipe(
      map((response) => {
        this.saveLoggedUserData.execute({
          password: user.password,
          login: response,
        })
        return response
      })
    )
  }

  private encryptUserPassword(user: UserCredentials): void {
    if (user.password) {
      user.password = this.encryptPassword.execute(user.password)
    }
  }
}
