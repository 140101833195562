import { Injectable } from '@angular/core'
import { RejectedTransactionsLineChartData } from '../models/rejected-transactions-line-chart-data'
import { SummaryBySdkVersion } from '../models/transactions-summary.model'
import { NumberHelper } from 'src/app/core/helpers/number.helper'
import { UseCase } from 'src/app/core/domain/usecases/usecase'

@Injectable({
  providedIn: 'root',
})
export class MapSummaryBySdkVersionToRejectedLineChartData
  implements UseCase<SummaryBySdkVersion[], RejectedTransactionsLineChartData>
{
  constructor(private numberHelper: NumberHelper) {}

  execute(
    summaryBySdk: SummaryBySdkVersion[]
  ): RejectedTransactionsLineChartData {
    const { groupedByVersion, uniqueDates } =
      this.groupByVersionInList(summaryBySdk)

    return this.mapToRejectedTransactionsLineChartData(
      uniqueDates,
      groupedByVersion
    )
  }

  private mapToRejectedTransactionsLineChartData(
    uniqueDates: {},
    groupedByVersion: {}
  ): RejectedTransactionsLineChartData {
    return {
      labels: Object.keys(uniqueDates),
      datasets: Object.keys(groupedByVersion).map((version) => {
        return {
          name: version,
          values: groupedByVersion[version].map((it) =>
            this.numberHelper.roundWithPlaces(2, it.failedRate)
          ),
        }
      }),
    }
  }

  private groupByVersionInList(
    summaryBySdk: SummaryBySdkVersion[]
  ): GroupedByVersionData {
    let uniqueDates = {}
    const groupedByVersion = summaryBySdk.reduce((map, item) => {
      uniqueDates[item.date] = ''
      if (!map[item.version]) map[item.version] = []
      map[item.version].push(item)
      return map
    }, {})

    return { groupedByVersion, uniqueDates }
  }
}

interface GroupedByVersionData {
  groupedByVersion: {}
  uniqueDates: {}
}
