import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'
// import * as ChartDataLabels from 'chartjs-plugin-datalabels'

@Component({
  selector: 'app-transactions-percentage-summary-doughnut-chart',
  templateUrl:
    './transactions-percentage-summary-doughnut-chart.component.html',
  styleUrls: ['./transactions-percentage-summary-doughnut-chart.component.css'],
})
export class TransactionsPercentageSummaryDoughnutChartComponent
  implements OnInit, OnChanges
{
  @Input() datasets!: number[]

  _datasets: {
    labels: string[]
    values: {
      backgroundColor: string[]
      hoverBackgroundColor: string[]
      data: number[]
    }[]
  }

  options = {
    // plugin: ChartDataLabels,
    // plugins: {
    //   datalabels: {
    //     formatter: (value, ctx) => {
    //       return value == 0 ? '' : value + '%'
    //     },
    //     color: 'white',
    //     font: {
    //       weight: 'bold',
    //       size: 12,
    //     },
    //   },
    // },
  }

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.datasets.currentValue) {
      this.setData(changes.datasets.currentValue)
    }
  }

  setData(datasets) {
    this._datasets = {
      labels: ['Aprovados', 'Rejeitados'],
      values: [
        {
          backgroundColor: ['#211858', '#FF6D40'],
          hoverBackgroundColor: ['#211858', '#FF6D40'],
          data: datasets,
        },
      ],
    }
  }
}
