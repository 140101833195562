<app-title title="CONTROLE DE COMPANHIAS"></app-title>

<div class="p-col" style="max-width: 100%;">
    <p-card>
        <app-button [disabled]="!checkPermission('company.add') || !showCompanyFilter" (onClick)="routeToNewCompany()"
            label="Companhia" type="rounded" icon="add" theme="primary"
            title="Clique para abrir o formulário para adicionar companhia"></app-button>
        <button pButton pRipple *ngIf="checkPermission('company.add') && !showCompanyFilter"
            class="p-ml-1 primaryButton p-button-rounded" icon="pi pi-question-circle"
            (click)="showNoAddCompanyAdvise()"></button>
        <div class="p-col"></div>
        <div class="card" id="table-card">
            <p-table [value]="companies" [scrollable]="true" [resizableColumns]="true" [rows]="5" [rowHover]="true"
                dataKey="id" [showCurrentPageReport]="true">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between" *ngIf="showCompanyFilter">
                        <span class="p-input">
                            <label for="filterName" class="p-mr-2">Nome</label>
                            <input pInputText id="filterName" [(ngModel)]="companyFilter.name" type="text"
                                (keyup.enter)="searchCompanies()" />
                        </span>
                        <span class="p-input">
                            <label for="filterCnpj" class="p-mr-2">CNPJ</label>
                            <p-inputMask id="filterCnpj" unmask="true" [(ngModel)]="companyFilter.cnpj"
                                mask="99.999.999/9999-99" type="text" (keyup.enter)="searchCompanies()"></p-inputMask>
                        </span>
                        <span class="p-input">
                            <label for="filterCodrev" class="p-mr-2">Codrev</label>
                            <input pInputText id="filterCodrev" [(ngModel)]="companyFilter.codrev" type="text"
                                (keyup.enter)="searchCompanies()" />
                        </span>
                        <app-button (onClick)="searchCompanies()" type="rounded" label=" Filtrar" icon="filter"
                            theme="primary"></app-button>
                    </div>
                </ng-template>
                <ng-template pTemplate="colgroup">
                    <colgroup>
                        <col style="width:30px">
                        <col style="width:120px">
                        <col style="width:60px">
                        <col style="width:60px">
                        <col style="width:30px">
                        <col style="width:30px">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>CNPJ</th>
                        <th>Codrev</th>
                        <th>Editar</th>
                        <th>Gerar</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-company>
                    <tr>
                        <td>{{company.id}}</td>
                        <td>{{company.name}}</td>
                        <td>{{company.cnpj | cnpj}}</td>
                        <td>{{company.codrev}}</td>
                        <td>
                            <app-button [disabled]="!checkPermission('company.update')" label="" theme="secondary"
                                (onClick)="editCompany(company)" icon="edit" type="rounded"></app-button>
                        </td>
                        <td>
                            <app-button [disabled]="!checkPermission('company.report')"
                                [title]="checkPermission('company.report') ? 'Clique para gerar a planilha (CSV)' : 'Sem permissão para gerar a planilha'"
                                label="" theme="secondary" (onClick)="reportCompany(company)" icon="file"
                                type="rounded"></app-button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" *ngIf="withoutPermission">
                    <tr>
                        <td colspan="8" style="text-align: center">{{withoutPermission}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Total: {{ count }} companhia{{ count > 1 ? 's' : '' }}
                    </div>
                </ng-template>
            </p-table>
            <p-paginator [rows]="10" (onPageChange)=changePage($event) [totalRecords]="count"></p-paginator>
        </div>
    </p-card>
</div>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
<p-toast></p-toast>