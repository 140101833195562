import { Injectable } from '@angular/core'
import { UseCase } from './usecase'
import { Observable } from 'rxjs'
import { Sdk } from '../models/sdk.model'
import { GetAllSdks } from './get-all-sdks'
import { HasLoggedUserPermission } from './has-logged-user-permission'

type AllSdkResponse = {
  hasPermission?: boolean
  sdks: Sdk[]
}

@Injectable({ providedIn: 'root' })
export class GetAllSdksAndCheckUserPermission
  implements UseCase<void, Observable<AllSdkResponse>>
{
  constructor(
    private getAllSdks: GetAllSdks,
    private hasLoggedUserPermission: HasLoggedUserPermission
  ) {}

  execute(_: void): Observable<AllSdkResponse> {
    return new Observable((subs) => {
      if (!this.hasLoggedUserPermission.execute('sdk.list')) {
        subs.next({ sdks: [], hasPermission: false })
        return
      }

      this.getAllSdks.execute().subscribe((sdks) => {
        subs.next({
          sdks: sdks,
          hasPermission: true,
        })
      })
    })
  }
}
