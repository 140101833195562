import { Contact } from './contact.model'
import { EndpointCompany } from './endpoint-company.model'
import { SdkCompany } from './sdk-company.model'

export interface CompanyList {
  count: number
  list: Company[]
}

export class Company {
  id: number
  name: string
  creationDate: string
  cnpj: string
  codrev: string
  locale: any
  contactList: Contact[]
  sdkCompanyList: SdkCompany[]
  endpointCompanyList: EndpointCompany[]

  constructor(name: string = null, cnpj: string = null) {
    this.name = name
    this.cnpj = cnpj
  }
}
