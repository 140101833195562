import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { LoginResponse } from '../domain/models/login-response'
import { UserCredentials } from '../domain/models/user-credentials'

export const InterceptorSkipHeader = 'X-Skip-Interceptor'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = `${environment.apiUrl}`
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      InterceptorSkipHeader,
    }),
  }

  constructor(private httpClient: HttpClient) {}

  logIn(userAuth: UserCredentials): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(
      `${this.apiUrl}` + 'auth/login',
      userAuth,
      this.httpOptions
    )
  }
}
