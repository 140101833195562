<div class="p-grid p-flex-column p-m-3">
    <app-label class="p-pr-2 p-pb-2" label="Nome" size="12" classes="p-text-bold"></app-label>
    <app-input type="text" customStyle="width: 15%" [(ngModel)]="_company.name"></app-input>
    <small class="p-error" *ngIf="_submitted && !_company.name">Nome de companhia é obrigatório.</small>
</div>
<div class="p-grid p-flex-column p-m-3">
    <app-label class="p-pr-2 p-mt-2 p-pb-2" label="CNPJ" size="12" classes="p-text-bold"></app-label>
    <app-input type="masked" style="width: 15%" mask="99.999.999/9999-99" [(ngModel)]="_company.cnpj"></app-input>
    <small class="p-error" *ngIf="_submitted && !_company.cnpj">CNPJ é obrigatório.</small>
</div>
<div class="p-grid p-flex-column p-m-3">
    <app-label class="p-pr-2 p-mt-2 p-pb-2" label="Codrev" size="12" classes="p-text-bold"></app-label>
    <app-input type="number" style="width: 15%" minValue="0" [(ngModel)]="_company.codrev"></app-input>
    <small class="p-error" *ngIf="_submitted && !_company.codrev">Codrev é obrigatório.</small>
</div>
<div class="p-m-3">
    <app-locale-select groupClasses="p-d-flex p-flex-column" labelClasses="p-pb-2 p-text-bold" groupStyle="width: 15%"
        [initialLocale]="_companyInitLocale" (selected)="setLocale($event)"></app-locale-select>
    <small class="p-error" *ngIf="_submitted && !_company.locale">Idioma é obrigatório.</small>
</div>
<p-divider></p-divider>
<app-company-contacts [contactList]="_company.contacts" (contacts)="setContacts($event)"></app-company-contacts>
<small class="p-error" *ngIf="_submitted && (_company.contacts || _company.contacts?.length == 0)">Lista de
    contatos é
    obrigatório.</small>
<p-divider></p-divider>
<app-company-sdks [company]="_company" (companySdks)="setCompanySdks($event)"></app-company-sdks>
<small class="p-error" *ngIf="_submitted && (_company.sdks || _company.sdks?.length == 0)">Lista de
    SDKs é
    obrigatório.</small>

<div class="p-d-flex p-jc-end p-p-4 p-mt-6">
    <app-button theme="secondary" type="rounded" label="Cancelar" classes="p-mr-3"
        (onClick)="cancelForm()"></app-button>
    <app-button theme="primary" type="rounded" label="Salvar" (onClick)="saveCompany()"></app-button>
</div>