import { Injectable } from '@angular/core'
import { UseCase } from './usecase'
import { UrlHelper } from '../../helpers/url.helper'

type AnyObject = {}

@Injectable({ providedIn: 'root' })
export class SetParamOnUrl implements UseCase<AnyObject, void> {
  constructor(private urlHelper: UrlHelper) {}

  execute(param: AnyObject): void {
    this.urlHelper.setParams(param)
  }
}
