import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { Contact } from '../../domain/models/contact.model'

@Component({
  selector: 'app-company-contact-form',
  templateUrl: './company-contact-form.component.html',
  styleUrls: ['./company-contact-form.component.css'],
})
export class CompanyContactFormComponent implements OnInit, OnChanges {
  @Input() contact?: Contact = new Contact()
  @Input() visible?: boolean = false
  @Output() onSave = new EventEmitter<Contact | undefined>()

  _showContactDialog = false
  _contactSubmitted = false
  _contact: Contact = new Contact()
  _phoneType: string = 'Móvel'
  _formTitle = 'Novo Contato'

  constructor(private snackBar: SnackBarService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contact?.currentValue) {
      this._contact = changes.contact?.currentValue
      this._formTitle = 'Editar Contato'
    }

    if (changes.visible?.currentValue) {
      this._showContactDialog = changes.visible.currentValue
    }
  }

  hideContactDialog(): void {
    this._showContactDialog = false
    this.resetContactForm()
  }

  saveContact(): void {
    this._contactSubmitted = true
    if (!this.isContactFormValid()) {
      this.snackBar.warn('Fornulário inválido', 8, 'top', 'right')
      return
    }

    this.onSave.emit(Contact.copy(this._contact))
    this.resetContactForm()
    this.hideContactDialog()
  }

  cancelForm(): void {
    this.onSave.emit()

    this.hideContactDialog()
  }

  setPhoneType(type: string): void {
    this._phoneType = type
  }

  private isContactFormValid(): boolean {
    return Boolean(
      this._contact &&
        this._contact.name &&
        // this._contact.email &&
        // this._contact.phone &&
        this._contact.department
    )
  }

  private resetContactForm(): void {
    this._contactSubmitted = false
    this._contact = new Contact()
    this._phoneType = 'Móvel'
    this._formTitle = 'Novo Contato'
  }
}
