import { Injectable } from '@angular/core'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { CompanyNew } from '../models/company-new.model'
import { Observable } from 'rxjs'
import { CompanyService } from 'src/app/core/service/company.service'

@Injectable({ providedIn: 'root' })
export class UpdateCompany implements UseCase<CompanyNew, Observable<void>> {
  constructor(private companyService: CompanyService) {}

  execute(company: CompanyNew): Observable<void> {
    return this.companyService.update(CompanyNew.parseToCreateCompany(company))
  }
}
