import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class FileHelper {
  // for generate reports in CSV format
  saveAsCsvFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      let TYPE = 'text/csv; charset = UTF-8'
      let EXTENSION = '.csv'
      const data: Blob = new Blob([buffer], {
        type: TYPE,
      })
      FileSaver.saveAs(data, fileName + EXTENSION)
    })
  }
}
