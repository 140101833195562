export interface ResourceList {
  count: number;
  list: Resource[];
}

export class Resource {
  id: number;
  name: string;
  description: string;
  descriptionOriginal: string;
  creationDate: string;
  enabled: boolean;
  checked: boolean;
}
