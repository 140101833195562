import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CreateReleaseComponent } from './pages/create-release/create-release.component'
import { LatestReleasesComponent } from './pages/latest-releases/latest-releases.component'
import { ListSdksComponent } from './pages/list-sdks/list-sdks.component'
import { StatusSdkComponent } from './pages/status-sdk/status-sdk.component'
import { AuthGuard } from 'src/app/core/security/auth.guard'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: ListSdksComponent,
  },
  {
    path: 'status',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: StatusSdkComponent,
  },
  {
    path: 'latest-releases',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: LatestReleasesComponent,
  },
  {
    path: 'create-release',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: CreateReleaseComponent,
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SDKsRoutingModule {}
