import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Resource, ResourceList } from '../domain/models/resource.model'

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  private apiUrl = `${environment.apiUrl}`
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  }

  constructor(private httpClient: HttpClient) {}

  listAllResources(): Observable<Resource[]> {
    return this.httpClient.get<Resource[]>(
      this.apiUrl + 'resource/list-all',
      this.httpOptions
    )
  }

  listResources(page: number): Observable<ResourceList> {
    return this.httpClient.post<ResourceList>(
      this.apiUrl + 'resource/list?page=' + page,
      this.httpOptions
    )
  }

  saveResource(resource: Resource): Observable<string> {
    resource.description = resource.descriptionOriginal // send english description
    return this.httpClient.post<string>(
      this.apiUrl + 'resource/' + (resource.id ? 'update' : 'add'),
      resource,
      this.httpOptions
    )
  }
}
