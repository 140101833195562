import { CompanyNew } from '../../../companies/domain/models/company-new.model'
import { Profile } from './profile.model'

export interface UserList {
  count: number
  list: User[]
}

export class User {
  id: string
  name: string
  cpf: string
  email: string
  enabled: boolean
  password: string
  creationDate: string
  lastLoginDate: string
  profile: Profile
  company: CompanyNew
  nullCompany: boolean

  constructor(name?: string) {
    this.name = name ? name : null
  }
}
