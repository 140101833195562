<p-toolbar>
    <div class="p-toolbar-group-left">
        <img src="/assets/img/logo.svg" alt="certisign logo" id="menu-logo" height="40" class="p-mr-2"
            style="cursor: pointer" (click)="home()">
        <p-divider layout="vertical"></p-divider>
        <div id="bar-title">
            <h3><b>ID Onboarding - Portal Administrativo</b></h3>
        </div>
    </div>
    <div class="p-toolbar-group-right">
        <b class="p-mr-3">{{userAuthorized?.name}}</b>
        <app-button classes="p-mr-3" type="rounded" theme="primary" icon="lock" title="Clique para alterar a senha"
            (onClick)="showChangePasswordDialog()"></app-button>
        <app-button classes="p-mr-3" type="rounded" theme="primary" icon="signOut" title="Clique para encerrar a sessão"
            (onClick)="logout()"></app-button>
    </div>
</p-toolbar>

<p-dialog [(visible)]="passwordDialog" [style]="{width: '300px'}" header="Atualização de senha" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <h4 *ngIf="mustDefinePassword" style="color: red">Sua senha deve ser definida!</h4>
        <div *ngIf="!mustDefinePassword" class="p-field">
            <label for="password-old">Senha atual</label>
            <p-password [(ngModel)]="changePassword.passwordOld" [feedback]="false" id="password-old" required
                autofocus>
            </p-password>
            <small class="p-error" *ngIf="submitted && !changePassword.passwordOld">Senha atual é obrigatória.</small>
        </div>
        <div class="small">
            <i *ngIf="isRequirementOk(0)" class="pi pi-check p-mr-1"></i>
            <label [title]="'No mínimo ' + minimumSize + ' caracteres'">{{'Tamanho mínimo: ' + minimumSize}}</label>
        </div>
        <div class="small">
            <i *ngIf="isRequirementOk(1)" class="pi pi-check icon-color p-mr-1"></i>
            <label [hidden]="requirements.length < 1">{{requirementsTitle[0]}}</label>
        </div>
        <div class="small">
            <i *ngIf="isRequirementOk(2)" class="pi pi-check icon-color p-mr-1"></i>
            <label [hidden]="requirements.length < 2">{{requirementsTitle[1]}}</label>
        </div>
        <div class="small">
            <i *ngIf="isRequirementOk(3)" class="pi pi-check icon-color p-mr-1"></i>
            <label [hidden]="requirements.length < 3">{{requirementsTitle[2]}}</label>
        </div>
        <div class="small">
            <i *ngIf="isRequirementOk(4)" class="pi pi-check icon-color p-mr-1"></i>
            <label [hidden]="requirements.length < 4">{{requirementsTitle[3]}}</label>
        </div>
        <div class="p-field p-mb-0 p-mt-2">
            <label for="password-new">Senha nova</label>
            <p-password [(ngModel)]="changePassword.passwordNew"
                (input)="checkPasswordRequirements($event.target.value)" [feedback]="false" id="password-new" required
                toggleMask="true">
            </p-password>
            <small class="p-error" *ngIf="submitted && !changePassword.passwordNew">Senha nova é
                obrigatória.</small><br>
            <small class="p-error" *ngIf="submitted && !this.requirementsAllOk">Senha deve atender todos
                requisitos.</small>
        </div>
        <div class="p-field">
            <label for="password-new-confirm">Confirme</label>
            <p-password [(ngModel)]="changePassword.passwordNewConfirm" [feedback]="false" id="password-new-confirm"
                required>
            </p-password>
            <small class="p-error" *ngIf="submitted && !changePassword.passwordNewConfirm">A confirmação é
                obrigatória.</small>
            <small class="p-error"
                *ngIf="submitted && changePassword.passwordNewConfirm && changePassword.passwordNew != changePassword.passwordNewConfirm">A
                confirmação está incorreta.</small>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <app-button label="Cancelar" type="rounded" theme="secondary" icon="cancel" title="Clique para alterar a senha"
            (onClick)="hideDialog()"></app-button>
        <app-button label="Salvar" type="rounded" theme="primary" icon="check" title="Clique para alterar a senha"
            (onClick)="sendChangePassword()"></app-button>
    </ng-template>
</p-dialog>