<div *ngIf="type === 'text'">
    <input pInputText id="input-text" type="text" [ngClass]="classes" [style]="customStyle"
        [placeholder]="placeHoldText" [(ngModel)]="data" [maxlength]="maxLength" [disabled]="disabled" />
</div>
<div *ngIf="type === 'masked'">
    <p-inputMask id="input-masked" [ngClass]="classes" [style]="customStyle" unmask="true" [placeholder]="placeHoldText"
        [(ngModel)]="data" [mask]="mask" [characterPattern]="regex" type="text" [disabled]="disabled"></p-inputMask>
</div>

<div *ngIf="type === 'number'">
    <p-inputNumber id="input-number" [ngClass]="classes" [style]="customStyle" [min]="getNumberValue(minValue)"
        [max]="getNumberValue(maxValue)" [(ngModel)]="data" mode="decimal" [useGrouping]="false"
        [placeholder]="placeHoldText" [disabled]="disabled"></p-inputNumber>
</div>
<div *ngIf="type === 'password'">
    <p-password id="input-password" [ngClass]="classes" [style]="customStyle" [(ngModel)]="data" [feedback]="false"
        [placeholder]="placeHoldText"></p-password>
</div>