import { Endpoint } from './endpoint.model'
export interface SdkList {
  count: number
  list: Sdk[]
}

export class Sdk {
  id: number
  name: string
  description: string
  descriptionOriginal: string
  enabled: boolean
  version: string
  creationDate: string
  endpointList: Endpoint[]

  // indicators
  running?: Boolean = null
  runningStatus?: String = 'Carregando...'
  cpu?: number
  cpuStr?: string = ''
  ram?: number
  ramStr?: string = ''
  hd?: number
  hdStr?: string = ''
  errorMessage?: string

  constructor(name?: string) {
    this.name = name ? name : null
  }

  static copy(sdk: Sdk): Sdk {
    const {
      id,
      name,
      description,
      descriptionOriginal,
      enabled,
      version,
      creationDate,
      endpointList,
    } = sdk
    return {
      id,
      name,
      description,
      descriptionOriginal,
      enabled,
      version,
      creationDate,
      endpointList,
    }
  }
}
