<app-title title="CONTROLE DE SDKS"></app-title>

<div class="p-col" style="max-width: 100%">
  <p-card>
    <button pButton icon="pi pi-plus" (click)="openForm(null)" class="p-button-rounded primaryButton" label="Adicionar"
      title="Clique para abrir o formulário para adicionar sdk"></button>
    <div class="p-col"></div>
    <div class="card" id="table-card">
      <p-table [value]="sdks" [scrollable]="true" [resizableColumns]="true" [rows]="5" [rowHover]="true" dataKey="id"
        [showCurrentPageReport]="true">
        <ng-template pTemplate="colgroup">
          <colgroup>
            <col style="width: 40px" />
            <col style="width: 150px" />
            <col style="width: 200px" />
            <col style="width: 60px" />
            <col style="width: 120px" />
            <col style="width: 40px" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header">
          <tr id="columns">
            <th>ID</th>
            <th>Nome</th>
            <th>Descrição (em inglês)</th>
            <th>Versão</th>
            <th>Data</th>
            <th>Editar</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-sdk>
          <tr [style]="sdk.enabled ? '' : 'text-decoration: line-through'">
            <td>{{ sdk.id }}</td>
            <td>{{ sdk.name }}</td>
            <td>{{ sdk.descriptionOriginal }}</td>
            <td>{{ sdk.version }}</td>
            <td>{{ sdk.creationDate | date : 'dd/MM/yyyy' }}</td>
            <td>
              <button pButton pRipple icon="pi pi-pencil" class="secundaryButton p-button-rounded p-mr-2"
                (click)="openForm(sdk)"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <div class="p-d-flex p-ai-center p-jc-between">
            Total: {{ count }} SDK{{ count > 1 ? 's' : '' }}
          </div>
        </ng-template>
      </p-table>
      <p-paginator [rows]="10" (onPageChange)="changePage($event)" [totalRecords]="count"></p-paginator>
    </div>
  </p-card>

  <p-dialog [(visible)]="sdkDialog" [style]="{ width: '500px' }" header="Detalhes do SDK" [modal]="true"
    styleClass="p-fluid" (onHide)="hideDialog()">
    <ng-template pTemplate="content">
      <div class="p-field">
        <label class="p-mr-2" for="name">{{
          'Nome' + (sdk.id ? ' (não é possível alterar)' : '')
          }}</label>
        <input type="text" pInputText id="name" [(ngModel)]="sdk.name" required autofocus [readOnly]="sdk.id" />
        <small class="p-error" *ngIf="submitted && !sdk.name">Nome do sdk é obrigatório.</small>
      </div>
      <div class="p-field">
        <label class="p-mr-2" for="description">Descrição (deve ser em inglês)</label>
        <input type="text" pInputText id="description" [(ngModel)]="sdk.descriptionOriginal" required autofocus />
        <small class="p-error" *ngIf="submitted && !sdk.descriptionOriginal">Descrição do sdk é obrigatória (em
          inglês).</small>
      </div>
      <div class="p-field">
        <label class="p-mr-2" for="version">Versão</label>
        <input type="text" pInputText id="version" [(ngModel)]="sdk.version" required autofocus />
        <small class="p-error" *ngIf="submitted && !sdk.name">Versão do sdk é obrigatória.</small>
      </div>
      <div class="p-field">
        <label class="p-mr-2" for="status">Status</label>
        <input type="checkbox" id="status" [checked]="sdk.enabled" (change)="sdk.enabled = !sdk.enabled" autofocus />
      </div>
      <div class="p-field">
        <div *ngIf="sdk.creationDate">
          <label classe="p-mr-2" class="p-mr-1">Data/hora criação:</label>
          <label>{{ sdk.creationDate | date : 'dd/MM/yyyy' }}</label>
        </div>
      </div>
      <p-divider></p-divider>
      <p-table [value]="sdk.endpointList">
        <ng-template pTemplate="header">
          <tr>
            <th>
              <h4>Endpoints</h4>
            </th>
            <th colspan="3" class="p-text-right">
              <button pButton pRipple type="button" label="Adicionar" icon="pi pi-plus"
                class="p-button-rounded primaryButton" (click)="addEndpoint()" style="width: 120px"></button>
            </th>
          </tr>
          <tr>
            <th>Nome</th>
            <th>Habilitado</th>
            <th>Editar</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="colgroup">
          <colgroup>
            <col style="width: 100%" />
            <col style="width: 100px" />
            <col style="width: 40px" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="body" let-endpoint>
          <tr>
            <td>{{ endpoint.name }}</td>
            <td>{{ endpoint.enabled ? 'Sim' : 'Não' }}</td>
            <td>
              <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded secundaryButton"
                (click)="openEndpointForm(endpoint)"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>

    <ng-template pTemplate="footer">
      <app-button classes="p-mr-4" label="Cancelar" type="rounded" theme="secondary" icon="cancel"
        title="Clique para alterar a senha" (onClick)="hideDialog()"></app-button>

      <app-button label="Salvar" type="rounded" theme="primary" icon="check" (onClick)="preSaveSdk()"></app-button>
    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="endpointDialog" [style]="{ width: '400px' }" header="Detalhes do Endpoint" [modal]="true"
    styleClass="p-fluid" (onHide)="hideEndpointDialog()">
    <ng-template pTemplate="content">
      <div class="p-field">
        <label class="p-mr-2" for="endpointName">{{
          'Nome' + (endpoint.id ? ' (não é possível alterar)' : '')
          }}</label>
        <input type="text" pInputText id="endpointName" [(ngModel)]="endpoint.name" required autofocus
          [readOnly]="endpoint.id" />
        <small class="p-error" *ngIf="submitted && !endpoint.name">Nome do endpoint é obrigatório.</small>
      </div>
      <div class="p-field">
        <label class="p-mr-2" for="endpointDescription">Descrição (deve ser em inglês)</label>
        <input type="text" pInputText id="endpointDescription" [(ngModel)]="endpoint.descriptionOriginal" required
          autofocus />
        <small class="p-error" *ngIf="submitted && !endpoint.descriptionOriginal">Descrição do endpoint é obrigatória
          (em inglês).</small>
      </div>
      <div class="p-field">
        <label class="p-mr-2" for="endpointStatus">Status</label>
        <input type="checkbox" id="endpointStatus" [checked]="endpoint.enabled"
          (change)="endpoint.enabled = !endpoint.enabled" autofocus />
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <app-button classes="p-mr-4" label="Cancelar" type="rounded" theme="secondary" icon="cancel"
        title="Clique para alterar a senha" (onClick)="hideEndpointDialog()"></app-button>

      <app-button label="Salvar" type="rounded" theme="primary" icon="check" (onClick)="preSaveEndpoint()"></app-button>
    </ng-template>
  </p-dialog>
</div>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<p-toast></p-toast>