import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { TransactionsSummary } from '../domain/models/transactions-summary.model'

@Injectable({
  providedIn: 'root',
})
export class BioAnalyticsService {
  private apiUrl = environment.bioAnalyticsApi.url

  constructor(private http: HttpClient) {}

  get getDefaultDateRangeInDays(): number {
    return environment.bioAnalyticsApi.defaultDateRangeInDays
  }

  getTransactionsSummary({
    dateRange,
    companyNumber,
    transactionType,
  }: {
    dateRange: { dateFrom: string; dateTo: string }
    companyNumber: string
    transactionType: string
  }): Observable<TransactionsSummary> {
    const url = `${this.apiUrl}/transaction/summary?dateFrom=${dateRange.dateFrom}&dateTo=${dateRange.dateTo}&companyNumber=${companyNumber}&transactionType=${transactionType}`
    return this.http.get<TransactionsSummary>(url)
  }
}
