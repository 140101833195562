import { Component, Input, forwardRef } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent),
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  @Input() type!: 'text' | 'number' | 'masked' | 'password'
  @Input() mask?: string
  @Input() regex?: string
  @Input() placeHoldText?: string = ''
  @Input() maxLength?: number
  @Input() disabled?: boolean
  @Input() classes?: string = ''
  @Input() customStyle?: string = ''
  @Input() minValue?: number
  @Input() maxValue?: number

  _data: any

  get data(): any {
    return this._data
  }
  set data(v: any) {
    if (v !== this._data) {
      this._data = v
      this.onChange(v)
    }
  }

  touched = false

  onChange = (data) => {}

  onTouched = () => {}

  writeValue(obj: any): void {
    this.data = obj
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched()
      this.touched = true
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled
  }

  getNumberValue(value): number {
    return Number(value)
  }
}
