import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'
import { IsAccessTokenValidAndUserHasPermission } from '../domain/usecases/is-access-token-valid-and-user-has-permission'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private isAccessTokenValidAndUserHasPermission: IsAccessTokenValidAndUserHasPermission
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const { authorized } = this.isAccessTokenValidAndUserHasPermission.execute({
      route,
      state,
    })

    return authorized
  }
}
