import { NgModule } from '@angular/core'
import { CoreModule } from '../../core/core.module'
import { RejectedTransactionsRateLineChartComponent } from './components/rejected-transactions-rate-line-chart/rejected-transactions-rate-line-chart.component'
import { TransactionComponent } from './components/transaction/transaction.component'
import { TransactionsPercentageSummaryBarChartComponent } from './components/transactions-percentage-summary-bar-chart/transactions-percentage-summary-bar-chart.component'
import { TransactionsPercentageSummaryDoughnutChartComponent } from './components/transactions-percentage-summary-doughnut-chart/transactions-percentage-summary-doughnut-chart.component'
import { TransactionsRawSummaryBarChartComponent } from './components/transactions-raw-summary-bar-chart/transactions-raw-summary-bar-chart.component'
import { TransactionsRawSummaryDoughnutChartComponent } from './components/transactions-raw-summary-doughnut-chart/transactions-raw-summary-doughnut-chart.component'
import { TransactionsSummaryGeneralViewModeComponent } from './components/transactions-summary-general-view-mode/transactions-summary-general-view-mode.component'
import { TransactionsSummarySdkViewModeComponent } from './components/transactions-summary-sdk-view-mode/transactions-summary-sdk-view-mode.component'
import { TransactionsSummaryViewModeOptionsComponent } from './components/transactions-summary-view-mode-options/transactions-summary-view-mode-options.component'
import { ListTransactionsComponent } from './pages/list-transactions/list-transactions.component'
import { TransactionDashboardComponent } from './pages/transaction-dashboard/transaction-dashboard.component'
import { TransactionDetailComponent } from './pages/transaction-detail/transaction-detail.component'
import { TransactionsSummaryComponent } from './pages/transactions-summary/transactions-summary.component'
import { TransactionRoutingModule } from './transactions-routing.module'

@NgModule({
  declarations: [
    ListTransactionsComponent,
    TransactionDashboardComponent,
    TransactionDetailComponent,
    TransactionComponent,
    TransactionsSummaryComponent,
    TransactionsPercentageSummaryBarChartComponent,
    TransactionsRawSummaryBarChartComponent,
    TransactionsRawSummaryDoughnutChartComponent,
    TransactionsPercentageSummaryDoughnutChartComponent,
    RejectedTransactionsRateLineChartComponent,
    TransactionsSummaryGeneralViewModeComponent,
    TransactionsSummarySdkViewModeComponent,
    TransactionsSummaryViewModeOptionsComponent,
  ],
  imports: [CoreModule, TransactionRoutingModule],
})
export class TransactionsModule {}
