import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import * as ChartDataLabels from 'chartjs-plugin-datalabels'
import { NumberHelper } from 'src/app/core/helpers/number.helper'
import { TransactionsRawSummaryBarChartData } from '../../domain/models/transactions-raw-summary-bar-chart-data'

@Component({
  selector: 'app-transactions-raw-summary-bar-chart',
  templateUrl: './transactions-raw-summary-bar-chart.component.html',
  styleUrls: ['./transactions-raw-summary-bar-chart.component.css'],
})
export class TransactionsRawSummaryBarChartComponent
  implements OnInit, OnChanges
{
  @Input() datasets!: TransactionsRawSummaryBarChartData

  _datasets: {
    labels?: string[]
    values?: { label: string; backgroundColor: string; data: number[] }[]
  } = undefined

  _options = {
    plugin: ChartDataLabels,
    plugins: {},
    yAxes: {},
  }

  constructor(private numberHelper: NumberHelper) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.datasets.currentValue) {
      this.setData(changes.datasets.currentValue)
    }
  }

  private setData(datasets: TransactionsRawSummaryBarChartData) {
    this.setOptions(datasets)

    this._datasets = {
      labels: datasets.labels,
      values: [
        {
          label: 'Aprovados',
          backgroundColor: '#211858',
          data: datasets.successRawData.map((it) => Number(it)),
        },
        {
          label: 'Rejeitados',
          backgroundColor: '#FF6D40',
          data: datasets.failedRawData.map((it) => Number(it)),
        },
      ],
    }
  }

  private setOptions(datasets: TransactionsRawSummaryBarChartData): void {
    this._options = {
      plugin: ChartDataLabels,
      plugins: {
        datalabels: {
          color: 'white',
          rotation: (ctx) => {
            return datasets?.labels?.length >= 15 ? 270 : 0
          },
          formatter: (value, ctx) => {
            return value == 0 ? '' : this.numberHelper.format(Number(value))
          },
          font: {
            weight: 'bold',
            size: this.getFontSize(datasets),
          },
        },
      },
      yAxes: {
        stepSize: this.getStepSize(
          datasets.successRawData.map((it) => Number(it)),
          datasets.failedRawData.map((it) => Number(it))
        ),
      },
    }
  }

  private getFontSize(datasets: TransactionsRawSummaryBarChartData) {
    return datasets.labels?.length >= 30 ? 11 : 14
  }

  private getStepSize(successData: number[], failedData: number[]): number {
    let min = Number(successData[0])
    let max = Number(successData[1])

    successData
      .map((it) => Number(it))
      .forEach((it) => {
        if (it > max) max = it
        if (it < min) min = it
      })

    failedData
      .map((it) => Number(it))
      .forEach((it) => {
        if (it > max) max = it
        if (it < min) min = it
      })
    const step = (max - min) / 10
    return Number(step.toFixed(0))
  }
}
