import { NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { FormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha'
import { CoreModule } from './core/core.module'
import { TokenInterceptorService } from './core/security/token.interceptor'
import { CompaniesModule } from './modules/companies/companies.module'
import { SdksModule } from './modules/sdks/sdks.module'
import { TransactionsModule } from './modules/transactions/transactions.module'
import { TranslationsModule } from './modules/translations/translations.module'
import { UsersModule } from './modules/users/users.module'

@NgModule({
  declarations: [AppComponent],
  imports: [
    RecaptchaV3Module,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TransactionsModule,
    CoreModule,
    CompaniesModule,
    UsersModule,
    SdksModule,
    TranslationsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LdALoAcAAAAAFNgQL1lCMx4C2Jk9gogueReBi2K',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
