import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class DateHelper {
  /**
   * Formata data de yyyy-MM-dd para dd/MM/yyyy
   */
  formatDateString(value: string): string {
    if (value) {
      let newValue = ''
      let aux = value.split('-')
      if (aux.length == 3) {
        newValue = aux[2] + '/' + aux[1] + '/' + aux[0]
        return newValue
      }
    }
    return null
  }

  /**
   * Formata data de dd/MM/yyyy para yyyy-MM-dd
   */
  unformatDateString(value?: string): string | null {
    if (value) {
      let newValue = ''
      let aux = value.split('/')
      if (aux.length == 3) {
        newValue = aux[2] + '-' + aux[1] + '-' + aux[0]
        return newValue
      }
    }
    return null
  }

  /**
   * Formata data e hora de dd/MM/yyyy HH:mm para yyyy-MM-dd HH:mm
   */
  unformatDateTimeString(value: string, hour?: string): string {
    if (value) {
      let newValue = ''
      value = value.replace(',', '')
      let aux = value.split('/')
      if (aux.length == 3) {
        let aux2 = aux[2].split(' ')
        if (aux2.length == 2) {
          newValue =
            aux2[0] +
            '-' +
            aux[1] +
            '-' +
            aux[0] +
            ' ' +
            (hour ? hour : aux2[1])
        }
        return newValue
      }
    }
    return null
  }

  isDateValid(dateStr: string): boolean {
    return !isNaN(Date.parse(dateStr))
  }

  /**
   * Formata data de yyyy-MM-ddThh:mm:ss ou yyyy-MM-dd hh:mm:ss para dd/MM/yyyy hh:mm:ss
   */
  formatDatetimeString(value: string): string {
    if (value) {
      let newValue = ''
      let aux = value.split('-')
      if (aux.length == 3) {
        aux[2] = aux[2].replace(' ', 'T')
        let aux2 = aux[2].split('T')
        if (aux2.length == 2) {
          if (aux2[0].length < 2) aux2[0] = '0' + aux2[0]
          if (aux[1].length < 2) aux[1] = '0' + aux[1]
          newValue = aux2[0] + '/' + aux[1] + '/' + aux[0] + ' ' + aux2[1]
        }
        return newValue
      }
    }
    return ''
  }

  getIsoDateStringWithNoTime(date: Date): string {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return `${year}-${month > 9 ? month : '0' + month}-${
      day > 9 ? day : '0' + day
    }`
  }

  isoStringtoDate(date: string): Date | undefined {
    if (!date) return

    const splitted = date.split('-')

    if (splitted.length != 3) return

    const dt = new Date()
    dt.setFullYear(Number(splitted[0]))
    dt.setMonth(Number(splitted[1]) - 1)
    dt.setDate(Number(splitted[2]))
    return dt
  }
}
