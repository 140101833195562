import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import {
  Translation,
  TranslationList,
} from '../domain/models/translation.model'

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private apiUrl = `${environment.apiUrl}`
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  }

  constructor(private httpClient: HttpClient) {}

  listTranslations(page: number): Observable<TranslationList> {
    return this.httpClient.post<TranslationList>(
      this.apiUrl + 'translation/list?page=' + page,
      this.httpOptions
    )
  }

  saveTranslation(translation: Translation): Observable<string> {
    return this.httpClient.post<string>(
      this.apiUrl + 'translation/' + (translation.id ? 'update' : 'add'),
      translation,
      this.httpOptions
    )
  }
}
