export interface TranslationList {
  count: number;
  list: Translation[];
}

export class Translation {
  id: number;
  value: string;
  locale: any;
  translation: string;
  tableName: string;
  columnName: string;
}
