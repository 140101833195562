import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { HasLoggedUserPermission } from '../domain/usecases/has-logged-user-permission'

@Directive({
  selector: '[appShowByUserPermission]',
})
export class ShowByUserPermissionDirective {
  @Input('appShowByUserPermission') set showByUserPermission(
    permission: string
  ) {
    if (this.hasLoggedUserPermission.execute(permission)) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    } else {
      this.viewContainer.clear()
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private hasLoggedUserPermission: HasLoggedUserPermission
  ) {}
}
