import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  private apiUrl = `${environment.apiUrl}`
  private apiCoreUrl = `${environment.apiCoreUrl}`
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  }

  constructor(private httpClient: HttpClient) {}

  listAllSdks(): Observable<any> {
    return this.httpClient.get<any>(
      this.apiUrl + 'sdk/list-all',
      this.httpOptions
    )
  }

  // core endpoints (may pass by HUB or directly to CORE)
  filterTransaction(payload: any, urlParams: string): Observable<any> {
    return this.httpClient.post<any>(
      this.apiCoreUrl + 'transaction?' + urlParams,
      payload,
      this.httpOptions
    )
  }

  getTransactionContent(tid: string): Observable<any> {
    let payload = {
      tid: tid,
    }
    return this.httpClient.post<any>(
      this.apiCoreUrl + 'transaction?purpose=CONTENT',
      payload,
      this.httpOptions
    )
  }

  dashboardTransaction(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      this.apiCoreUrl + 'dashboard',
      payload,
      this.httpOptions
    )
  }
}
