import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment'
import { ChangePassword } from '../../domain/models/change-password'
import { UserAuthorized } from '../../domain/models/user-authorized'
import { StorageHelper } from '../../helpers/storage.helper'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  userAuthorized: UserAuthorized
  passwordDialog: boolean
  changePassword: ChangePassword
  mustDefinePassword: boolean
  permissions: string[] = []
  minimumSize: number
  requirements: string[] = []
  requirementsAllOk: boolean
  requirementsOk: boolean[] = []
  requirementsTitle: string[] = []

  constructor(private router: Router, private storageHelper: StorageHelper) {}

  ngOnInit(): void {
    this.loadRequirementsPassword()
    this.userAuthorized = new UserAuthorized()
    this.userAuthorized.name = this.storageHelper.getBy('auth_name') // to shows user name at header page
    let rp = this.storageHelper.removeBy('rp')
    if (rp) {
      // when a user has a default password, it must redefine password (rp) after login
      this.mustDefinePassword = true
      this.showChangePasswordDialog()
      this.changePassword.passwordOld = rp
    }
  }

  loadRequirementsPassword() {
    let temp = `${environment.requirementsPassword}`
    let array = temp.split('_')
    this.minimumSize = Number(array[0])
    temp = array[1]
    if (temp.indexOf('A') >= 0) {
      this.requirements.push('A')
      this.requirementsTitle.push('Pelo menos uma letra maíuscula')
    }
    if (temp.indexOf('a') >= 0) {
      this.requirements.push('a')
      this.requirementsTitle.push('Pelo menos uma letra minúscula')
    }
    if (temp.indexOf('0') >= 0) {
      this.requirements.push('0')
      this.requirementsTitle.push('Pelo menos um dígito')
    }
    if (temp.indexOf('@') >= 0) {
      this.requirements.push('@')
      this.requirementsTitle.push('Pelo menos um símbolo')
    }
  }

  showChangePasswordDialog() {
    this.changePassword = new ChangePassword()
    this.passwordDialog = true
  }

  logout() {
    this.router.navigate(['login']).then(() => this.storageHelper.clearAll())
    let recaptcha = document.getElementsByClassName(
      'grecaptcha-badge'
    ) as HTMLCollectionOf<HTMLElement>
    if (recaptcha && recaptcha.item(0) && recaptcha.item(0).style)
      recaptcha.item(0).style.visibility = 'visible' // shows recaptcha again at login page
  }

  home() {
    this.router.navigate(['transacttions/dashboard'])
  }

  isRequirementOk(index: number) {
    return this.requirementsOk.length > index && this.requirementsOk[index]
  }
}
