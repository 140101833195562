<!-- new/edit sdk dialog -->
<p-dialog [(visible)]="_showSdkDialog" [style]="{width: '450px'}" [header]="_isEdittingMode ? 'Editar SDK': 'Novo SDK'"
    [modal]="true" styleClass="p-fluid" (onHide)="cancelForm()">
    <ng-template pTemplate="content">
        <div *ngIf="!_isEdittingMode" class="p-d-flex p-flex-column">
            <app-label class="p-pb-2" label="SDK" size="12" classes="p-text-bold"></app-label>
            <app-dropdown [disabled]="_isEdittingMode" labelClasses="p-mb-2" title="" [initialOption]="_selectedSdk"
                [options]="_sdkList" (selected)="selectSdk($event)"></app-dropdown>
            <small class="p-error" *ngIf="_sdkSubmitted && !_selectedSdk">SDK é obrigatório.</small>
        </div>
        <div class="p-mt-2 p-d-flex p-flex-column" *ngIf="_sdkEndpoints?.length">
            <app-text class="p-pb-2 p-pl-2" text="Serviços" size="10" classes="p-text-bold"></app-text>
            <div *ngFor="let endpoint of _sdkEndpoints">
                <input type="checkbox" [id]="endpoint.id" [checked]="endpoint.checked"
                    (change)="endpoint.checked = !endpoint.checked">
                <span class="p-pl-2">{{endpoint.description}}</span>
            </div>
            <small class="p-error" *ngIf="_sdkSubmitted && _sdkEndpointsNotSelected">Nenhum serviço selecionado.</small>
        </div>
        <div class="p-grid p-flex-column p-mt-3">
            <div class="p-col p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                <app-label label="Token" size="12" classes="p-text-bold"></app-label>
            </div>
            <div class="p-col p-grid vertical-container">
                <div class="p-col-9 p-as-center">
                    <app-input title="Gerado automático e não editável" type="text" placeHoldText="Gerado automático"
                        classes="p-button-lg" customStyle="width: 100%;" disabled="true"
                        [(ngModel)]="_sdkCompanyNew.token"></app-input>
                    <small class="p-error" *ngIf="_sdkSubmitted && !_sdkCompanyNew.token">Token é obrigatório.</small>
                </div>
                <div *ngIf="!_sdkCompanyNew.id" class="p-col-1 p-mr-2">
                    <app-button label="" title="gerar token" theme="secondary" (onClick)="generateToken(_sdkCompanyNew)"
                        icon="refresh" type="rounded"></app-button>
                </div>
                <div class="p-col-1">
                    <app-button label="" title="copiar token" theme="secondary" (onClick)="copyToken(_sdkCompanyNew)"
                        icon="copy" type="rounded"></app-button>
                </div>
            </div>
        </div>
        <div class="p-d-flex p-flex-column">
            <app-label class="p-pb-2" label="Expiração" size="12" classes="p-text-bold"></app-label>
            <app-input type="masked" placeHoldText="dia/mês/ano" mask="99/99/9999"
                [(ngModel)]="_sdkCompanyNew.tokenExpirationDate"></app-input>
            <small class="p-error"
                *ngIf="_sdkSubmitted && (!_sdkCompanyNew.tokenExpirationDate || _tokenExpirationDateInvalid)">Data é
                inválida.</small>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <div class="p-d-flex p-jc-end">
            <app-button (onClick)="cancelForm()" type="rounded" theme="secondary" label="Cancelar"></app-button>
            <app-button (onClick)="saveSdk()" type="rounded" theme="primary" label="Salvar"></app-button>
        </div>
    </ng-template>
</p-dialog>