export enum TransactionType {
  LIVENESS_3D = 'LIVENESS_3D',
  VIVACIDADE_3D = 'LIVENESS_3D',
}

const pathMap = {
  'Liveness 3D': TransactionType[TransactionType.LIVENESS_3D],
  liveness3d: TransactionType[TransactionType.LIVENESS_3D],
  'Vivacidade 3D': TransactionType[TransactionType.LIVENESS_3D],
}

export namespace TransactionType {
  export function getType(path: string): string {
    return pathMap[path]
  }
}
