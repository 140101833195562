import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { Transaction } from '../models/transaction.model'
import { DateHelper } from 'src/app/core/helpers/date.helper'
import { TransactionService } from '../../services/transaction.service'
import { GetLoggedUserCompany } from 'src/app/core/domain/usecases/get-logged-user-company'
import { Constants } from 'src/app/core/helpers/constants'

@Injectable({ providedIn: 'root' })
export class GetTransactionsDashboardData
  implements UseCase<Transaction, Observable<any>>
{
  constructor(
    private dateHelper: DateHelper,
    private transactionService: TransactionService,
    private getLoggedUserCompany: GetLoggedUserCompany
  ) {}

  execute(param: Transaction): Observable<any> {
    let payload = this.buildPayload(param)

    return this.transactionService.dashboardTransaction(payload)
  }

  private buildPayload(param: Transaction) {
    const { to, from } = this.getDateRange(param)
    return {
      cnpj: this.getCnpj(param),
      sdk: this.getSdk(param),
      dateFrom: from,
      dateTo: to,
    }
  }

  private getCnpj(param: Transaction): string | null {
    let cnpj = param.company?.cnpj || null
    if (!param.company?.cnpj && param.company?.name !== Constants.optionTodas) {
      cnpj = this.getLoggedUserCompany.execute().cnpj
    }
    return cnpj
  }

  private getDateRange(param: Transaction): {
    to: string
    from: string
  } {
    let to = param.dateTo
    if (!param.dateTo) {
      to = new Date()
    }

    let from = param.dateFrom
    if (!param.dateFrom) {
      const f = new Date()
      f.setDate(to.getDate() - 1)
      from = f
    }

    return {
      from: this.dateHelper.getIsoDateStringWithNoTime(from),
      to: `${this.dateHelper.getIsoDateStringWithNoTime(to)} 23:59:59`,
    }
  }

  private getSdk(param: Transaction) {
    let sdk = null
    if (param.sdkFilter && param.sdkFilter.name !== Constants.optionTodos) {
      sdk = param.sdkFilter.name
    }

    return sdk
  }
}
