import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
  {
    path: 'users',
    loadChildren: () =>
      import('./modules/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'companies',
    loadChildren: () =>
      import('./modules/companies/companies.module').then(
        (m) => m.CompaniesModule
      ),
  },
  {
    path: 'transactions',
    loadChildren: () =>
      import('./modules/transactions/transactions.module').then(
        (m) => m.TransactionsModule
      ),
  },
  {
    path: 'sdks',
    loadChildren: () =>
      import('./modules/sdks/sdks.module').then((m) => m.SdksModule),
  },
  {
    path: 'translations',
    loadChildren: () =>
      import('./modules/translations/translations.module').then(
        (m) => m.TranslationsModule
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
