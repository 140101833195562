import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'

export interface TabMenu {
  id: string
  name: string
  icon?: string
}

const iconsMap = {
  android: 'pi pi-android',
  ios: 'pi pi-apple',
  browser: 'pi pi-globe',
  server: 'pi pi-cloud',
  lineChart: 'pi pi-chart-line',
  barChart: 'pi pi-chart-bar',
}

@Component({
  selector: 'app-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.css'],
})
export class TabMenuComponent implements OnChanges {
  @Input() menus: TabMenu[]
  @Input() initialMenu?: TabMenu
  @Output() onSelect = new EventEmitter<TabMenu>()

  _activeMenu: TabMenu
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menus?.currentValue) {
      changes.menus.currentValue.forEach((m) => this.addClickAndParseIcon(m))
      this.menus = changes.menus.currentValue
    }
    if (changes.initialMenu?.currentValue) {
      this.initialMenu = changes.initialMenu.currentValue
      this._activeMenu = this.initialMenu
    }
  }

  setActiveItem(item: TabMenu): void {
    this._activeMenu = item
    this.onSelect.emit(this.menus.find((m) => m.name === item.name))
  }

  private addClickAndParseIcon(menu: any) {
    menu.icon = iconsMap[menu.icon]
    menu.command = (event?: any) => {
      this.setActiveItem(event.item)
    }
  }
}
