import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import {
  CompanyNew,
  SdkNew,
} from 'src/app/modules/companies/domain/models/company-new.model'
import { Contact } from '../../domain/models/contact.model'

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.css'],
})
export class CompanyFormComponent implements OnInit, OnChanges {
  @Input() company?: CompanyNew
  @Output() onSave = new EventEmitter<CompanyNew>()
  @Output() onCancel = new EventEmitter<void>()

  _company: CompanyNew = new CompanyNew()
  _submitted: boolean = false
  _companyInitLocale = {
    id: '',
  }

  constructor(private snackBar: SnackBarService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.company?.currentValue) {
      this._company = changes.company?.currentValue
      this._companyInitLocale = { id: this._company.locale }
    }
  }

  saveCompany(): void {
    if (!this.isFormValid()) {
      this.snackBar.warn('Fornulário inválido', 8, 'top', 'right')
      return
    }

    this.onSave.emit(this._company)
  }

  cancelForm(): void {
    this.onCancel.emit()
  }

  setLocale(locale): void {
    this._company.locale = locale.id
  }

  setCompanySdks(sdkCompanyList: SdkNew[]): void {
    this._company.sdks = sdkCompanyList
  }

  setContacts(contactList: Contact[]) {
    this._company.contacts = contactList
  }

  resetForm(): void {
    this._company = new CompanyNew()
    this._submitted = false
  }

  private isFormValid(): boolean {
    return Boolean(
      this._company.contacts?.length > 0 &&
        this._company.sdks?.length > 0 &&
        this._company.name &&
        this._company.cnpj &&
        this._company.codrev
    )
  }
}
