<app-title title="CONTROLE DE RECURSOS"></app-title>

<div class="p-col" style="max-width: 100%;">
    <p-card>
        <button pButton icon='pi pi-plus' (click)="openForm(null)" class="p-button-rounded primaryButton"
            label="Adicionar" title="Clique para abrir o formulário para adicionar recurso"></button>
        <div class="p-col"></div>
        <div class="card" id="table-card">
            <p-table [value]="resources" [scrollable]="true" [resizableColumns]="true" [rows]="5" [rowHover]="true"
                dataKey="id" [showCurrentPageReport]="true">
                <ng-template pTemplate="colgroup">
                    <colgroup>
                        <col style="width:40px">
                        <col style="width:150px">
                        <col style="width:150px">
                        <col style="width:120px">
                        <col style="width:40px">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr id="columns">
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Descrição (em inglês)</th>
                        <th>Data/hora</th>
                        <th>Editar</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-resource>
                    <tr [style]="resource.enabled ? '' : 'text-decoration: line-through'">
                        <td>{{resource.id}}</td>
                        <td>{{resource.name}}</td>
                        <td>{{resource.descriptionOriginal}}</td>
                        <td>{{resource.creationDate | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                        <td>
                            <button pButton pRipple icon="pi pi-pencil" class="secundaryButton p-button-rounded p-mr-2"
                                (click)="openForm(resource)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Total: {{ count }} recurso{{ count > 1 ? 's' : '' }}
                    </div>
                </ng-template>
            </p-table>
            <p-paginator [rows]="10" (onPageChange)=changePage($event) [totalRecords]="count"></p-paginator>
        </div>
    </p-card>

    <p-dialog [(visible)]="resourceDialog" [style]="{width: '400px'}" header="Detalhes do Recurso" [modal]="true"
        styleClass="p-fluid" (onHide)="hideDialog()">
        <ng-template pTemplate="content">
            <div class="p-field">
                <label class="p-mr-2" for="name">{{"Nome" + (resource.id ? " (não é possível alterar)" : "")}}</label>
                <input type="text" pInputText id="name" [(ngModel)]="resource.name" required autofocus
                    [readOnly]="resource.id" />
                <small class="p-error" *ngIf="submitted && !resource.name">Nome do recurso é obrigatório.</small>
            </div>
            <div class="p-field">
                <label class="p-mr-2" for="description">Descrição (deve ser em inglês)</label>
                <input type="text" pInputText id="description" [(ngModel)]="resource.descriptionOriginal" required
                    autofocus />
                <small class="p-error" *ngIf="submitted && !resource.descriptionOriginal">Descrição do recurso é
                    obrigatória (em inglês).</small>
            </div>
            <div class="p-field">
                <label for="status">Status</label>
                <input type="checkbox" id="status" [checked]="resource.enabled"
                    (change)="resource.enabled = !resource.enabled" autofocus>
            </div>
            <div class="p-field">
                <div *ngIf="resource.creationDate">
                    <label class="p-mr-2" class="p-mr-1">Data/hora criação:</label>
                    <label>{{resource.creationDate | date:'dd/MM/yyyy HH:mm:ss'}}</label>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <app-button classes="p-mr-4" label="Cancelar" type="rounded" theme="secondary" icon="cancel"
                title="Clique para alterar a senha" (onClick)="hideDialog()"></app-button>

            <app-button label="Salvar" type="rounded" theme="primary" icon="check"
                (onClick)="preSaveResource()"></app-button>
        </ng-template>
    </p-dialog>
</div>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
<p-toast></p-toast>