import { Resource } from './resource.model'

export interface ProfileList {
  count: number
  list: Profile[]
}

export class Profile {
  id: number
  name: string
  description: string
  creationDate: string
  resourceList: Resource[]
  countPermissions: string

  constructor(name?: string) {
    this.name = name ? name : null
  }
}
