import { Injectable } from '@angular/core'
import { StorageHelper } from './storage.helper'
import { Company } from '../../modules/companies/domain/models/company.model'

@Injectable({
  providedIn: 'root',
})
export class CompanyHelper {
  constructor(private storageHelper: StorageHelper) {}

  getCompanyFromLoggedUser(): Company {
    let c = JSON.parse(this.storageHelper.getBy('c'))
    if (c === 'null') {
      c = new Company('', '')
    }
    return c
  }

  saveCompanyFromLoggedUser(c: Company): void {
    this.storageHelper.saveBy('c', JSON.stringify(c || 'null'))
  }
}
