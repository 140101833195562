import { Contact } from './contact.model'
import { Endpoint } from '../../../../core/domain/models/endpoint.model'
import { Sdk } from '../../../../core/domain/models/sdk.model'

export interface CompanyListNew {
  totalElements: number
  content: CompanyNew[]
}

export class CompanyNew {
  id: number
  name: string
  creationDate: string
  cnpj: string
  codrev: string
  locale: any
  contacts: Contact[] = []
  sdks: SdkNew[] = []

  constructor(name: string = null, cnpj: string = null) {
    this.name = name
    this.cnpj = cnpj
  }

  static parseToCreateCompany(comp: CompanyNew): CompanyNew {
    comp.sdks = comp.sdks.map(
      (s) =>
        <SdkNew>{
          id: s.id,
          token: s.token,
          tokenExpirationDate: s.tokenExpirationDate,
          endpoints: s.endpoints.map(
            (e) =>
              <EndpointNew>{
                id: e.id,
              }
          ),
        }
    )

    return comp
  }
}

export class SdkNew {
  id: number
  name: string
  description: string
  descriptionOriginal: string
  enabled: boolean
  version: string
  creationDate: string
  endpoints: EndpointNew[] = []
  token: string
  tokenExpirationDate: string

  // indicators
  running?: Boolean = null
  runningStatus?: String = 'Carregando...'
  cpu?: number
  cpuStr?: string = ''
  ram?: number
  ramStr?: string = ''
  hd?: number
  hdStr?: string = ''
  errorMessage?: string

  constructor(name?: string) {
    this.name = name ? name : null
  }

  static copy(sdk: SdkNew): SdkNew {
    const {
      id,
      name,
      description,
      descriptionOriginal,
      enabled,
      version,
      creationDate,
      endpoints: endpoints,
      token,
      tokenExpirationDate,
    } = sdk
    return {
      id,
      name,
      description,
      descriptionOriginal,
      enabled,
      version,
      creationDate,
      endpoints,
      token,
      tokenExpirationDate,
    }
  }

  static copyFromOld(old: Sdk, current: SdkNew): void {
    current.id = old.id
    current.name = old.name
    current.description = old.description
    current.descriptionOriginal = old.descriptionOriginal
    current.enabled = old.enabled
    current.version = old.version
    current.creationDate = old.creationDate
  }
}

export class EndpointNew {
  id: number
  description?: string = ''
  descriptionOriginal?: string = ''
  name: string = ''
  enabled?: boolean
  checked?: boolean

  callUsage?: number // transient attribute (auxiliar variable)
  callLimit?: number // transient attribute (auxiliar variable)

  constructor(description?: string) {
    this.description = description ? description : null
  }

  static mapToOld(current: EndpointNew): Endpoint {
    return {
      id: current.id,
      description: current.description,
      descriptionOriginal: current.descriptionOriginal,
      name: current.name,
      enabled: current.enabled,
      checked: current.checked,
    }
  }
}

/*
{
    "name": "teste Leonardo",
    "cnpj": "22138061000152",
    "codrev": "4",
    "locale": "PT_BR", //TODO: eh um enum com esse nome?
    "contacts": [
        {
            "name": "Juliana Andrade",
            "email": "juliana.andrade@certisign.com.br",
            "phone": "+55 11 12345-6789",
            "department": "Gerência de Produtos"
        }
    ],
    "sdks": [
        {
            "id": 1,
            "token": "{{$randomUUID}}",
            "tokenExpirationDate": "2024-12-13",
            "endpoints": [
                {
                    "id": 4
                }
            ]
        }
    ]
}


list
{
    "id": 13,
    "sdks": [
        {
            "id": 1,
            "name": "Facetec",
            "description": "SDK for liveness validation",
            "descriptionOriginal": null,
            "enabled": true,
            "version": "9.4.6",
            "creationDate": "2021-09-23",
            "token": "7db32c58-0b7f-4938-9a64-306bdccf3a2d",
            "tokenExpirationDate": "2024-12-13",
            "endpoints": [
                {
                    "id": 4,
                    "name": "liveness3d",
                    "callUsage": null,
                    "callLimit": null,
                    "description": "Liveness 3D",
                    "descriptionOriginal": null,
                    "enabled": true
                }
            ]
        }
    ],
    "contacts": [
        {
            "id": 20,
            "name": "Juliana Andrade",
            "email": "juliana.andrade@certisign.com.br",
            "phone": "+55 11 12345-6789",
            "department": "Gerência de Produtos",
            "creationDate": "2024-04-04T13:18:45"
        }
    ],
    "name": "teste Leonardo",
    "creationDate": "2024-04-04T13:18:45",
    "cnpj": "22138061000152",
    "codrev": "4",
    "locale": "pt_BR"
}
*/
