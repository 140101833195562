import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { DateHelper } from 'src/app/core/helpers/date.helper'
import { environment } from 'src/environments/environment'
import { TransactionService } from '../../services/transaction.service'
import { FilterTransactionsData } from '../models/filter-transactions-data'
import { TransactionList } from '../models/transaction.model'
import { RenameSdkNewToOld } from './rename-sdk-new-to-old'

@Injectable({ providedIn: 'root' })
export class FilterTransactions
  implements UseCase<FilterTransactionsData, Observable<TransactionList>>
{
  private transactionPageSize = `${environment.transactionPageSize}`

  constructor(
    private dateHelper: DateHelper,
    private renameSdkNewToOld: RenameSdkNewToOld,
    private transactionService: TransactionService
  ) {}

  execute(param: FilterTransactionsData): Observable<TransactionList> {
    let payload = this.buildPayload(param)

    param.page = param.page || 0

    const urlParams = `purpose=${param.purpose}&page=${param.page}&limit=${this.transactionPageSize}`

    return this.transactionService.filterTransaction(payload, urlParams)
  }

  private buildPayload(param: FilterTransactionsData) {
    return {
      cnpj: param.data.cnpj,
      sdk: this.renameSdkNewToOld.execute(param.data.sdk),
      path: param.data.path,
      dateFrom: param.data.dateFrom
        ? this.dateHelper.unformatDateTimeString(
            param.data.dateFrom.toLocaleString()
          )
        : null,
      dateTo: param.data.dateTo
        ? this.dateHelper.unformatDateTimeString(
            param.data.dateTo.toLocaleString()
          )
        : null,
    }
  }
}
