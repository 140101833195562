import { Injectable } from '@angular/core'
import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}
  private ref: MatSnackBarRef<TextOnlySnackBar>

  info(
    message: string,
    durationInSec: number,
    verticalPosition?: 'top' | 'bottom',
    horizontalPosition?: 'start' | 'center' | 'end' | 'left' | 'right'
  ) {
    this.ref = this.snackBar.open(message, 'x', {
      duration: durationInSec * 1000,
      verticalPosition: verticalPosition || 'bottom',
      horizontalPosition: horizontalPosition || 'center',
      panelClass: ['snackbar-info'],
    })
  }

  warn(
    message: string,
    durationInSec: number,
    verticalPosition?: 'top' | 'bottom',
    horizontalPosition?: 'start' | 'center' | 'end' | 'left' | 'right'
  ) {
    this.ref = this.snackBar.open(message, 'x', {
      duration: durationInSec * 1000,
      verticalPosition: verticalPosition || 'bottom',
      horizontalPosition: horizontalPosition || 'center',
      panelClass: ['snackbar-warn'],
    })
  }

  error(
    message: string,
    durationInSec: number,
    verticalPosition?: 'top' | 'bottom',
    horizontalPosition?: 'start' | 'center' | 'end' | 'left' | 'right'
  ) {
    this.ref = this.snackBar.open(message, 'x', {
      duration: durationInSec * 1000,
      verticalPosition: verticalPosition || 'bottom',
      horizontalPosition: horizontalPosition || 'center',
      panelClass: ['snackbar-error'],
    })
  }

  success(
    message: string,
    durationInSec: number,
    verticalPosition?: 'top' | 'bottom',
    horizontalPosition?: 'start' | 'center' | 'end' | 'left' | 'right'
  ) {
    this.ref = this.snackBar.open(message, 'x', {
      duration: durationInSec * 1000,
      verticalPosition: verticalPosition || 'bottom',
      horizontalPosition: horizontalPosition || 'center',
      panelClass: ['snackbar-success'],
    })
  }

  closeSnackBar() {
    this.ref?.dismiss()
  }
}
