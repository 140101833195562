import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Sdk } from 'src/app/core/domain/models/sdk.model'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { SdkService } from 'src/app/core/service/sdk.service'

@Injectable({ providedIn: 'root' })
export class GetSdkStatus implements UseCase<Sdk[], Observable<Sdk[]>> {
  constructor(private sdkService: SdkService) {}

  execute(sdks: Sdk[]): Observable<Sdk[]> {
    return new Observable((subs) => {
      this.removeHybridSdk(sdks)

      this.sdkService.retrieveStatusSdk(sdks.map((s) => s.name)).subscribe(
        (data) => {
          const mappedStatus = data.reduce((map, it) => {
            map[it.sdk] = it
            return map
          }, {})

          sdks.forEach((s) => {
            // for each SDK put information about machine indicators received from BAM
            let sdk = mappedStatus[s.name]
            if (sdk) {
              this.setStatus(s, sdk)
            }
          })

          subs.next(sdks)
        },
        (_) => {
          sdks.forEach((s) => {
            s.running = false
            s.runningStatus = 'Não foi possível obter o status'
          })

          subs.next(sdks)
        }
      )
    })
  }

  private removeHybridSdk(sdks: Sdk[]) {
    let hybridIndex = sdks.findIndex((s) => s.name === 'Hybrid')
    if (hybridIndex >= 0)
      // Hybrid don´t list (not exist at MySQL db)
      sdks.splice(hybridIndex, 1)
  }

  private setStatus(s: Sdk, sdk: any) {
    s.running = sdk.running
    s.runningStatus = s.running ? 'Em execução' : 'Parado'
    s.cpu = isNaN(sdk.cpu) ? null : Number(sdk.cpu)
    s.cpuStr = s.cpu ? s.cpu + '%' : ''
    s.ram = isNaN(sdk.ram) ? null : Number(sdk.ram)
    s.ramStr = s.ram ? s.ram + '%' : ''
    s.hd = isNaN(sdk.hd) ? null : Number(sdk.hd)
    s.hdStr = s.hd ? s.hd + '%' : ''
    s.errorMessage = sdk.errorMessage
  }
}
