import { Injectable } from '@angular/core'
import { FilterData } from 'src/app/core/domain/models/filter-data'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { Profile, ProfileList } from '../models/profile.model'
import { Observable } from 'rxjs'
import { ProfileService } from '../../services/profile.service'

@Injectable({ providedIn: 'root' })
export class FilterUserProfiles
  implements UseCase<FilterData<Profile>, Observable<ProfileList>>
{
  constructor(private profileService: ProfileService) {}

  execute(filter: FilterData<Profile>): Observable<ProfileList> {
    filter.page = filter.page || 0
    return this.profileService.filterProfile(filter.data, filter.page)
  }
}
