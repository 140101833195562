export const environment = {
  production: true,
  environment: 'Production',

  apiUrl: 'https://prod-adm-api.bioservices.certisign.com.br/msadmbioengapi/api/v1/',
  apiCoreUrl: 'https://prod-core-api.bioservices.certisign.com.br/api/v1/', // CORE
  
  bioAnalyticsApi: {
    url: 'https://analytics.bioservices.certisign.com.br/bio-analytics/api/v1',
    authHeader: 'X-API-KEY',
    authKey: '0RnfZLEaX7PYI7UsJzjG',
    defaultDateRangeInDays: 5,
	language: 'pt-BR',
  },

  bcryptSalt: '$2a$10$Ev6tnzGFJImGXEqbetlaEe', // PROD Key for generating user password encryption (using BCrypt). It is also defined in the backend (the same key).
  storageShuffleNumbers: '9240183657', // PROD Assorted numbers that make up the encryption of user permissions (based on the Vigenère cipher)
  requirementsPassword: '5_Aa0@',
  transactionPageSize: 10,
  configManagerPermission: 'config.manager'
};
