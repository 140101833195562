import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { Subject } from 'rxjs'
import { throttleTime } from 'rxjs/operators'
import { Constants } from '../../helpers/constants'

const buttonClassesMap = {
  rounded: 'p-button-rounded',
  raised: 'p-button-raised',
  primary: 'primaryButton',
  secondary: 'secundaryButton',
  third: 'thirdButton',
}

const iconMap = {
  add: 'pi pi-plus',
  filter: 'pi pi-filter',
  cancel: 'pi pi-times',
  edit: 'pi pi-pencil',
  delete: 'pi pi-trash',
  file: 'pi pi-file-excel',
  refresh: 'pi pi-replay',
  copy: 'pi pi-copy',
  download: 'pi pi-download',
  lock: 'pi pi-lock',
  signOut: 'pi pi-sign-out',
  check: 'pi pi-check',
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css'],
})
export class ButtonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label!: string
  @Input() title?: string = ''
  @Input() debounceTimeInSec?: number = 2
  @Input() icon?:
    | 'add'
    | 'filter'
    | 'cancel'
    | 'edit'
    | 'delete'
    | 'file'
    | 'refresh'
    | 'copy'
    | 'download'
    | 'lock'
    | 'signOut'
    | 'check'
  @Input() type?: 'rounded' | 'raised' = 'raised'
  @Input() theme?: 'primary' | 'secondary' | 'third' = 'primary'
  @Input() disabled?: boolean = false
  @Input() classes?: string = ''
  @Output() onClick = new EventEmitter<void>()

  private _filterEvent = new Subject()

  _icon: string | undefined

  constructor() {}

  ngOnInit(): void {
    this._icon = this.resolveIcon()
    this._filterEvent
      .pipe(throttleTime(this.debounceTimeInSec * 1000))
      .subscribe((_) => this.emitEvent())
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.label?.currentValue) {
      this.label = changes.label.currentValue
    }
    if (changes.title?.currentValue) {
      this.title = changes.title.currentValue
    }
    if (changes.debounceTimeInSec?.currentValue) {
      this.debounceTimeInSec = changes.debounceTimeInSec.currentValue
    }
    if (changes.icon?.currentValue) {
      this.icon = changes.icon.currentValue
    }
    if (changes.type?.currentValue) {
      this.type = changes.type.currentValue
    }
    if (changes.theme?.currentValue) {
      this.theme = changes.theme.currentValue
    }
    if (changes.disabled?.currentValue) {
      this.disabled = changes.disabled.currentValue
    }
  }

  resolveIcon(): string | undefined {
    return iconMap[this.icon]
  }

  ngOnDestroy(): void {
    this._filterEvent.unsubscribe()
  }

  emitEvent() {
    this.onClick.emit()
  }

  handleEvent() {
    this._filterEvent.next()
  }

  getClass(): string {
    let classes = ''

    if (this.type) {
      classes += ` ${buttonClassesMap[this.type]}`
    }

    if (this.theme) {
      classes += ` ${buttonClassesMap[this.theme]}`
    }

    if (this.theme) return classes
  }
}
