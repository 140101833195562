<div class="p-grid p-m-0" style="height: 990px;">
    <div class="p-col-7 login-picture-box">
        <img class="login-picture" alt="" src="/assets/img/login-picture.svg">
    </div>
    <div class="p-col p-nogutter p-m-6 p-p-6 login-form-box p-grid p-flex-column p-jc-center">
        <div class="p-col-4 login-logo-frame p-pt-4 p-pb-4">
            <img alt="" class="login-logo-img" src="/assets/img/logo.svg">
        </div>
        <div class="p-col-4 login-app-name p-pt-3 p-pb-2">
            IDManager
        </div>
        <div class="p-col-4 login-input-data p-pt-4 p-pb-4 p-pl-4 p-pr-4">
            <div class="p-pl-6 p-pr-6">
                <div class="p-grid p-nogutter p-flex-column p-jc-center p-pt-3 p-pb-3">
                    <app-label class="p-pr-2 p-mt-2 p-pb-2" label="Email" size="12"></app-label>
                    <app-input customStyle="width: 100%" type="text" [(ngModel)]="userCredentials.email"></app-input>
                </div>
                <div class="p-grid p-nogutter p-flex-column p-jc-center p-pt-3 p-pb-4">
                    <app-label class="p-pr-2 p-mt-2 p-pb-2" label="Senha" size="12"></app-label>
                    <app-input type="password" [(ngModel)]="userCredentials.password"></app-input>
                </div>
                <div class="p-grid p-nogutter p-flex-column p-jc-center p-pt-4 p-pb-4">
                    <app-button (onClick)="sendLogin()" type="raised" theme="secondary" label="Entrar"></app-button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="p-grid footer">
    <div class="p-col p-as-center p-text-center">
        <a class="links" href="https://www.certisign.com.br/" target="_blank">
            <span class="certisign-certificadora-digita2">2024 @ Certisign Certificadora Digital</span>
        </a> |
        <a class="links" href="https://www.certisign.com.br/certisign/area-de-conhecimento/politicas" target="_blank">
            <span class="certisign-certificadora-digita2">Políticas</span>
        </a>
    </div>
</div>