import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import {
  CompanyListNew,
  CompanyNew,
} from '../../modules/companies/domain/models/company-new.model'

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private path: string = `${environment.apiUrl}companies`
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  }

  constructor(private httpClient: HttpClient) {}

  saveCompany(company: CompanyNew): Observable<number> {
    if (company.cnpj) company.cnpj = company.cnpj.replace(/\D/g, '') // let only digits at CNPJ
    return this.httpClient.post<number>(this.path, company, this.httpOptions)
  }

  update(company: CompanyNew): Observable<void> {
    return this.httpClient.put<void>(this.path, company, this.httpOptions)
  }

  getById(id: number): Observable<CompanyNew> {
    return this.httpClient.get<CompanyNew>(`${this.path}/${id}`)
  }

  filterCompany(
    companyFilter: CompanyNew,
    page: number
  ): Observable<CompanyListNew> {
    return this.httpClient.post<CompanyListNew>(
      `${this.path}/query?page=${page}&size=${environment.transactionPageSize}`,
      companyFilter,
      this.httpOptions
    )
  }

  listAllCompanies(): Observable<CompanyListNew> {
    return this.httpClient.get<CompanyListNew>(this.path, this.httpOptions)
  }
}
