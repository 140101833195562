import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { TabMenu } from 'src/app/core/components/tab-menu/tab-menu.component'
import { SdkType } from '../../domain/models/sdk-release'

@Component({
  selector: 'app-sdks-tab-menu',
  templateUrl: './sdks-tab-menu.component.html',
  styleUrls: ['./sdks-tab-menu.component.css'],
})
export class SdksTabMenuComponent implements OnInit {
  @Output() selectedMenu = new EventEmitter<TabMenu>()

  _menus: TabMenu[]
  _initialMenu: TabMenu

  constructor() {}

  ngOnInit(): void {
    this._menus = [
      { id: SdkType.BROWSER, name: 'Browser', icon: 'browser' },
      { id: SdkType.ANDROID, name: 'Android', icon: 'android' },
      { id: SdkType.IOS, name: 'iOS', icon: 'ios' },
      { id: SdkType.SERVER, name: 'Server', icon: 'server' },
    ]
    this._initialMenu = this._menus[0]
    this.setActiveTabMenu(this._menus[0])
  }

  setActiveTabMenu(menu: TabMenu): void {
    this.selectedMenu.emit(menu)
  }
}
