import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'

interface BarChartOptions {
  xAxes: {
    interval?: number[]
    formatLabel?: (label: string) => string
    title?: string
  }
  yAxes: {
    interval?: number[]
    stepSize?: number
    formatLabel?: (label: string) => string
    title?: string
  }
}
@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css'],
})
export class BarChartComponent implements OnInit, OnChanges {
  @Input() options: BarChartOptions

  @Input() datasets: {
    labels: string[]
    values: { label: string; backgroundColor: string; data: number[] }[]
  }

  _data: any = undefined
  _plugin: any

  _options: any = {
    plugin: {},
    plugins: {},
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {},
          scaleLabel: {},
          gridLines: {
            color: 'rgba(255,255,255,0.2)',
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 10,
            beginAtZero: true,
          },
          scaleLabel: {},
          gridLines: {
            // color: 'rgba(255,255,255,0.2)',
          },
          stacked: true,
        },
      ],
    },
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.datasets.currentValue) {
      this._data = undefined
      this._data = {
        labels: changes.datasets.currentValue.labels,
        datasets: changes.datasets.currentValue.values,
      }
    }

    if (changes.options?.currentValue) {
      this._plugin = changes.options?.currentValue.plugin
        ? changes.options?.currentValue.plugin
        : undefined
      this._options.plugins = changes.options.currentValue.plugins
      this.setXAxes(changes.options.currentValue)
      this.setYAxes(changes.options.currentValue)
    }
  }

  private setXAxes(options: BarChartOptions) {
    if (options?.xAxes?.interval && options?.xAxes?.interval?.length == 2) {
      this._options.scales.xAxes[0].ticks.min = options.xAxes.interval[0]
      this._options.scales.xAxes[0].ticks.max = options.xAxes.interval[1]
    }

    if (options?.xAxes?.title) {
      this._options.scales.xAxes[0].scaleLabel.display = true
      this._options.scales.xAxes[0].scaleLabel.labelString = options.xAxes.title
    }

    if (options?.xAxes && options?.xAxes?.formatLabel) {
      this._options.scales.xAxes[0].ticks.callback = (label, index, labels) => {
        return options.xAxes.formatLabel(label)
      }
    }
  }

  private setYAxes(options: BarChartOptions) {
    if (options?.yAxes?.interval && options?.yAxes?.interval?.length == 2) {
      this._options.scales.yAxes[0].ticks.min = options.yAxes.interval[0]
      this._options.scales.yAxes[0].ticks.max = options.yAxes.interval[1]
    }

    if (options?.yAxes?.stepSize) {
      this._options.scales.yAxes[0].ticks.stepSize = options.yAxes.stepSize
    }

    if (options?.yAxes?.title) {
      this._options.scales.yAxes[0].scaleLabel.display = true
      this._options.scales.yAxes[0].scaleLabel.labelString = options.yAxes.title
    }

    if (options?.yAxes?.formatLabel) {
      this._options.scales.yAxes[0].ticks.callback = (label, index, labels) => {
        return options.yAxes.formatLabel(label)
      }
    }
  }
}
