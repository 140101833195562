<app-title title="CONTROLE DE TRADUÇÕES"></app-title>

<div class="p-col" style="max-width: 100%;">
    <p-card>
        <button pButton icon='pi pi-plus' (click)="openForm(null)" class="p-button-rounded primaryButton"
            label="Adicionar" title="Clique para abrir o formulário para adicionar tradução"></button>
        <div class="p-col"></div>
        <div class="card" id="table-card">
            <p-table [value]="translations" [scrollable]="true" [resizableColumns]="true" [rows]="5" [rowHover]="true"
                [autoLayout]="true" dataKey="id" [showCurrentPageReport]="true">
                <ng-template pTemplate="colgroup">
                    <colgroup>
                        <col style="width:40px">
                        <col style="width:250px">
                        <col style="width:60px">
                        <col style="width:250px">
                        <col style="width:80px">
                        <col style="width:40px">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr id="columns">
                        <th>ID</th>
                        <th>Valor</th>
                        <th>Idioma</th>
                        <th>Tradução</th>
                        <th>Tabela</th>
                        <th>Editar</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-translation>
                    <tr>
                        <td>{{translation.id}}</td>
                        <td>{{translation.value}}</td>
                        <td>{{translation.locale}}</td>
                        <td>{{translation.translation}}</td>
                        <td>{{translation.tableName}}</td>
                        <td>
                            <button pButton pRipple icon="pi pi-pencil" class="secundaryButton p-button-rounded p-mr-2"
                                (click)="openForm(translation)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Total: {{ count }} traduç{{ count > 1 ? 'ões' : 'ão' }}
                    </div>
                </ng-template>
            </p-table>
            <p-paginator [rows]="10" (onPageChange)=changePage($event) [totalRecords]="count"></p-paginator>
        </div>
    </p-card>

    <p-dialog [(visible)]="translationDialog" [style]="{width: '400px'}" header="Detalhes da Tradução" [modal]="true"
        styleClass="p-fluid" (onHide)="hideDialog()">
        <ng-template pTemplate="content">
            <div class="p-field">
                <label class="p-mr-2" for="value">Valor (deve ser em inglês)</label>
                <input type="text" pInputText id="value" [(ngModel)]="translation.value" required autofocus />
                <small class="p-error" *ngIf="submitted && !translation.value">Valor referência da tradução é
                    obrigatória.</small>
            </div>
            <div class="p-field">
                <label class="p-mr-2" for="locale">Idioma</label>
                <p-dropdown id="locale" [options]="localeList" optionLabel="label" [(ngModel)]="translation.locale"
                    required></p-dropdown>
                <small class="p-error" *ngIf="submitted && !translation.locale">Idioma é obrigatório.</small>
            </div>
            <div class="p-field">
                <label class="p-mr-2" for="translation">Tradução</label>
                <input type="text" pInputText id="translation" [(ngModel)]="translation.translation" required
                    autofocus />
                <small class="p-error" *ngIf="submitted && !translation.translation">Tradução é obrigatória.</small>
            </div>
            <div class="p-field">
                <label class="p-mr-2" for="tableName">Tabela</label>
                <input type="text" pInputText id="tableName" [(ngModel)]="translation.tableName" required autofocus />
                <small class="p-error" *ngIf="submitted && !translation.tableName">Tabela alvo da tradução é
                    obrigatória.</small>
            </div>
            <div class="p-field">
                <label class="p-mr-2" for="columnName">Coluna</label>
                <input type="text" pInputText id="columnName" [(ngModel)]="translation.columnName" required autofocus />
                <small class="p-error" *ngIf="submitted && !translation.columnName">Coluna alvo da tradução é
                    obrigatória.</small>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <app-button classes="p-mr-4" label="Cancelar" type="rounded" theme="secondary" icon="cancel"
                title="Clique para alterar a senha" (onClick)="hideDialog()"></app-button>

            <app-button label="Salvar" type="rounded" theme="primary" icon="check"
                (onClick)="saveTranslation()"></app-button>
        </ng-template>
    </p-dialog>
</div>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
<p-toast></p-toast>