import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ChangePassword } from '../../../core/domain/models/change-password'
import { User, UserList } from '../domain/models/user.model'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiUrl = `${environment.apiUrl}`
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  }

  constructor(private httpClient: HttpClient) {}

  saveUser(user: User): Observable<User> {
    return this.httpClient.post<User>(
      this.apiUrl + 'user/' + (user.id ? 'update' : 'add'),
      user,
      this.httpOptions
    )
  }

  listAllUsers(): Observable<User[]> {
    return this.httpClient.get<User[]>(
      this.apiUrl + 'user/list-all',
      this.httpOptions
    )
  }

  filterUsers(userFilter: User, page: number): Observable<UserList> {
    return this.httpClient.post<UserList>(
      this.apiUrl + 'user/list?page=' + page,
      userFilter,
      this.httpOptions
    )
  }

  changeStatus(user: User): Observable<string> {
    return this.httpClient.put<string>(
      this.apiUrl + 'user/change-status',
      user,
      this.httpOptions
    )
  }

  changePassword(changePassword: ChangePassword): Observable<string> {
    return this.httpClient.put<string>(
      this.apiUrl + 'user/change-password',
      changePassword,
      this.httpOptions
    )
  }

  resetPassword(id: number): Observable<string> {
    return this.httpClient.put<string>(
      this.apiUrl + 'user/reset-password?id=' + id,
      this.httpOptions
    )
  }
}
