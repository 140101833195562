import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.css'],
})
export class DateRangeFilterComponent implements OnInit, OnChanges {
  @Input() initDateFrom?: Date
  @Input() initDateTo?: Date
  @Input() maxDateValue?: Date

  @Output() selectedRange = new EventEmitter<{
    dateFrom: Date
    dateTo: Date
  }>()

  dateFrom: Date
  dateTo: Date
  startDate = new Date()

  constructor() {}

  ngOnInit(): void {
    if (this.initDateFrom) {
      this.dateFrom = this.initDateFrom
    }

    if (this.initDateTo) {
      this.dateTo = this.initDateTo
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initDateFrom?.currentValue) {
      this.dateFrom = changes.initDateFrom.currentValue
    }

    if (changes.initDateTo?.currentValue) {
      this.dateTo = changes.initDateTo.currentValue
    }
    if (changes.maxDateValue?.currentValue) {
      this.maxDateValue = changes.maxDateValue.currentValue
    }
  }

  setDateFrom(date: Date) {
    this.dateFrom = date
    if (this.dateFrom && this.dateTo) {
      this.selectedRange.emit({
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      })
    }
  }

  setDateTo(date: Date) {
    this.dateTo = date
    if (this.dateFrom && this.dateTo) {
      this.selectedRange.emit({
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      })
    }
  }
}
