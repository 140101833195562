import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import { NumberHelper } from 'src/app/core/helpers/number.helper'
// import * as ChartDataLabels from 'chartjs-plugin-datalabels'

@Component({
  selector: 'app-transactions-raw-summary-doughnut-chart',
  templateUrl: './transactions-raw-summary-doughnut-chart.component.html',
  styleUrls: ['./transactions-raw-summary-doughnut-chart.component.css'],
})
export class TransactionsRawSummaryDoughnutChartComponent
  implements OnInit, OnChanges
{
  @Input() datasets!: number[]

  _datasets: {
    labels: string[]
    values: {
      backgroundColor: string[]
      hoverBackgroundColor: string[]
      data: number[]
    }[]
  }

  options = {
    // plugin: ChartDataLabels,
    // plugins: {
    //   datalabels: {
    //     rotation: (ctx) => {
    //       const valuesBefore = ctx.dataset.data
    //         .slice(0, ctx.dataIndex)
    //         .reduce((a, b) => a + b, 0)
    //       const sum = ctx.dataset.data.reduce((a, b) => a + b, 0)
    //       const rotation =
    //         ((valuesBefore + ctx.dataset.data[ctx.dataIndex] / 2) / sum) * 360
    //       return rotation
    //     },
    //     formatter: (value, ctx) => {
    //       return value == 0 ? '' : this.numberHelper.format(Number(value))
    //     },
    //     color: 'white',
    //     font: {
    //       weight: 'bold',
    //       size: 12,
    //     },
    //   },
    // },
  }

  constructor(private numberHelper: NumberHelper) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.datasets.currentValue) {
      this.setData(changes.datasets.currentValue)
    }
  }

  setData(datasets) {
    this._datasets = {
      labels: ['Aprovados', 'Rejeitados'],
      values: [
        {
          backgroundColor: ['#211858', '#FF6D40'],
          hoverBackgroundColor: ['#211858', '#FF6D40'],
          data: datasets,
        },
      ],
    }
  }
}
