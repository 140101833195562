import { Component, OnInit } from '@angular/core'
import { TabMenu } from 'src/app/core/components/tab-menu/tab-menu.component'
import { SdkRelease } from '../../domain/models/sdk-release'
import { GetLatestReleaseBySdk } from '../../domain/usecases/get-latest-release-by-sdk'

@Component({
  selector: 'app-latest-releases',
  templateUrl: './latest-releases.component.html',
  styleUrls: ['./latest-releases.component.css'],
})
export class LatestReleasesComponent implements OnInit {
  _activeTabMenu: TabMenu
  _activeReleaseNote?: SdkRelease = undefined
  private _releasesMap = {}

  constructor(private getLatestReleaseBySdk: GetLatestReleaseBySdk) {}

  ngOnInit(): void {
    this.getLatestReleaseBySdk.execute().subscribe((releases) => {
      this._releasesMap = releases.reduce((map, release) => {
        map[release.type] = release
        return map
      }, {})

      if (this._activeTabMenu) this.setActiveTabMenu(this._activeTabMenu)
    })
  }

  setActiveTabMenu(tabMenu: TabMenu): void {
    this._activeTabMenu = tabMenu
    if (Object.keys(this._releasesMap)?.length) {
      this._activeReleaseNote = this._releasesMap[this._activeTabMenu.id]
    }
  }
}
