<app-title title="STATUS SDKs"></app-title>

<div class="p-col">
    <h3 *ngIf="withoutPermission" class="p-text-center">{{withoutPermission}}</h3>
    <p-card *ngFor="let sdk of sdkList">
        <p-fieldset legend="{{sdk.name}}">
            <div class="p-grid">
                <div class="p-text-center p-ml-2">
                    <b>CPU</b>
                    <p-knob [(ngModel)]="sdk.cpu" [readonly]="true" valueTemplate="{{sdk.cpuStr}}"
                        [valueColor]="this.getKnobChartColor(sdk.cpu)"></p-knob>
                </div>
                <div class="p-text-center p-ml-2">
                    <b>RAM</b>
                    <p-knob [(ngModel)]="sdk.ram" [readonly]="true" valueTemplate="{{sdk.ramStr}}"
                        [valueColor]="this.getKnobChartColor(sdk.ram)"></p-knob>
                </div>
                <div class="p-text-center p-ml-2">
                    <b>HD</b>
                    <p-knob [(ngModel)]="sdk.hd" [readonly]="true" valueTemplate="{{sdk.hdStr}}"
                        [valueColor]="this.getKnobChartColor(sdk.hd)"></p-knob>
                </div>
                <div class="p-col p-ml-6">
                    <b>Status:</b>
                    <p [ngClass]="{'sdk-success-status': sdk.running,'sdk-error-status': !sdk.running}">
                        {{sdk.runningStatus}}</p>

                    <p *ngIf="sdk.errorMessage" style="color: var(--error-color-1) !important">{{sdk.errorMessage}}</p>
                </div>
                <div class="p-col">
                    <b>Informações:</b>
                    <p>Descrição: {{sdk.description}}</p>
                    <p>Criado em: {{sdk.creationDate | date:'dd/MM/yyyy'}}</p>
                    <p>Versão: {{sdk.version}}</p>
                    <p>Estado: {{sdk.enabled ? 'Ativo' : 'Inativo'}}</p>
                </div>
            </div>
        </p-fieldset>
    </p-card>
</div>