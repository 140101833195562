import { Injectable } from '@angular/core'
import { UseCase } from './usecase'
import { PermissionsHelper } from '../../helpers/permission.helper'

type Permission = string
@Injectable({ providedIn: 'root' })
export class HasLoggedUserPermission implements UseCase<Permission, boolean> {
  constructor(private permissionsHelper: PermissionsHelper) {}

  execute(permission: Permission): boolean {
    return this.permissionsHelper.getPermissions()?.indexOf(permission) >= 0
  }
}
