import { Injectable } from '@angular/core'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { Translation } from '../models/translation.model'
import { Observable } from 'rxjs'
import { TranslationService } from '../../services/translation.service'

@Injectable({ providedIn: 'root' })
export class SaveTranslation
  implements UseCase<Translation, Observable<string>>
{
  constructor(private translationService: TranslationService) {}

  execute(translation: Translation): Observable<string> {
    return this.translationService.saveTranslation(translation)
  }
}
