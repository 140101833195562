import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.css'],
})
export class DoughnutChartComponent implements OnChanges {
  @Input() datasets: {
    labels: string[]
    values: {
      backgroundColor: string[]
      hoverBackgroundColor: string[]
      data: number[]
    }[]
  }

  @Input() options: {
    plugin: {}
    plugins: {}
  }

  _data: any
  _plugin: any

  _options: any = {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.datasets?.currentValue) {
      this._data = {
        labels: changes.datasets.currentValue.labels,
        datasets: changes.datasets.currentValue.values,
      }
    }
    if (changes.options?.currentValue) {
      this._plugin = changes.options.currentValue.plugin
      this._options.plugins = changes.options.currentValue.plugins
    }
  }
}
