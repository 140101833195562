import { Injectable } from '@angular/core'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { CompanyListNew, CompanyNew } from '../models/company-new.model'
import { Observable } from 'rxjs'
import { CompanyService } from 'src/app/core/service/company.service'
import { FilterData } from 'src/app/core/domain/models/filter-data'

@Injectable({ providedIn: 'root' })
export class FilterCompanies
  implements UseCase<FilterData<CompanyNew>, Observable<CompanyListNew>>
{
  constructor(private companyService: CompanyService) {}

  execute(param: FilterData<CompanyNew>): Observable<CompanyListNew> {
    param.page = param.page || 0
    return this.companyService.filterCompany(param.data, param.page)
  }
}
