import { Injectable } from '@angular/core'
import { SummaryBySdkVersion } from '../models/transactions-summary.model'
import { UseCase } from 'src/app/core/domain/usecases/usecase'

@Injectable({
  providedIn: 'root',
})
export class EqualizeSdkVersionsSummaryByDay
  implements UseCase<SummaryBySdkVersion[], SummaryBySdkVersion[]>
{
  execute(summaryBySdk: SummaryBySdkVersion[]): SummaryBySdkVersion[] {
    let { allAvaliableVersions, groupedVersionsByDay } =
      this.groupSummaryByDateAndVersion(summaryBySdk)

    this.setDefaultSummaryBySdkVersionOnMissingDate(
      allAvaliableVersions,
      groupedVersionsByDay
    )

    return this.mapToSummaryBySdkVersionList(groupedVersionsByDay)
  }

  private groupSummaryByDateAndVersion(
    summaryBySdk: SummaryBySdkVersion[]
  ): GroupedSummaryData {
    let allAvaliableVersions = {}
    let groupedVersionsByDay = {}

    summaryBySdk.forEach((e) => {
      allAvaliableVersions[e.version] = ''
      if (!groupedVersionsByDay[e.date]) groupedVersionsByDay[e.date] = {}
      if (!groupedVersionsByDay[e.date][e.version])
        groupedVersionsByDay[e.date][e.version] = {}
      groupedVersionsByDay[e.date][e.version] = e
    })

    return { allAvaliableVersions, groupedVersionsByDay }
  }

  private setDefaultSummaryBySdkVersionOnMissingDate(
    allAvaliableVersions: {},
    groupedVersionsByDay: {}
  ) {
    Object.keys(groupedVersionsByDay).forEach((date) => {
      Object.keys(allAvaliableVersions).forEach((version) => {
        const hasSummaryOfSdkVersionOnDate = groupedVersionsByDay[date][version]
        if (!hasSummaryOfSdkVersionOnDate) {
          groupedVersionsByDay[date][version] = new SummaryBySdkVersion(
            date,
            version
          )
        }
      })
    })
  }

  private mapToSummaryBySdkVersionList(groupedVersionsByDay: {}): SummaryBySdkVersion[] {
    return Object.values(groupedVersionsByDay)
      .map((v) => Object.values(v))
      .reduce((acc, current) => acc.concat(current), [])
  }
}

interface GroupedSummaryData {
  allAvaliableVersions: {}
  groupedVersionsByDay: {}
}
