import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Profile, ProfileList } from '../domain/models/profile.model'

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private apiUrl = `${environment.apiUrl}`
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  }

  constructor(private httpClient: HttpClient) {}

  saveProfile(profile: Profile): Observable<string> {
    return this.httpClient.post<string>(
      this.apiUrl + 'profile/' + (profile.id ? 'update' : 'add'),
      profile,
      this.httpOptions
    )
  }

  listAllProfiles(): Observable<Profile[]> {
    return this.httpClient.get<Profile[]>(
      this.apiUrl + 'profile/list-all',
      this.httpOptions
    )
  }

  filterProfile(profileFilter, page: number): Observable<ProfileList> {
    return this.httpClient.post<ProfileList>(
      this.apiUrl + 'profile/list?page=' + page,
      profileFilter,
      this.httpOptions
    )
  }
}
