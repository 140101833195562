import { AfterContentChecked, Component, OnInit } from '@angular/core'
import { IsUserLoggedIn } from './core/domain/usecases/is-user-logged-in'
import { ChartHelper } from './core/helpers/chart.helper'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterContentChecked {
  constructor(
    private chartHelper: ChartHelper,
    private isUserLoggedIn: IsUserLoggedIn
  ) {}

  hasUserLoggedIn: boolean

  ngOnInit(): void {
    this.chartHelper.globalUnregisterChartPlugins()
  }

  ngAfterContentChecked() {
    this.hasUserLoggedIn = this.isUserLoggedIn.execute()
  }
}
