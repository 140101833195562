import { Injectable } from '@angular/core'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { CompanyNew } from '../models/company-new.model'
import { FileHelper } from 'src/app/core/helpers/file.helper'
import { DateHelper } from 'src/app/core/helpers/date.helper'
import { GenericHelper } from 'src/app/core/helpers/generic.helper'

@Injectable({ providedIn: 'root' })
export class CreateCompanyReport implements UseCase<CompanyNew, void> {
  constructor(
    private genericHelper: GenericHelper,
    private dateHelper: DateHelper,
    private fileHelper: FileHelper
  ) {}

  execute(company: CompanyNew): void {
    import('xlsx').then((xlsx) => {
      let c = this.genericHelper.deepCopy(company)
      let report = [c]
      c.cnpj = c.cnpj.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      )
      c.creationDate = this.dateHelper.formatDatetimeString(c.creationDate)

      const headers: any = {
        id: 'ID',
        name: 'NOME',
        cnpj: 'CNPJ',
        codrev: 'CODREV',
        creationDate: 'CRIAÇÃO',
        locale: 'IDIOMA',
      }
      let order = {
        header: ['id', 'name', 'cnpj', 'codrev', 'creationDate', 'locale'],
        skipHeader: true,
      }

      for (let i = 1; i <= company.contacts.length; i++) {
        let c = company.contacts[i - 1]
        let f = 'contactName' + i
        headers[f] = 'CONTATO NOME ' + i
        report[0][f] = c.name
        order.header.push(f)
        f = 'contactDepartament' + i
        headers[f] = 'DEPARTAMENTO'
        report[0][f] = c.department || ''
        order.header.push(f)
        f = 'contactEmail' + i
        headers[f] = 'EMAIL'
        report[0][f] = c.email || ''
        order.header.push(f)
        f = 'contactPhone' + i
        headers[f] = 'FONE'
        report[0][f] = c.phone || ''
        order.header.push(f)
      }

      for (let i = 1; i <= company.sdks.length; i++) {
        let sc = company.sdks[i - 1]
        let f = 'sdkName' + i
        headers[f] = 'SDK NOME ' + i
        report[0][f] = sc.name
        order.header.push(f)
        f = 'sdkToken' + i
        headers[f] = 'TOKEN'
        report[0][f] = sc.token
        order.header.push(f)
        f = 'sdkExpiration' + i
        headers[f] = 'EXPIRAÇÃO'
        report[0][f] = sc.tokenExpirationDate
        order.header.push(f)

        let list = []
        sc.endpoints.forEach((ec) => {
          list.push(ec.description)
        })
        if (list.length > 0) {
          let f = 'endpointName' + i
          headers[f] = 'SERVIÇO' + (list.length == 1 ? '' : 'S')
          report[0][f] = list.join(', ')
          order.header.push(f)
        }
      }

      report.unshift(headers)
      let worksheet = xlsx.utils.json_to_sheet(report, order)
      let csv = xlsx.utils.sheet_to_csv(worksheet, { FS: ';' })
      this.fileHelper.saveAsCsvFile(csv, 'company')
    })
  }
}
