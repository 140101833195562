const distinctedColors = [
  '#4363d8',
  '#ffe119',
  '#e6194B',
  '#bfef45',
  '#f58231',
  '#42d4f4',
  '#f032e6',
  '#3cb44b',
  '#911eb4',
  '#a9a9a9',
  '#000000',
  '#9A6324',
  '#469990',
  '#808000',
]

export class ColorGeneratorHelper {
  private index = 0

  getNextColor(): string {
    const color = distinctedColors[this.index++]
    if (this.index >= distinctedColors.length) this.index = 0
    return color
  }
}
