import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { SdkRelease } from '../../domain/models/sdk-release'
import { CreateSdkRelease } from '../../domain/usecases/create-sdk-release'

@Component({
  selector: 'app-create-release',
  templateUrl: './create-release.component.html',
  styleUrls: ['./create-release.component.css'],
})
export class CreateReleaseComponent implements OnInit {
  constructor(
    private createSdkRelease: CreateSdkRelease,
    private router: Router,
    private snackBar: SnackBarService
  ) {}

  ngOnInit(): void {}

  redirectToLatestReleases(): void {
    this.router.navigate(['/sdks/latest-releases'])
  }

  saveRelease(release: SdkRelease): void {
    if (release) {
      this.createSdkRelease.execute(release).subscribe((id) => {
        this.snackBar.success('Release Salva!', 5, 'top', 'right')
        this.redirectToLatestReleases()
      })
    }
  }
}
