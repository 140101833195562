import { Component, OnInit } from '@angular/core'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { HasLoggedUserPermission } from 'src/app/core/domain/usecases/has-logged-user-permission'
import { Constants } from 'src/app/core/helpers/constants'
import { GenericHelper } from 'src/app/core/helpers/generic.helper'
import { Profile, ProfileList } from '../../domain/models/profile.model'
import { Resource } from '../../domain/models/resource.model'
import { FilterUserProfiles } from '../../domain/usecases/filter-user-profiles'
import { GetAllUserPermissions } from '../../domain/usecases/get-all-user-permissions'
import { SaveUserProfile } from '../../domain/usecases/save-user-profile'

@Component({
  selector: 'app-list-user-profiles',
  templateUrl: './list-user-profiles.component.html',
  styleUrls: ['./list-user-profiles.component.css'],
})
export class ListUserProfilesComponent implements OnInit {
  profileDialog: boolean
  submitted: boolean
  profileFilter: Profile = new Profile()

  profiles: Profile[] = []
  resources: Resource[] = []

  count: number = 0

  profileList: ProfileList

  profile = new Profile()
  withoutPermission: string

  constructor(
    private snackBar: SnackBarService,
    private genericHelper: GenericHelper,
    private hasLoggedUserPermission: HasLoggedUserPermission,
    private saveUserProfile: SaveUserProfile,
    private filterUserProfiles: FilterUserProfiles,
    private getAllUserPermissions: GetAllUserPermissions
  ) {}

  ngOnInit() {
    this.listAllResources()

    if (!this.checkPermission('profile.list'))
      this.withoutPermission = 'Sem permissão para listar perfis!'
  }

  listAllResources() {
    if (this.checkPermission('resource.list')) {
      this.getAllUserPermissions.execute().subscribe((data) => {
        this.resources = data
        this.listAllProfiles()
      })
    } else {
      console.warn('Sem permissão para listar resources!')
    }
  }

  listAllProfiles() {
    this.profileFilter = new Profile()
    this.searchProfiles()
  }

  searchProfiles(page?: number) {
    if (this.checkPermission('profile.list')) {
      this.filterUserProfiles
        .execute({ data: this.profileFilter, page })
        .subscribe((data) => {
          this.profileList = data
          this.profiles = this.profileList.list
          this.count = this.profileList.count
          let resourcesLength = this.resources.length
          this.profiles.forEach(function (profile) {
            profile.countPermissions =
              profile.resourceList.length + ' de ' + resourcesLength
          })
        })
    }
  }

  openForm(profile: Profile) {
    if (profile) this.profile = this.genericHelper.deepCopy(profile)
    else {
      this.profile = new Profile()
      this.profile.resourceList = []
    }
    let res
    for (let i = 0; i < this.resources.length; i++) {
      res = this.resources[i]
      res.checked =
        this.profile.resourceList.findIndex((r) => r.name == res.name) >= 0
    }
    this.profileDialog = true
  }

  hasResource(resource: Resource) {
    return (
      this.profile.resourceList.findIndex((r) => r.name == resource.name) >= 0
    )
  }

  hideDialog() {
    this.profileDialog = false
    this.submitted = false
  }

  changePage(event) {
    this.searchProfiles(event.page)
  }

  saveProfile() {
    this.submitted = true
    let res
    this.profile.resourceList = []
    for (let i = 0; i < this.resources.length; i++) {
      res = this.resources[i]
      if (res.checked) this.profile.resourceList.push(res)
    }
    if (this.profile.resourceList.length == 0) {
      this.snackBar.warn(
        'Dados de formulário inválidos, pelo menos uma permissão é necessária!',
        5,
        'top',
        'right'
      )
    } else if (this.profile.name == Constants.optionTodos) {
      this.snackBar.error(
        'Dados de formulário inválidos, nome não permitido!',
        5,
        'top',
        'right'
      )
    } else {
      this.saveUserProfile.execute(this.profile).subscribe(
        () => {},
        (error) => {
          if (error.status == 200) {
            this.snackBar.success('Perfil Salvo!', 5, 'top', 'right')
            this.hideDialog()
            this.listAllProfiles()
          } else if (error.status == 400) {
            this.snackBar.error(
              'Dados de formulário inválidos!',
              5,
              'top',
              'right'
            )
          } else if (error.status == 405) {
            this.snackBar.error(
              'Sem permissões para esta ação!',
              5,
              'top',
              'right'
            )
          } else {
            this.snackBar.error('Erro desconhecido!', 5, 'top', 'right')
          }
        }
      )
    }
  }
  checkPermission(permission: string): boolean {
    return this.hasLoggedUserPermission.execute(permission)
  }
}
