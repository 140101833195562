import { Injectable } from '@angular/core'
import { StorageHelper } from '../../helpers/storage.helper'
import { UseCase } from './usecase'

@Injectable({ providedIn: 'root' })
export class IsUserLoggedIn implements UseCase<void, boolean> {
  constructor(private storageHelper: StorageHelper) {}

  execute(_: void): boolean {
    return Boolean(this.storageHelper.getBy('auth_name'))
  }
}
