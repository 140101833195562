import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Sdk } from 'src/app/core/domain/models/sdk.model'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { SdkService } from 'src/app/core/service/sdk.service'

@Injectable({ providedIn: 'root' })
export class GetAllSdks implements UseCase<void, Observable<Sdk[]>> {
  constructor(private sdkService: SdkService) {}

  execute(_: void): Observable<Sdk[]> {
    return this.sdkService.listAllSdks()
  }
}
