import { Injectable } from '@angular/core'
import { UseCase } from './usecase'
import { StorageHelper } from '../../helpers/storage.helper'

@Injectable({ providedIn: 'root' })
export class SaveRoleFromLoggedUser implements UseCase<string, void> {
  constructor(private storageHelper: StorageHelper) {}

  execute(role: string): void {
    this.storageHelper.saveBy('r', JSON.stringify(role || 'null'))
  }
}
