import { Component, OnInit } from '@angular/core';
// @ts-ignore
import packageInfo from 'package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {
  public version: string = packageInfo.version;
  public currentYear: number = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {
  }

}
