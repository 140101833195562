<p-table [value]="contactList">
    <ng-template pTemplate="header">
        <tr>
            <th>
                <app-text text="Contatos" size="14" classes="p-text-bold"></app-text>
            </th>
            <th></th>
            <th colspan="4" class="p-text-right" *appShowByUserPermission="'company.update'">
                <app-button (onClick)="showContactDialog()" icon="add" type="rounded" theme="primary"
                    label="Contato"></app-button>
            </th>
        </tr>
        <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Telefone</th>
            <th>Departamento</th>
            <th>Editar</th>
            <th>Excluir</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="colgroup">
        <colgroup>
            <col style="width:85px">
            <col style="width:85px">
            <col style="width:85px">
            <col style="width:85px">
            <col style="width:40px">
            <col style="width:40px">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="body" let-contact>
        <tr>
            <td>{{contact.name}}</td>
            <td>{{contact.email}}</td>
            <td>{{contact.phone | phone}}</td>
            <td>{{contact.department}}</td>
            <td>
                <app-button *appShowByUserPermission="'company.update'" label="" theme="secondary"
                    (onClick)="editContact(contact)" icon="edit" type="rounded"></app-button>
            </td>
            <td>
                <app-button *appShowByUserPermission="'company.update'" label="" theme="secondary"
                    (onClick)="deleteContact(contact)" icon="delete" type="rounded"></app-button>
            </td>
        </tr>
    </ng-template>
</p-table>

<!-- new contact dialog -->
<app-company-contact-form [contact]="_contact" [visible]="_showContactDialog"
    (onSave)="saveContact($event)"></app-company-contact-form>