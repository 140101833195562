import { Injectable } from '@angular/core'
import { UseCase } from './usecase'
import { LoginResponse } from '../models/login-response'
import { CompanyHelper } from '../../helpers/company.helper'
import { PermissionsHelper } from '../../helpers/permission.helper'
import { StorageHelper } from '../../helpers/storage.helper'
import { SaveRoleFromLoggedUser } from './save-role-from-logged-user'

type UserData = {
  password: string
  login: LoginResponse
}

@Injectable({ providedIn: 'root' })
export class SaveLoggedUserData implements UseCase<UserData, void> {
  constructor(
    private storageHelper: StorageHelper,
    private companyHelper: CompanyHelper,
    private permissionsHelper: PermissionsHelper,
    private saveRoleFromLoggedUser: SaveRoleFromLoggedUser
  ) {}

  execute(userData: UserData): void {
    this.setToken(userData.login.token)
    this.storageHelper.saveBy('auth_name', userData.login.name) // user name to show at header page
    let company = userData.login.company
    this.companyHelper.saveCompanyFromLoggedUser(company)
    this.saveRoleFromLoggedUser.execute(userData.login.role)
    this.permissionsHelper.savePermissions(userData.login.permissions)
    if (userData.login.randomPassword) {
      // login endpoint indicates if password is random or not
      this.storageHelper.saveBy('rp', userData.password) // just for check if is random password
    }
    this.storageHelper.saveBy(
      'pageSize',
      userData.login?.pageSize?.toString() || '10'
    )
  }

  private setToken(token: string): void {
    this.storageHelper.saveBy('token', token)
  }
}
