import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ComponentsModule } from './components/components.module'
import { ShowByUserPermissionDirective } from './directives/show-by-user-permission.directive'
import { PhonePipe } from './pipes/phone.pipe'
import { CnpjPipe } from './pipes/cnpj.pipe'

@NgModule({
  exports: [
    ComponentsModule,
    CommonModule,
    ShowByUserPermissionDirective,
    PhonePipe,
    CnpjPipe,
  ],
  declarations: [ShowByUserPermissionDirective, PhonePipe, CnpjPipe],
})
export class CoreModule {}
