import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import { Constants } from '../../helpers/constants'

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css'],
})
export class LabelComponent implements OnInit, OnChanges {
  @Input() label!: string
  @Input() for?: string
  @Input() size?: number = 16
  @Input() color?: string = Constants.appPrimaryColor
  @Input() classes?: string = ''

  ngOnInit(): void {}

  getStyle(): string {
    return `font-size: ${this.size}pt; color: ${this.color}`
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.label?.currentValue) {
      this.label = changes.label.currentValue
    }
    if (changes.for?.currentValue) {
      this.for = changes.for.currentValue
    }
    if (changes.size?.currentValue) {
      this.size = changes.size.currentValue
    }
    if (changes.color?.currentValue) {
      this.color = changes.color.currentValue
    }
    if (changes.classes?.currentValue) {
      this.classes = changes.classes.currentValue
    }
  }
}
