import { Injectable } from '@angular/core'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { TransactionService } from '../../services/transaction.service'
import { Observable } from 'rxjs'

type UnknownModel = any
@Injectable({ providedIn: 'root' })
export class GetTransactionContent
  implements UseCase<string, Observable<UnknownModel>>
{
  constructor(private transactionService: TransactionService) {}

  execute(tid: string): Observable<UnknownModel> {
    return this.transactionService.getTransactionContent(tid)
  }
}
