import { Component, OnInit } from '@angular/core'
import { SnackBarService } from '../snack-bar.service'
import { ResponsiveService } from './responsive.service'

@Component({
  selector: 'app-block-screen',
  templateUrl: './block-screen.component.html',
  styleUrls: ['./block-screen.component.css'],
})
export class BlockScreenComponent implements OnInit {
  isBlockScreen: boolean = false
  constructor(
    private responsive: ResponsiveService,
    private snackbar: SnackBarService
  ) {}

  ngOnInit(): void {
    this.responsive.screenSizeChanges.subscribe((result) => {
      if (result.matches) {
        this.isBlockScreen = true
        this.snackbar.info(
          'O tamanho da tela não é suportado! Mude para uma tela maior.',
          3600
        )
      } else {
        this.isBlockScreen = false
        this.snackbar.closeSnackBar()
      }
    })
  }
}
