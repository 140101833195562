import { Injectable } from '@angular/core'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { User } from '../models/user.model'
import { UserService } from '../../services/user.service'
import { Observable } from 'rxjs'
import { GenerateRandomPassword } from './generate-random-password'
import { EncryptPassword } from 'src/app/core/domain/usecases/encrypt-password'
import { map } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class SaveUpdateUser implements UseCase<User, Observable<User>> {
  constructor(
    private userService: UserService,
    private generateRandomPassword: GenerateRandomPassword,
    private encryptPassword: EncryptPassword
  ) {}

  execute(user: User): Observable<User> {
    let randomPassword: string
    if (!user.id) {
      randomPassword = this.generateRandomPassword.execute()
      user.password = this.encryptPassword.execute(randomPassword)
    }

    return this.userService.saveUser(user).pipe(
      map((data) => {
        data.password = randomPassword
        return data
      })
    )
  }
}
