import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Constants } from './constants'

@Injectable({
  providedIn: 'root',
})
export class GenericHelper {
  private static storageShuffleNumbers = `${environment.storageShuffleNumbers}`

  // decodes some value using storageShuffleNumbers defined at environment file
  unshuffle(valueShuffled: string): string {
    return GenericHelper.unshuffle(valueShuffled)
  }

  shuffle(value: string): string {
    let temp
    let valueShuffled = ''
    for (let i = 0; i < value.length; i++) {
      temp =
        value.charCodeAt(i) +
        parseInt(GenericHelper.storageShuffleNumbers[i % 10])
      valueShuffled += String.fromCharCode(temp)
    }
    return valueShuffled
  }

  deepCopy(obj) {
    let copy

    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' != typeof obj) return obj

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date()
      copy.setTime(obj.getTime())
      return copy
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = []
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i])
      }
      return copy
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {}
      for (let attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr])
      }
      return copy
    }

    throw new Error("Unable to copy obj! Its type isn't supported.")
  }

  getCalendarTranslations(): any {
    return {
      dayNames: Constants.dayNames,
      dayNamesShort: Constants.dayNamesShort,
      dayNamesMin: Constants.dayNamesMin,
      monthNames: Constants.monthNames,
      monthNamesShort: Constants.monthNamesShort,
      today: Constants.today,
      clear: Constants.clear,
    }
  }

  formatCpf(cpf: string): string {
    return cpf && cpf.length == 11
      ? cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
      : ''
  }

  copyToClipboard(data: string): boolean {
    const listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', data)
      e.preventDefault()
      document.removeEventListener('copy', listener)
    }
    document.addEventListener('copy', listener)
    return document.execCommand('copy')
  }

  uuidv4(): string {
    return (<any>crypto).randomUUID()
  }

  public static unshuffle(valueShuffled: string) {
    let value = null
    if (valueShuffled) {
      let temp
      value = ''
      for (let i = 0; i < valueShuffled.length; i++) {
        temp =
          valueShuffled.charCodeAt(i) -
          parseInt(GenericHelper.storageShuffleNumbers[i % 10])
        value += String.fromCharCode(temp)
      }
    }
    return value
  }
}
