import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'cnpj',
})
export class CnpjPipe implements PipeTransform {
  transform(cnpj: string, ...args: unknown[]): string {
    return cnpj && cnpj.length == 14
      ? cnpj.substring(0, 2) +
          '.' +
          cnpj.substring(2, 5) +
          '.' +
          cnpj.substring(5, 8) +
          '/' +
          cnpj.substring(8, 12) +
          '-' +
          cnpj.substring(12)
      : ''
  }
}
