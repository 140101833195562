import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.css'],
})
export class RadioButtonComponent implements OnInit, OnChanges {
  @Input() label: string
  @Input() initialValue: string
  @Input() value: string
  @Input() labelStyle: string
  @Input() buttonStyle: string
  @Output() onSelect = new EventEmitter<string>()

  _value: string

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.label?.currentValue) {
      this.label = changes.label.currentValue
    }
    if (changes.value?.currentValue) {
      this.value = this._value = changes.value.currentValue
    }
  }

  ngOnInit(): void {}

  click(): void {
    this.onSelect.emit(this._value)
  }
}
