import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { Transaction } from '../../domain/models/transaction.model'
import { GetTransactionContent } from '../../domain/usecases/get-transaction-content'

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.css'],
})
export class TransactionDetailComponent implements OnInit {
  transaction?: Transaction
  transactionContent: string = ''
  panelOpenState = false

  constructor(
    private snackbar: SnackBarService,
    private router: Router,
    private getTransactionContent: GetTransactionContent
  ) {}

  ngOnInit(): void {
    this.transaction = history.state.data
    if (!this.transaction) {
      this.snackbar.info(
        'Sem transação para detalhar, redirecionando para a listagem de transações',
        5
      )
      this.router.navigate(['transactions'])
    } else this.showTransactionContent()
  }

  showTransactionContent() {
    if (!this.transaction?.tid) {
      return
    }

    this.getTransactionContent.execute(this.transaction?.tid).subscribe(
      (data) => {
        if (data) {
          this.transactionContent = this.formatTransactionContent(data)
        } else {
          this.transactionContent = this.formatTransactionContent(
            'Sem conteúdo a ser exibido!'
          )
        }
      },
      (_) => {
        this.transactionContent = this.formatTransactionContent(
          'Sem conteúdo a ser exibido!'
        )
      }
    )
  }

  private formatTransactionContent(data: any): string {
    let content = '\n'

    if (typeof data == 'object' && data) {
      Object.keys(data).forEach(
        (attrName) => (content += `  ${attrName} : ${data[attrName]}\n`)
      )
    } else if (typeof data == 'string') {
      content += data
    }

    return content
  }
}
