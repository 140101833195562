import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ListTranslationsComponent } from './pages/list-translations/list-translations.component'
import { AuthGuard } from 'src/app/core/security/auth.guard'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: ListTranslationsComponent,
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TranslationsRoutingModule {}
