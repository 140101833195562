import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { SdkRelease } from '../models/sdk-release'
import { SdkReleaseService } from '../../services/sdk-release.service'

@Injectable({ providedIn: 'root' })
export class GetLatestReleaseBySdk
  implements UseCase<void, Observable<SdkRelease[]>>
{
  constructor(private releaseNoteService: SdkReleaseService) {}

  execute(_: void): Observable<SdkRelease[]> {
    return this.releaseNoteService.getLatestReleases()
  }
}
