import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { PrimeNGConfig } from 'primeng/api'
import { GenericHelper } from '../../helpers/generic.helper'

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.css'],
})
export class DateInputComponent implements OnInit, OnChanges {
  @Input() label!: Date
  @Input() initialDate?: Date
  @Output() selected = new EventEmitter<Date>()
  @Input() maxDateValue?: Date

  dateValue: Date

  constructor(
    private config: PrimeNGConfig,
    private genericHelper: GenericHelper
  ) {
    this.config.setTranslation(this.genericHelper.getCalendarTranslations())
  }

  ngOnInit(): void {
    if (this.initialDate) {
      this.dateValue = this.initialDate
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialDate?.currentValue) {
      this.dateValue = changes.initialDate.currentValue
    }
    if (changes.maxDateValue?.currentValue) {
      this.maxDateValue = changes.maxDateValue.currentValue
    }
  }
  
  selectedDate(date: Date) {
    this.selected.emit(date)
  }
}
