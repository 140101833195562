<app-title title="Detalhes da Transação"></app-title>

<div class="container p-grid p-dir-col">
    <div class="p-col">
        <app-transaction [transaction]="transaction"></app-transaction>
    </div>
    <div class="p-col">
        <p-accordion (onOpen)="showTransactionContent()">
            <p-accordionTab header="Conteúdo">
                <textarea readonly="true" name="" id="" cols="30" rows="10">
                    {{transactionContent}}
                </textarea>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>