import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { User } from '../models/user.model'
import { UserService } from '../../services/user.service'

@Injectable({ providedIn: 'root' })
export class GetAllUsers implements UseCase<void, Observable<User[]>> {
  constructor(private userService: UserService) {}

  execute(_: void): Observable<User[]> {
    return this.userService.listAllUsers()
  }
}
