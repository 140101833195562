import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { TabMenu } from 'src/app/core/components/tab-menu/tab-menu.component'

@Component({
  selector: 'app-transactions-summary-view-mode-options',
  templateUrl: './transactions-summary-view-mode-options.component.html',
  styleUrls: ['./transactions-summary-view-mode-options.component.css'],
})
export class TransactionsSummaryViewModeOptionsComponent implements OnInit {
  @Output() activeViewMode = new EventEmitter<number>()

  constructor() {}

  _menus: TabMenu[] = [
    { id: '1', name: 'Geral', icon: 'barChart' },
    { id: '2', name: 'Taxa de rejeição por SDK', icon: 'lineChart' },
  ]
  _initialMenu: TabMenu = this._menus[0]

  ngOnInit(): void {
    this.activeViewMode.emit(Number(this._initialMenu.id))
  }

  setActiveTabMenu(activeMenu: TabMenu) {
    this.activeViewMode.emit(Number(activeMenu.id))
  }
}
