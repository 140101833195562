import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { UserService } from '../../services/user.service'

type UserId = number

@Injectable({ providedIn: 'root' })
export class ResetUserPassword implements UseCase<UserId, Observable<string>> {
  constructor(private userService: UserService) {}

  execute(id: UserId): Observable<string> {
    return this.userService.resetPassword(id)
  }
}
