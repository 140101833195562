import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { TranslationsRoutingModule } from './translations-routing.module'
import { ListTranslationsComponent } from './pages/list-translations/list-translations.component'
import { CoreModule } from 'src/app/core/core.module'

@NgModule({
  declarations: [ListTranslationsComponent],
  imports: [CoreModule, CommonModule, TranslationsRoutingModule],
})
export class TranslationsModule {}
