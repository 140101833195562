import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { CompaniesRoutingModule } from './companies-routing.module'
import { CompanyContactsComponent } from './components/company-contacts/company-contacts.component'
import { CompanySdkFormComponent } from './components/company-sdk-form/company-sdk-form.component'
import { CompanySdksComponent } from './components/company-sdks/company-sdks.component'
import { CreateCompanyComponent } from './pages/create-company/create-company.component'
import { ListCompaniesComponent } from './pages/list-companies/list-companies.component'
import { CompanyContactFormComponent } from './components/company-contact-form/company-contact-form.component'
import { CompanyFormComponent } from './components/company-form/company-form.component'
import { EditCompanyComponent } from './pages/edit-company/edit-company.component'
import { CoreModule } from 'src/app/core/core.module'

@NgModule({
  declarations: [
    ListCompaniesComponent,
    CreateCompanyComponent,
    CompanyContactsComponent,
    CompanySdksComponent,
    CompanySdkFormComponent,
    CompanyContactFormComponent,
    CompanyFormComponent,
    EditCompanyComponent,
  ],
  imports: [CoreModule, CommonModule, CompaniesRoutingModule],
})
export class CompaniesModule {}
