import { CompanyNew } from '../../../companies/domain/models/company-new.model'
import { Endpoint } from '../../../../core/domain/models/endpoint.model'
import { Sdk } from '../../../../core/domain/models/sdk.model'

export interface TransactionList {
  count: number
  list: Transaction[]
}

export class Transaction {
  _id: string
  tid: string
  externalTid: string
  installationId: string
  sdk: string
  path: string // endpoint
  date: string
  success: any
  error: any
  errorMessage: any
  clientIp: any
  cnpj: string
  status: string

  company: CompanyNew // for filter and list exhibition
  sdkFilter: Sdk // for filter
  endpointFilter: Endpoint // for filter
  dateFrom: Date // for filter
  dateTo: Date // for filter
  credentialUser: string // from CORE and parse to cnpj
}
