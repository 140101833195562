import { Injectable } from '@angular/core'
import { UseCase } from './usecase'
import { CompanyHelper } from '../../helpers/company.helper'
import { StorageHelper } from '../../helpers/storage.helper'

@Injectable({ providedIn: 'root' })
export class IsLoggedUserAdministrator implements UseCase<void, boolean> {
  constructor(
    private storageHelper: StorageHelper,
    private companyHelper: CompanyHelper
  ) {}

  execute(_: void): boolean {
    const c = this.companyHelper.getCompanyFromLoggedUser()
    const r = this.getRoleFromLoggedUser()
    // Usuário Certisign ou sem compania
    // TODO: adicionar cnpj na variável de ambiente
    return (!c.cnpj || c.cnpj === '01554285000175') && r === 'ADMIN'
  }

  getRoleFromLoggedUser(): string {
    const r = JSON.parse(this.storageHelper.getBy('r'))
    return r === 'null' ? '' : (r as string)
  }
}
