import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { Contact } from '../../domain/models/contact.model'

@Component({
  selector: 'app-company-contacts',
  templateUrl: './company-contacts.component.html',
  styleUrls: ['./company-contacts.component.css'],
})
export class CompanyContactsComponent implements OnInit, OnChanges {
  @Input() contactList: Contact[] = []
  @Output() contacts = new EventEmitter<Contact[]>()

  _showContactDialog = false
  _contact: Contact
  _contactFormMode: 'create' | 'edit' = 'create'

  constructor(private snackBar: SnackBarService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contactList?.currentValue) {
      this.contactList = changes.contactList.currentValue
    }
  }

  showContactDialog(): void {
    this._showContactDialog = true
  }

  saveContact(contact?: Contact): void {
    if (contact && this._contactFormMode === 'create') {
      this.saveOnCreation(contact)
    } else if (contact && this._contactFormMode === 'edit') {
      this.saveOnEditting(contact)
    }

    this.contacts.emit(this.contactList)
    this._showContactDialog = false
    this._contactFormMode = 'create'
  }

  private saveOnEditting(contact: Contact) {
    this.contactList = this.contactList.filter(
      (c) => c.email !== contact.email && c.phone !== contact.phone
    )
    this.contactList.push(contact)
  }

  private saveOnCreation(contact: Contact) {
    if (
      contact &&
      !this.contactList.find(
        (c) => c.email === contact.email && c.phone === contact.phone
      )
    ) {
      this.contactList.push(contact)
    }
  }

  editContact(contact: Contact): void {
    this._contact = Contact.copy(contact)
    this._showContactDialog = true
    this._contactFormMode = 'edit'
  }

  deleteContact(contact: Contact): void {
    if (this._contactFormMode === 'edit') {
      this.snackBar.warn(
        'O contato será efetivamente excluído somente após salvar a companhia!',
        5,
        'top',
        'right'
      )
    }
    this.contactList = this.contactList.filter((c) => c.id !== contact.id)
    this.contacts.emit(this.contactList)
  }
}
