import { Injectable } from '@angular/core'
import { UseCase } from 'src/app/core/domain/usecases/usecase'

@Injectable({ providedIn: 'root' })
export class RenameSdkNewToOld implements UseCase<string, string> {
  execute(param: string): string {
    switch (param) {
      case 'Azul':
        return 'Facetec SDK'
      case 'Vermelho':
        return 'MostQI API'
      case 'Verde':
        return 'Datavalid API'
      case 'Amarelo':
        return 'Tech5 SDK'
      default:
        return param
    }
  }
}
