import { Injectable } from '@angular/core'
import * as Chart from 'chart.js'
import * as ChartDataLabels from 'chartjs-plugin-datalabels'

@Injectable({
  providedIn: 'root',
})
export class ChartHelper {
  private chartJs = Chart
  private chartLabelPlugin: any = ChartDataLabels

  // random colors for transaction dashboard chart (each color for one endpoint)
  chartColors = [
    '#D98880',
    '#A93226',
    '#7B241C',
    '#6E2C00',
    '#C39BD3',
    '#BB8FCE',
    '#884EA0',
    '#512E5F',
    '#5DADE2',
    '#5499C7',
    '#1F618D',
    '#154360',
    '#A9DFBF',
    '#1ABC9C',
    '#148F77',
    '#145A32',
    '#8D6E63',
    '#795548',
    '#5D4037',
    '#4E342E',
    '#F7DC6F',
    '#D38A00',
    '#D35400',
    '#D34900',
  ].sort(() => 0.5 - Math.random())

  getChartColor(i: number) {
    if (i < this.chartColors.length) return this.chartColors[i]
    else return '#000000'
  }

  // get variable color according percentage of use for some machine resource (hd, cpu, ram)
  getKnobChartColor(value: number): string {
    if (!value) return null
    let color: string
    let x: number
    if (value < 50) {
      // Varies between green (0%) and yellow (50%)
      x = (value * 0xff) / 50
      color = '#' + Math.round(x).toString(16).toUpperCase() + 'FF00'
    } else {
      // Varies between yellow (50%) and red (100%)
      value -= 50
      value = 50 - value
      x = (value * 0xff) / 50
      let s: string = Math.round(x).toString(16).toUpperCase()
      if (s.length == 1) s += '0'
      color = '#FF' + s + '00'
    }
    return color
  }

  globalUnregisterChartPlugins() {
    this.chartJs.plugins.unregister(this.chartLabelPlugin)
  }
}
