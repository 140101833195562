import { Injectable } from '@angular/core'
import { UseCase } from 'src/app/core/domain/usecases/usecase'

@Injectable({ providedIn: 'root' })
export class RenameSdkOldToNew implements UseCase<string, string> {
  execute(param: string): string {
    switch (param) {
      case 'Facetec SDK':
        return 'Azul'
      case 'MostQI API':
        return 'Vermelho'
      case 'Datavalid API':
        return 'Verde'
      case 'Tech5 SDK':
        return 'Amarelo'
      default:
        return param
    }
  }
}
