import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'

interface DropdownData {
  name: string
  id: string
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css'],
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() options: DropdownData[]
  @Input() initialOption?: DropdownData
  @Input() disabled?: boolean = false
  @Input() classes?: string
  @Input() customStyle?: string
  @Output() selected = new EventEmitter<DropdownData>()

  _option: DropdownData

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options?.currentValue) {
      this.options = changes.options.currentValue
    }
    if (changes.initialOption?.currentValue) {
      this._option = changes.initialOption.currentValue
    }
  }

  selectedItem(data: any): void {
    this.selected.emit(data.value)
  }
}
