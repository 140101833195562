import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { Sdk } from 'src/app/core/domain/models/sdk.model'
import { CopyDataToClipboard } from 'src/app/core/domain/usecases/copy-data-to-clipboard'
import { GetAllSdksAndCheckUserPermission } from 'src/app/core/domain/usecases/get-all-sdks-and-check-user-permission'
import { Constants } from 'src/app/core/helpers/constants'
import { DateHelper } from 'src/app/core/helpers/date.helper'
import { GenericHelper } from 'src/app/core/helpers/generic.helper'
import {
  EndpointNew,
  SdkNew,
} from 'src/app/modules/companies/domain/models/company-new.model'

@Component({
  selector: 'app-company-sdk-form',
  templateUrl: './company-sdk-form.component.html',
  styleUrls: ['./company-sdk-form.component.css'],
})
export class CompanySdkFormComponent implements OnInit {
  @Input() sdkCompany?: SdkNew
  @Input() visible: boolean = false
  @Output() onSave = new EventEmitter<SdkNew | undefined>()

  _sdkList: Sdk[] = []

  _sdkCompanyNew: SdkNew = new SdkNew()
  _selectedSdk: Sdk = new Sdk()
  _sdkEndpoints: EndpointNew[]
  _sdkSubmitted = false
  _showSdkDialog = false
  _tokenExpirationDateInvalid = false
  _sdkEndpointsNotSelected = false
  _isEdittingMode = false

  constructor(
    private snackBar: SnackBarService,
    private dateHelper: DateHelper,
    private genericHelper: GenericHelper,
    private copyDataToClipboard: CopyDataToClipboard,
    private getAllSdksAndCheckUserPermission: GetAllSdksAndCheckUserPermission
  ) {}

  ngOnInit(): void {
    this.listAllSdks()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sdkCompany?.currentValue) {
      // this.setFormData(changes)
      this.setFormDataNew(changes)
      this._showSdkDialog = true
      this._isEdittingMode = true
    }
    if (changes.visible?.currentValue) {
      this._showSdkDialog = changes.visible.currentValue
    }
  }

  private setFormDataNew(changes: SimpleChanges): void {
    this._sdkCompanyNew = changes.sdkCompany.currentValue
    this._selectedSdk = this._sdkList.find(
      (s) => s.id === this._sdkCompanyNew.id
    )
    this._selectedSdk = Sdk.copy(this._selectedSdk)

    this._selectedSdk.endpointList = this._sdkCompanyNew.endpoints.map((e) =>
      EndpointNew.mapToOld(e)
    )

    this._sdkCompanyNew.tokenExpirationDate =
      this.unformatIsoTokenExpirationDate(
        this._sdkCompanyNew.tokenExpirationDate
      )

    const selectedSdk = this._sdkList.find(
      (s) => this._sdkCompanyNew.id === s.id
    )
    const selectedEndpointsIdList = this._sdkCompanyNew.endpoints?.map(
      (e) => e.id
    )
    selectedSdk?.endpointList
      ?.filter((e) => selectedEndpointsIdList?.includes(e.id))
      ?.forEach((e) => (e.checked = true))
    this._sdkEndpoints = selectedSdk?.endpointList
  }

  listAllSdks(): void {
    this.getAllSdksAndCheckUserPermission.execute().subscribe((data) => {
      if (data.hasPermission) {
        this._sdkList = [new Sdk(Constants.optionSelecione)]
        this._sdkList = this._sdkList.concat(data.sdks)
      } else {
        this.snackBar.warn('Sem permissão para listar SDKs!', 5, 'top', 'right')
      }
    })
  }

  showSdkDialog(): void {
    this._showSdkDialog = true
  }

  hideSdkDialog(): void {
    this.visible = this._showSdkDialog = false

    this.resetSdkDialogForm()
  }

  private resetSdkDialogForm(): void {
    this._sdkEndpoints?.forEach((s) => (s.checked = false))
    this._sdkEndpoints = []
    this._selectedSdk = new Sdk()
    this._sdkCompanyNew = new SdkNew()
    this._sdkSubmitted = false
    this._isEdittingMode = false
  }

  selectSdk(sdk: Sdk): void {
    if (!sdk?.endpointList) {
      this.resetSdkDialogForm()
      return
    }

    this._selectedSdk = Sdk.copy(sdk)
    this._sdkEndpoints = sdk.endpointList.filter((e) => e.enabled)
    this._sdkEndpoints.forEach((s) => (s.checked = false))
  }

  copyToken(): void {
    if (!this._sdkCompanyNew.token) {
      this.snackBar.warn('Token não foi gerado!', 5, 'top', 'right')
    } else if (this.copyDataToClipboard.execute(this._sdkCompanyNew.token)) {
      this.snackBar.info('Token foi copiado com sucesso!', 5, 'top', 'right')
    } else {
      this.snackBar.error('Não foi possível copiar o token!', 5, 'top', 'right')
    }
  }

  generateToken(): void {
    this._sdkCompanyNew.token = this.genericHelper.uuidv4()
  }

  saveSdk(): void {
    this._sdkSubmitted = true
    if (!this.isSdkDialogFormValid()) {
      this.snackBar.warn('Fornulário inválido', 8, 'top', 'right')
      return
    }

    this.onSave.emit(this.prepareSdkCompanyToBeSaved())
    this.hideSdkDialog()
    this.resetSdkDialogForm()
  }

  cancelForm(): void {
    this.onSave.emit()
    this.hideSdkDialog()
  }

  hasDisableTokenExpirationDate(): boolean {
    return Boolean(this._sdkCompanyNew?.id)
  }

  private prepareSdkCompanyToBeSaved(): SdkNew {
    const selectedEndpoints = this._sdkEndpoints?.filter((s) => s.checked)
    this._sdkCompanyNew.endpoints = selectedEndpoints
    SdkNew.copyFromOld(this._selectedSdk, this._sdkCompanyNew)

    this._sdkCompanyNew.tokenExpirationDate = this.isoFormatTokenExpirationDate(
      this._sdkCompanyNew.tokenExpirationDate
    )

    return this._sdkCompanyNew
  }

  private isSdkDialogFormValid(): boolean {
    if (!this._sdkCompanyNew.tokenExpirationDate) {
      this._tokenExpirationDateInvalid = true
    } else {
      const isoDateStr = this.isoFormatTokenExpirationDate(
        this._sdkCompanyNew.tokenExpirationDate
      )

      this._tokenExpirationDateInvalid =
        !this.dateHelper.isDateValid(isoDateStr) ||
        !(new Date(isoDateStr) >= new Date())
    }

    this._sdkEndpointsNotSelected =
      this._sdkEndpoints?.filter((s) => s.checked).length == 0

    return (
      this._selectedSdk &&
      this._sdkCompanyNew.token &&
      this._sdkCompanyNew.tokenExpirationDate &&
      !this._tokenExpirationDateInvalid &&
      !this._sdkEndpointsNotSelected
    )
  }

  private isoFormatTokenExpirationDate(
    str: string,
    separator: string = '-'
  ): string {
    return `${str.substring(4, str.length)}${separator}${str.substring(
      2,
      str.length - 4
    )}${separator}${str.substring(0, str.length - 6)}`
  }

  private unformatIsoTokenExpirationDate(dateStr: string): string {
    if (!dateStr) return ''

    const splittedDate = dateStr.split('-')
    return `${splittedDate[2]}${splittedDate[1]}${splittedDate[0]}`
  }
}
