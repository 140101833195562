export class Constants {
  public static optionSelecione: string = 'Selecione' // first option for all dropbox components (when none option is selected)
  public static optionTodos: string = 'Todos' // default option for all dropbox components (when needs to get all information)
  public static optionTodas: string = 'Todas' // default option for all dropbox components (when needs to get all information)
  public static optionNenhuma: string = 'Nenhuma' // default option for all dropbox components (when needs to get all information without some criteria)
  public static dayNames: Array<string> = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ]
  public static dayNamesShort: Array<string> = [
    'Dom',
    'Seg',
    'Ter',
    'Qua',
    'Qui',
    'Sex',
    'Sáb',
  ]
  public static dayNamesMin: Array<string> = [
    'Dom',
    'Seg',
    'Ter',
    'Qua',
    'Qui',
    'Sex',
    'Sáb',
  ]
  public static monthNames: Array<string> = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]
  public static monthNamesShort: Array<string> = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ]
  public static today: string = 'Hoje'
  public static clear: string = 'Limpar'

  public static appPrimaryColor = '#000000'
  public static appSecondaryColor = '#ffa100'
  public static certisignCNPJ = '01554285000175'
}
