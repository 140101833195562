import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from 'src/app/core/security/auth.guard'
import { ListUserAuditHistoryComponent } from './pages/list-user-audit-history/list-user-audit-history.component'
import { ListUserProfilesComponent } from './pages/list-user-profiles/list-user-profiles.component'
import { ListUsersComponent } from './pages/list-users/list-users.component'
import { ListPermissionsComponent } from './pages/list-permissions/list-permissions.component'
import { AuthManagerGuard } from 'src/app/core/security/auth.manager.guard'
import { LoginComponent } from './pages/login/login.component'

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: ListUsersComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'profiles',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: ListUserProfilesComponent,
  },
  {
    path: 'audit-history',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: ListUserAuditHistoryComponent,
  },
  {
    path: 'permissions',
    pathMatch: 'full',
    canActivate: [AuthManagerGuard],
    component: ListPermissionsComponent,
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
