import { Injectable } from '@angular/core'
import { UseCase } from './usecase'
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router'
import { SnackBarService } from '../../components/snack-bar.service'
import { IsAccessTokenValid } from './is-access-token-valid'

type RouteData = {
  route?: ActivatedRouteSnapshot
  state?: RouterStateSnapshot
}

type Result = {
  authorized: boolean
  cause?: 'INVALID_TOKEN' | 'NO_PERMISSION'
}

@Injectable({ providedIn: 'root' })
export class IsAccessTokenValidAndUserHasPermission
  implements UseCase<RouteData, Result>
{
  constructor(
    private router: Router,
    private isAccessTokenValid: IsAccessTokenValid,
    private snackBar: SnackBarService
  ) {}

  execute(param: RouteData): Result {
    let result: Result = { authorized: true }

    if (!this.isAccessTokenValid.execute()) {
      console.log('Token de acesso não é válido. Redirecionando...')
      this.snackBar.warn('Token de acesso não é válido. Redirecionando...', 5)

      this.router.navigate(['/login'], {
        queryParams: { url: param?.state?.url },
      })
      result.authorized = false
      result.cause = 'INVALID_TOKEN'
    } else if (param?.route?.data?.roles) {
      console.log('Usuário sem permissão. Redirecionando...')
      this.snackBar.warn('Usuário sem permissão. Redirecionando...', 5)
      this.router.navigate(['/login'])
      result.authorized = false
      result.cause = 'NO_PERMISSION'
    }

    return result
  }
}
