import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { HasLoggedUserPermission } from 'src/app/core/domain/usecases/has-logged-user-permission'
import { IsLoggedUserAdministrator } from 'src/app/core/domain/usecases/is-logged-user-administrator'
import {
  CompanyListNew,
  CompanyNew,
} from 'src/app/modules/companies/domain/models/company-new.model'
import { Company } from 'src/app/modules/companies/domain/models/company.model'
import { CreateCompanyReport } from '../../domain/usecases/create-company-report'
import { FilterCompanies } from '../../domain/usecases/filter-companies'

@Component({
  selector: 'app-list-companies',
  templateUrl: './list-companies.component.html',
  styleUrls: ['./list-companies.component.css'],
})
export class ListCompaniesComponent implements OnInit {
  companyFilter: CompanyNew = new CompanyNew()
  companies: CompanyNew[] = []
  count: number = 0
  companyList: CompanyListNew
  withoutPermission: string
  showCompanyFilter: boolean

  constructor(
    private snackBar: SnackBarService,
    private router: Router,
    private isLoggedUserAdministrator: IsLoggedUserAdministrator,
    private hasLoggedUserPermission: HasLoggedUserPermission,
    private filterCompanies: FilterCompanies,
    private createCompanyReport: CreateCompanyReport
  ) {}

  ngOnInit(): void {
    this.showCompanyFilter = this.isLoggedUserAdministrator.execute()
    this.listAllCompanies()
  }

  listAllCompanies(): void {
    this.companyFilter = new CompanyNew()
    this.searchCompanies()
  }

  searchCompanies(page?: number): void {
    if (this.checkPermission('company.list')) {
      this.withoutPermission = null
      this.filterCompanies
        .execute({ data: this.companyFilter, page: page })
        .subscribe((data) => {
          this.companyList = data
          this.companies = this.companyList.content
          this.count = this.companyList.totalElements
        })
    } else {
      this.withoutPermission = 'Sem permissão para listar companhias!'
    }
  }

  changePage(event) {
    this.searchCompanies(event.page)
  }

  showNoAddCompanyAdvise() {
    this.snackBar.warn(
      'Seu usuário não tem permissão para criar novas companhias (pois já pertence a uma).',
      5,
      'top',
      'right'
    )
  }

  reportCompany(company: CompanyNew) {
    this.createCompanyReport.execute(company)
  }

  checkPermission(permission: string): boolean {
    return this.hasLoggedUserPermission.execute(permission)
  }

  routeToNewCompany(): void {
    this.router.navigate(['/companies/create'])
  }

  editCompany(company: Company): void {
    this.router.navigate([`/companies/${company.id}`])
  }
}
