import { Injectable } from '@angular/core'
import { UseCase } from './usecase'
import { UrlHelper } from '../../helpers/url.helper'

@Injectable({ providedIn: 'root' })
export class GetParamFromUrl implements UseCase<string, string> {
  constructor(private urlHelper: UrlHelper) {}

  execute(param: string): string {
    return this.urlHelper.getParam(param)
  }
}
