import { Injectable } from '@angular/core'
import { GenericHelper } from './generic.helper'

@Injectable({
  providedIn: 'root',
})
export class StorageHelper {
  constructor(private genericHelper: GenericHelper) {}

  saveBy(key: string, data: string): void {
    const shuffledData = this.genericHelper.shuffle(data)
    sessionStorage.setItem(key, shuffledData)
  }

  getBy(key: string): string {
    const shuffledData = sessionStorage.getItem(key)
    return this.genericHelper.unshuffle(shuffledData)
  }

  removeBy(key: string): string {
    const data = this.getBy(key)
    sessionStorage.removeItem(key)
    return data
  }

  clearAll(): void {
    sessionStorage.clear()
  }

  public static getItem(key: string) {
    const shuffledData = sessionStorage.getItem(key)
    return GenericHelper.unshuffle(shuffledData)
  }
}
