<div class="p-grid">
    <div [class]="_formClass">
        <div class="p-grid p-flex-column p-m-3" id="release-note-form-group">
            <app-label label="Tipo do SDK" size="12" class="p-pb-2 p-text-bold"></app-label>
            <app-sdk-type-select labelClasses="" (onSelect)="setSdkType($event)"></app-sdk-type-select>
            <small class="p-error" *ngIf="_isSubmitted && !releaseNote.type">O tipo do SDK é obrigatório.</small>
        </div>
        <div class="p-grid p-flex-column p-m-3" id="release-note-form-group">
            <app-label class="p-pb-2" label="Versão" size="12" classes="p-text-bold"></app-label>
            <app-input type="text" customStyle="width: 100%" [(ngModel)]="releaseNote.version"></app-input>
            <small class="p-error" *ngIf="_isSubmitted && !releaseNote.version">A versão do SDK é obrigatória.</small>
        </div>
        <div class="p-grid p-flex-column p-m-3" id="release-note-form-group">
            <app-label class="p-pb-2" label="Data da Versão" size="12" classes="p-text-bold"></app-label>
            <app-date-input (selected)="setVersionDate($event)" [initialDate]="_initialVersionDate"></app-date-input>
            <small class="p-error" *ngIf="_isSubmitted && !releaseNote.versionDate">A data da versão do SDK é
                obrigatória.</small>
        </div>
        <div class="p-grid p-flex-column p-m-3" id="release-note-form-group">
            <app-label class="p-pb-2" label="Data da Liberação" size="12" classes="p-text-bold"></app-label>
            <app-date-input (selected)="setReleaseDate($event)" [initialDate]="_initialReleaseDate"></app-date-input>
            <small class="p-error" *ngIf="_isSubmitted && !releaseNote.releaseDate">A data da liberação do SDK é
                obrigatória.</small>
        </div>
        <div *ngIf="!isSdkServer()" class="p-grid p-flex-column p-m-3" id="release-note-form-group">
            <app-label class="p-pb-2" label="Link para Download" size="12" classes="p-text-bold"></app-label>
            <app-input type="text" customStyle="width: 100%" [(ngModel)]="releaseNote.download"></app-input>
            <small class="p-error" *ngIf="_isSubmitted && !releaseNote.download">O link para download do SDK é
                obrigatório.</small>
            <small class="p-error" *ngIf="_isSubmitted && (!this.isSdkServer() && !isDownloadLinkValid())">O link para
                download do SDK não é
                válido.</small>
        </div>

        <div class="p-grid p-flex-column p-m-3" id="release-note-form-group">
            <app-label class="p-pb-2" label="Nota de Atualização" size="12" classes="p-text-bold"></app-label>
            <div class="p-grid p-jc-between">
                <div class="p-col-10">
                    <textarea id="release-note-input" name="" rows="4" [(ngModel)]="_releaseNoteItem"></textarea>
                </div>
                <div class="p-col-2">
                    <app-button classes="p-mr-2" (onClick)="addReleaseNote()" id=""
                        title="adicionar nota de atualização" icon="add" label="" theme="primary"></app-button>
                    <app-button (onClick)="clearReleaseNote()" id="" title="limpar o campo nota de atualização"
                        icon="refresh" label="" theme="secondary"></app-button>
                </div>
            </div>
            <small class="p-error" *ngIf="_isSubmitted && !releaseNote.contents?.length">As notas de atualização do SDK
                são obrigatórias.</small>
            <app-sdk-release-note-list [notes]="releaseNote.contents" (onDeleting)="deleteNote($event)"
                (onEditing)="editNote($event)"></app-sdk-release-note-list>
        </div>
        <div class="p-grid p-m-3">
            <app-button classes="p-mr-3" *ngIf="hasToEnablePreviewButton()" (onClick)="previewRelease()" type="rounded"
                theme="secondary" label="Pré-visualizar"></app-button>
            <app-button classes="p-mr-3" (onClick)="cancelForm()" type="rounded" theme="third"
                label="Cancelar"></app-button>
            <app-button (onClick)="saveRelease()" type="rounded" theme="primary" label="Salvar"></app-button>
        </div>
    </div>
    <div *ngIf="_isPreviewEnabled" class="p-col-5" id="preview-sdk-release">
        <div class="p-d-flex p-jc-center">
            <app-text class="p-pb-2" text="Pré-visualização" size="18" classes="p-text-bold"></app-text>
        </div>
        <app-sdk-release [releaseNote]="releaseNote"></app-sdk-release>
    </div>
</div>