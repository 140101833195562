import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'

@Component({
  selector: 'app-sdk-release-note-list',
  templateUrl: './sdk-release-note-list.component.html',
  styleUrls: ['./sdk-release-note-list.component.css'],
})
export class SdkReleaseNoteListComponent implements OnChanges {
  @Input() notes: string[]
  @Output() onEditing = new EventEmitter<string>()
  @Output() onDeleting = new EventEmitter<string>()

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.notes?.currentValue) {
      this.notes = changes.notes.currentValue
    }
  }

  edit(note: string): void {
    if (note) this.onEditing.emit(note)
  }

  remove(note: string): void {
    if (note) this.onDeleting.emit(note)
  }
}
