import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class NumberHelper {
  private formatter = new Intl.NumberFormat('pt-Br')

  format(num: number): string {
    return this.formatter.format(num)
  }

  formatPercentage(num: number): string {
    return `${this.roundWithPlaces(2, num)}%`
  }

  roundWithPlaces(places: number, value: number): number {
    return Number(
      (Number(value.toFixed(places)) * Math.pow(10, places)).toFixed(places)
    )
  }
}
