import { Injectable } from '@angular/core'
import { UseCase } from './usecase'

type DateParts = {
  days?: number
  month?: number
  year?: number
  origin?: Date
}

@Injectable({ providedIn: 'root' })
export class AddDayMonthYearToDate implements UseCase<DateParts, Date> {
  execute(param: DateParts): Date {
    const dateFrom = param.origin ? new Date(param.origin) : new Date()

    if (param.days) {
      dateFrom.setDate(dateFrom.getDate() + param.days)
    }

    if (param.month) {
      dateFrom.setMonth(dateFrom.getMonth() + param.month)
    }

    if (param.year) {
      dateFrom.setFullYear(dateFrom.getFullYear() + param.year)
    }

    return dateFrom
  }
}
