import { Sdk } from './sdk.model'

export class Endpoint {
  id: number
  description?: string = ''
  descriptionOriginal?: string = ''
  name: string = ''
  sdk?: Sdk
  enabled?: boolean
  checked?: boolean

  callUsage?: number // transient attribute (auxiliar variable)
  callLimit?: number // transient attribute (auxiliar variable)

  constructor(description?: string) {
    this.description = description ? description : null
  }
}
