import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { Profile } from '../models/profile.model'
import { ProfileService } from '../../services/profile.service'

@Injectable({ providedIn: 'root' })
export class SaveUserProfile implements UseCase<Profile, Observable<string>> {
  constructor(private profileService: ProfileService) {}

  execute(profile: Profile): Observable<string> {
    return this.profileService.saveProfile(profile)
  }
}
