<div *ngIf="hasUserLoggedIn">
    <app-header></app-header>
    <app-menu></app-menu>
</div>

<router-outlet></router-outlet>
<app-spinner></app-spinner>
<app-block-screen></app-block-screen>

<div *ngIf="hasUserLoggedIn" class="p-pt-6">
    <app-footer></app-footer>
</div>