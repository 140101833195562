import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Sdk, SdkList } from '../domain/models/sdk.model'

@Injectable({
  providedIn: 'root',
})
export class SdkService {
  private apiUrl = `${environment.apiUrl}`
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  }

  constructor(private httpClient: HttpClient) {}

  public listAllSdks(): Observable<Sdk[]> {
    return this.httpClient.get<Sdk[]>(
      this.apiUrl + 'sdk/list-all',
      this.httpOptions
    )
  }

  public listSdks(page: number): Observable<SdkList> {
    return this.httpClient.post<any>(
      this.apiUrl + 'sdk/list?page=' + page,
      this.httpOptions
    )
  }

  public saveSdk(sdk: Sdk): Observable<string> {
    return this.httpClient.post<string>(
      this.apiUrl + 'sdk/' + (sdk.id ? 'update' : 'add'),
      sdk,
      this.httpOptions
    )
  }

  public retrieveStatusSdk(sdkArray): Observable<any> {
    //TODO: this endpoint does not provide the expected data (and it's throwing error 500), it should be created a ednpoint to provide the right data
    return this.httpClient.post<any>(
      this.apiUrl + 'status',
      sdkArray,
      this.httpOptions
    )
  }
}
