import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { PrimeNGConfig } from 'primeng/api'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { Sdk } from 'src/app/core/domain/models/sdk.model'
import { GetLoggedUserCompany } from 'src/app/core/domain/usecases/get-logged-user-company'
import { HasLoggedUserPermission } from 'src/app/core/domain/usecases/has-logged-user-permission'
import { IsLoggedUserAdministrator } from 'src/app/core/domain/usecases/is-logged-user-administrator'
import { ChartHelper } from 'src/app/core/helpers/chart.helper'
import { Constants } from 'src/app/core/helpers/constants'
import { GenericHelper } from 'src/app/core/helpers/generic.helper'
import { CompanyNew } from 'src/app/modules/companies/domain/models/company-new.model'
import { Dashboard } from '../../domain/models/dashboard.model'
import { TransactionType } from '../../domain/models/transaction-type.enum'
import { Transaction } from '../../domain/models/transaction.model'
import { GetTransactionsDashboardData } from '../../domain/usecases/get-transactions-dashboard-data'
import { HasToShowLinkToTransactionsSummary } from '../../domain/usecases/has-to-show-link-to-transactions-summary'
import { RenameSdkOldToNew } from '../../domain/usecases/rename-sdk-old-to-new'
import { TransactionService } from '../../services/transaction.service'

@Component({
  selector: 'app-transaction-dashboard',
  templateUrl: './transaction-dashboard.component.html',
  styleUrls: ['./transaction-dashboard.component.css'],
})
export class TransactionDashboardComponent implements OnInit {
  transactionFilter: Transaction = new Transaction()
  sdkFilter: Sdk[] = []
  loading: boolean
  withoutPermission: string = ''
  emptyMessage: string

  dashboardData: Dashboard[] = []
  dashboardChart: any = {}
  showTransactionsSummaryDialog: boolean = false
  companyFromDropdown: CompanyNew

  constructor(
    private config: PrimeNGConfig,
    private transactionService: TransactionService,
    private genericHelper: GenericHelper,
    private hasLoggedUserPermission: HasLoggedUserPermission,
    private chartHelper: ChartHelper,
    private snackBar: SnackBarService,
    private router: Router,
    private hasToShowLinkToTransactionsSummary: HasToShowLinkToTransactionsSummary,
    private getTransactionsDashboardData: GetTransactionsDashboardData,
    private renameSdkOldToNew: RenameSdkOldToNew,
    private isLoggedUserAdministrator: IsLoggedUserAdministrator,
    private getLoggedUserCompany: GetLoggedUserCompany
  ) {}

  ngOnInit(): void {
    this.config.setTranslation(this.genericHelper.getCalendarTranslations())

    if (!this.hasLoggedUserPermission.execute('transaction.dashboard')) {
      this.withoutPermission =
        'Sem permissão para visualizar dashboard de transações!'
      return
    }

    this.listAllSdks()
    this.loadDashboard()
  }

  listAllSdks(): void {
    if (this.hasLoggedUserPermission.execute('sdk.list')) {
      this.transactionService.listAllSdks().subscribe((data) => {
        this.sdkFilter.push(new Sdk(Constants.optionTodos))
        this.transactionFilter.sdk = Constants.optionTodos
        this.sdkFilter = this.sdkFilter.concat(data)
      })
    } else {
      this.snackBar.warn('Sem permissão para listar sdks!', 5, 'top', 'right')
    }
  }

  quickFilter(period: string) {
    if (period == 'day') {
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      this.transactionFilter.dateFrom = today
    } else if (period == 'week') {
      let d = new Date(),
        day = d.getDay(),
        diff = d.getDate() - day
      let sunday = new Date(d.setDate(diff))
      sunday.setHours(0, 0, 0, 0)
      this.transactionFilter.dateFrom = sunday
    } else if (period == 'month') {
      let firstDayMonth = new Date()
      firstDayMonth.setDate(1)
      firstDayMonth.setHours(0, 0, 0, 0)
      this.transactionFilter.dateFrom = firstDayMonth

      // } else if(period == 'year') {
      //   let firstDayMonth = new Date();
      //   firstDayMonth.setMonth(0);
      //   firstDayMonth.setDate(1);
      //   firstDayMonth.setHours(0, 0, 0, 0);
      //   this.transactionFilter.dateFrom = firstDayMonth;
    }
    this.transactionFilter.dateTo = new Date()
    this.loadDashboard()
  }

  loadDashboard() {
    if (this.withoutPermission) {
      this.snackBar.warn(this.withoutPermission, 5, 'top', 'right')
      return
    }

    if (this.hasLoggedUserPermission.execute('transaction.dashboard')) {
      this.loading = true
      this.emptyMessage = 'Carregando informações...'
      this.withoutPermission = ''

      this.transactionFilter.company = this.companyFromDropdown
      if (!this.transactionFilter.dateTo) {
        this.transactionFilter.dateTo = new Date()
      }

      if (!this.transactionFilter.dateFrom) {
        const f = new Date()
        f.setDate(this.transactionFilter.dateTo.getDate() - 1)
        this.transactionFilter.dateFrom = f
      }

      let self = this
      this.getTransactionsDashboardData
        .execute(this.transactionFilter)
        .subscribe(
          (data) => {
            this.dashboardData = data
            let total = 0
            this.dashboardData.forEach(function (d) {
              total += d.count
              d.sdk = self.renameSdkOldToNew.execute(d.sdk)
              let sdk = self.sdkFilter.find((s) => s.name == d.sdk)
              if (sdk) {
                let endpoint = sdk.endpointList.find((e) => e.name == d.path)
                if (endpoint) d.path = endpoint.description
              }
            })
            let labels = [],
              percs = [],
              colors = [],
              bgColors = []
            this.dashboardData.forEach(function (d, i) {
              labels.push(d.path + ' (%)')
              percs.push(((d.count * 100) / total).toFixed(2))
              d.color = self.chartHelper.getChartColor(i)
              colors.push(d.color)
              bgColors.push(d.color + '75') // color with opacity %75
            })
            this.dashboardChart = {
              labels: labels,
              datasets: [
                {
                  data: percs,
                  backgroundColor: colors,
                  hoverBackgroundColor: bgColors,
                },
              ],
            }
            if (this.dashboardData.length == 0)
              this.emptyMessage = 'Nenhuma informação encontrada!'
            else {
              let dTotal = new Dashboard()
              dTotal.sdk = dTotal.path = ''
              dTotal.count = total
              this.dashboardData.push(dTotal)
            }
          },
          (error) => {
            this.emptyMessage = error.message
          }
        )
    }
  }

  routeToSummary(item): void {
    const params = {}

    const type = TransactionType.getType(item.path)
    if (type) {
      params['transactionType'] = type
    }

    const cnpj =
      this.companyFromDropdown?.cnpj || this.getLoggedUserCompany.execute().cnpj
    if (this.isLoggedUserAdministrator.execute() && !cnpj) {
      this.showTransactionsSummaryDialog = true
      return
    }

    params['company'] = cnpj
    this.router.navigate(['/transactions/summary'], { queryParams: params })
  }

  hasChartPage(path: string): boolean {
    return this.hasToShowLinkToTransactionsSummary.execute(path)
  }

  closeTransactionsSummaryDialog(): void {
    this.showTransactionsSummaryDialog = false
  }

  selectedCompany(company: { id: string; name: string }): void {
    const companyCnpj = company.id
    this.companyFromDropdown = new CompanyNew(company.name, companyCnpj)
  }

  setDateRange(range: any) {
    this.transactionFilter.dateFrom = range.dateFrom
    this.transactionFilter.dateTo = range.dateTo
  }
}
