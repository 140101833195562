import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ConvertIsoDateToString } from 'src/app/core/domain/usecases/convert-iso-date-to-string'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { BioAnalyticsService } from '../../services/bio-analytics.service'
import { FilterTransactionsSummary } from '../models/filter-transactions-summary'
import { TransactionsSummary } from '../models/transactions-summary.model'

@Injectable({ providedIn: 'root' })
export class GetTransactionsSummary
  implements
    UseCase<FilterTransactionsSummary, Observable<TransactionsSummary>>
{
  constructor(
    private bioAnalyticsService: BioAnalyticsService,
    private convertIsoDateToString: ConvertIsoDateToString
  ) {}

  execute(
    filterParams: FilterTransactionsSummary
  ): Observable<TransactionsSummary> {
    const filter = {
      dateRange: {
        dateFrom: this.convertIsoDateToString.execute(filterParams.dateFrom),
        dateTo: this.convertIsoDateToString.execute(filterParams.dateTo),
      },
      transactionType: filterParams.transactionType,
      companyNumber: filterParams.companyNumber,
    }

    return this.bioAnalyticsService.getTransactionsSummary(filter)
  }
}
