<app-title title="CONTROLE DE PERFIS"></app-title>

<div class="p-col" style="max-width: 100%;">
    <p-card>
        <button pButton [disabled]="!checkPermission('profile.add')" icon='pi pi-plus' (click)="openForm(null)"
            class="p-button-rounded primaryButton" label="Adicionar"
            title="Clique para abrir o formulário para adicionar perfil"></button>
        <div class="p-col"></div>
        <div class="card" id="table-card">
            <p-table [value]="profiles" [scrollable]="true" [resizableColumns]="true" [rows]="5" [rowHover]="true"
                dataKey="id" [showCurrentPageReport]="true">
                <!-- <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                      <h5 class="p-mr-3">Busca</h5>
                      <span class="p-input">
                        <label for="filterName" class="p-mr-2">Nome</label>
                        <input pInputText id="filterName" [(ngModel)]="profileFilter.name" type="text" (keyup.enter)="searchProfiles()"/>
                      </span>
                      <p-button (click)="searchProfiles()" label="Filtrar" icon='pi pi-filter'></p-button>
                    </div>
                </ng-template> -->
                <ng-template pTemplate="colgroup">
                    <colgroup>
                        <col style="width:40px">
                        <col style="width:150px">
                        <col style="width:250px">
                        <col style="width:60px">
                        <col style="width:40px">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr id="columns">
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Descrição</th>
                        <th>Permissões</th>
                        <th>Editar</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-profile>
                    <tr>
                        <td>{{profile.id}}</td>
                        <td>{{profile.name}}</td>
                        <td>{{profile.description}}</td>
                        <td>{{profile.countPermissions}}</td>
                        <td>
                            <button pButton [disabled]="!checkPermission('profile.update')" pRipple icon="pi pi-pencil"
                                class="secundaryButton p-button-rounded p-mr-2" (click)="openForm(profile)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5" style="text-align: center">{{withoutPermission}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Total: {{ count }} perfi{{ count > 1 ? 's' : 'l' }}
                    </div>
                </ng-template>
            </p-table>
            <p-paginator [rows]="10" (onPageChange)=changePage($event) [totalRecords]="count"></p-paginator>
        </div>
    </p-card>

    <p-dialog [(visible)]="profileDialog" [style]="{width: '400px'}" header="Detalhes do Perfil" [modal]="true"
        styleClass="p-fluid" (onHide)="hideDialog()">
        <ng-template pTemplate="content">
            <div class="p-field">
                <label class="p-mr-2" for="name">Nome</label>
                <input type="text" pInputText id="name" [(ngModel)]="profile.name" required autofocus />
                <small class="p-error" *ngIf="submitted && !profile.name">Nome do perfil é obrigatório.</small>
            </div>
            <div class="p-field">
                <label class="p-mr-2" for="description">Descrição</label>
                <input type="text" pInputText id="description" [(ngModel)]="profile.description" required autofocus />
                <small class="p-error" *ngIf="submitted && !profile.description">Descrição do perfil é
                    obrigatória.</small>
            </div>
            <div class="p-field">
                <div *ngIf="profile.creationDate">
                    <label class="p-mr-2" class="p-mr-1">Data/hora criação:</label>
                    <label>{{profile.creationDate | date:'dd/MM/yyyy HH:mm:ss'}}</label>
                </div>
            </div>
            <div class="p-field" style="height: 200px; overflow: auto">
                <label class="p-mb-3">Permissões</label>
                <div class="p-field-checkbox" *ngFor="let resource of resources">
                    <label>
                        <input type="checkbox" [id]="resource.id" [checked]="resource.checked"
                            (change)="resource.checked = !resource.checked" [disabled]="!resource.enabled">
                    </label>
                    <label [for]="resource.id" class="p-ml-1"
                        [style]="resource.enabled ? '' : 'text-decoration: line-through'" title="{{resource.name}}">
                        {{resource.description + (resource.enabled ? '' : ' (Desabilitado)')}}</label>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">

            <app-button classes="p-mr-4" label="Cancelar" type="rounded" theme="secondary" icon="cancel"
                title="Clique para alterar a senha" (onClick)="hideDialog()"></app-button>

            <app-button label="Salvar" type="rounded" theme="primary" icon="check"
                (onClick)="saveProfile()"></app-button>

            <!-- <button pButton pRipple label="Cancelar" icon="pi pi-times" class="noButton p-button-rounded"
                (click)="hideDialog()"></button>
            <button pButton pRipple label="Salvar" icon="pi pi-check" class="yesButton p-button-rounded p-text-bold"
                (click)="saveProfile()"></button> -->
        </ng-template>
    </p-dialog>
</div>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
<p-toast></p-toast>