import { Injectable } from '@angular/core'
import { UseCase } from './usecase'
import { DateHelper } from '../../helpers/date.helper'

@Injectable({ providedIn: 'root' })
export class ConvertIsoDateToString implements UseCase<Date, string> {
  constructor(private dateHelper: DateHelper) {}

  execute(param: Date): string {
    return this.dateHelper.getIsoDateStringWithNoTime(param)
  }
}
