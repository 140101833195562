<p-table [value]="_sdkCompanyList">
    <ng-template pTemplate="header">
        <tr>
            <th>
                <app-text text="SDKs" size="14" classes="p-text-bold"></app-text>
            </th>
            <th></th>
            <th colspan="4" class="p-text-right">
                <div class="p-d-flex p-jc-end">
                    <div *appShowByUserPermission="'company.update'">
                        <app-button (onClick)="showSdkDialog()" icon="add" type="rounded" theme="primary"
                            label="SDK"></app-button>
                    </div>
                </div>
            </th>
        </tr>
        <tr>
            <th>Nome</th>
            <th>Expiração</th>
            <th>Serviços</th>
            <th>Token</th>
            <th>Editar</th>
            <th>Excluir</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="colgroup">
        <colgroup>
            <col style="width:80px">
            <col style="width:80px">
            <col style="width:140px">
            <col style="width:40px">
            <col style="width:40px">
            <col style="width:40px">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="body" let-sdkCompany>
        <tr>
            <td>{{sdkCompany.name}}</td>
            <td>{{sdkCompany.tokenExpirationDate | date:'dd/MM/yyyy'}}</td>
            <td>
                <app-chips [data]="getEndpoints(sdkCompany)"></app-chips>
            </td>
            <td>
                <app-button *appShowByUserPermission="'company.update'" label="" title="Copiar token" theme="secondary"
                    (onClick)="copyToken(sdkCompany)" icon="copy" type="rounded"></app-button>
            </td>
            <td>
                <app-button *appShowByUserPermission="'company.update'" label="" theme="secondary"
                    (onClick)="editSdk(sdkCompany)" icon="edit" type="rounded"></app-button>
            </td>
            <td>
                <app-button *appShowByUserPermission="'company.update'" label="" theme="secondary"
                    (onClick)="deleteSdk(sdkCompany)" icon="delete" type="rounded"></app-button>
            </td>
        </tr>
    </ng-template>
</p-table>

<!-- new/edit sdk -->
<app-company-sdk-form [visible]="_showSdkDialog" [sdkCompany]="_sdkCompanyNew"
    (onSave)="saveSdk($event)"></app-company-sdk-form>