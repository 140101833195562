import { AfterViewChecked, Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ReCaptchaV3Service } from 'ng-recaptcha'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { UserCredentials } from 'src/app/core/domain/models/user-credentials'
import { LogUserIn } from 'src/app/core/domain/usecases/log-user-in'
import { StorageHelper } from 'src/app/core/helpers/storage.helper'
import { UrlHelper } from 'src/app/core/helpers/url.helper'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, AfterViewChecked {
  userCredentials = new UserCredentials()
  private url: string

  constructor(
    private snackBar: SnackBarService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private storageHelper: StorageHelper,
    private urlHelper: UrlHelper,
    private logUserIn: LogUserIn
  ) {}

  ngOnInit(): void {
    this.storageHelper.clearAll()
    this.url = this.urlHelper.getParam('url')
  }

  ngAfterViewChecked(): void {
    this.showHideRecaptcha(true)
  }

  showHideRecaptcha(show: boolean) {
    let recaptcha = document.getElementsByClassName(
      'grecaptcha-badge'
    ) as HTMLCollectionOf<HTMLElement>
    if (recaptcha && recaptcha.item(0) && recaptcha.item(0).style)
      recaptcha.item(0).style.visibility = show ? 'visible' : 'hidden'
  }

  sendLogin() {
    this.recaptchaV3Service
      .execute('importantAction')
      .subscribe((token) => this.handleToken(token))
  }

  private handleToken(recaptchaToken: string) {
    const self = this
    this.userCredentials.recaptcha = recaptchaToken

    self.logUserIn.execute(this.userCredentials).subscribe(
      (_) => {
        if (self.url) {
          this.redirectToUrlParam(self.url)
        } else {
          this.redirectToHome()
        }
        this.showHideRecaptcha(false)
      },
      (error) => {
        console.log(error.name + ': ' + error.statusText)
        this.snackBar.error(JSON.stringify(error.error), 5, 'top', 'right')
        self.userCredentials.email = ''
      }
    )
  }

  private redirectToUrlParam(url: string) {
    try {
      const urlComponent = decodeURIComponent(url)
      const pathAndParams = urlComponent.split('?')

      const path = pathAndParams[0]
      const params = pathAndParams[1]?.split('&')
      let paramsMap = {}
      params?.forEach((it) => {
        let nameAndValue = it.split('=')
        if (nameAndValue[0] && nameAndValue[1])
          paramsMap[nameAndValue[0]] = nameAndValue[1]
      })

      this.router.navigate([path], { queryParams: paramsMap })
    } catch (error) {
      this.redirectToHome()
    }
  }

  private redirectToHome() {
    this.router.navigate(['/transactions/dashboard'])
  }
}
