import { Component, OnInit } from '@angular/core'
import { ConfirmationService } from 'primeng/api'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { GenericHelper } from 'src/app/core/helpers/generic.helper'
import { Resource, ResourceList } from '../../domain/models/resource.model'
import { GetUserPermissionsByPage } from '../../domain/usecases/get-user-permissions-by-page'
import { SaveUserPermission } from '../../domain/usecases/save-user-permission'

@Component({
  selector: 'app-list-permissions',
  templateUrl: './list-permissions.component.html',
  styleUrls: ['./list-permissions.component.css'],
})
export class ListPermissionsComponent implements OnInit {
  resourceDialog: boolean
  submitted: boolean
  resourceFilter: Resource = new Resource()

  resources: Resource[] = []

  count: number = 0

  resourceList: ResourceList

  resource = new Resource()

  constructor(
    private saveUserPermission: SaveUserPermission,
    private snackBar: SnackBarService,
    private confirmationService: ConfirmationService,
    private genericHelper: GenericHelper,
    private getUserPermissionsByPage: GetUserPermissionsByPage
  ) {}

  ngOnInit() {
    this.listAllResources()
  }

  listAllResources() {
    this.resourceFilter = new Resource()
    this.searchResources()
  }

  searchResources(page?: number) {
    this.getUserPermissionsByPage.execute(page).subscribe((data) => {
      console.log(data)
      this.resourceList = data
      this.resources = this.resourceList.list
      this.count = this.resourceList.count
      // this.resources.forEach(function(r) {
      //   let aux = r.description;
      //   r.description = r.descriptionOriginal;
      //   r.descriptionOriginal = aux;
      // });
    })
  }

  openForm(resource: Resource) {
    if (resource) this.resource = this.genericHelper.deepCopy(resource)
    else {
      this.resource = new Resource()
      this.resource.enabled = true
    }
    this.resourceDialog = true
  }

  hideDialog() {
    this.resourceDialog = false
    this.submitted = false
  }

  changePage(event) {
    this.searchResources(event.page)
  }

  preSaveResource() {
    this.submitted = true
    console.log(this.resource)
    if (this.resource.name && this.resource.descriptionOriginal) {
      if (this.resource.id) {
        this.saveResource()
      } else {
        this.confirmationService.confirm({
          message:
            'O nome NÃO poderá ser alterado posteriormente.<br>Confirma a inclusão do recurso com o nome <b>' +
            this.resource.name +
            '</b>?',
          header: 'Confirmação',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Sim',
          acceptButtonStyleClass: 'yesButton',
          rejectButtonStyleClass: 'noButton',
          rejectLabel: 'Não',
          accept: () => {
            this.saveResource()
          },
        })
      }
    }
  }

  saveResource() {
    this.saveUserPermission.execute(this.resource).subscribe(
      () => {},
      (error) => {
        if (error.status == 200) {
          this.snackBar.success('Recurso Salvo!', 5, 'top', 'right')
          this.hideDialog()
          this.listAllResources()
        } else if (error.status == 400) {
          this.snackBar.error(
            'Dados de formulário inválidos!',
            5,
            'top',
            'right'
          )
        } else if (error.status == 405) {
          this.snackBar.error(
            'Sem permissões para esta ação!',
            5,
            'top',
            'right'
          )
        } else {
          this.snackBar.error('Erro desconhecido', 5, 'top', 'right')
        }
      }
    )
  }
}
