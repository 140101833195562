<app-title title="DASHBOARD DE TRANSAÇÕES"></app-title>

<div class="p-col" style="max-width: 100%">

  <p-card>
    <div class="card">
      <div class="p-d-flex p-ai-center p-jc-between">
        <div class="p-input" style="width: 23%">
          <app-company-filter groupClasses="p-d-flex" labelClasses="p-as-center p-pr-2 p-text-bold"
            [activeCompany]="companyFromDropdown?.name" [hasAllCompanies]="true"
            (selectedCompany)="selectedCompany($event)"></app-company-filter>
        </div>

        <div class="p-input">
          <app-date-range-filter class="" [initDateFrom]="transactionFilter?.dateFrom"
            [initDateTo]="transactionFilter?.dateTo" (selectedRange)="setDateRange($event)"></app-date-range-filter>
        </div>
        <!-- <span class="p-input">
          <label for="filterDatetimeFrom" class="p-mr-2">De</label>
          <p-calendar id="filterDatetimeFrom" appendTo="body" dateFormat="dd/mm/yy"
            [(ngModel)]="transactionFilter.dateFrom" showButtonBar="true"></p-calendar>
        </span>

        <span class="p-input">
          <label for="filterDatetimeTo" class="p-mr-2">Até</label>
          <p-calendar id="filterDatetimeTo" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="transactionFilter.dateTo"
            showButtonBar="true"></p-calendar>
        </span> -->

        <app-button label="Hoje" (onClick)="quickFilter('day')" type="rounded" theme="third"></app-button>
        <app-button label="Semana" (onClick)="quickFilter('week')" type="rounded" theme="third"></app-button>
        <app-button label="Mês" (onClick)="quickFilter('month')" type="rounded" theme="third"></app-button>
        <app-button class="p-ml-4" (onClick)="loadDashboard()" icon="filter" type="rounded" theme="primary"
          label="Filtrar"></app-button>
      </div>
      <h3 *ngIf="withoutPermission" class="p-text-center">
        {{ withoutPermission }}
      </h3>
    </div>
    <div *ngIf="!withoutPermission" class="p-grid" style="padding-top: 28px">
      <div class="p-card-shadow p-card p-component p-col-7">
        <div class="p-col">
          <p-table [value]="dashboardData" responsiveLayout="scroll">
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="3" class="p-text-center">{{ emptyMessage }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th></th>
                <!--                        <th>SDK</th>-->
                <th>Serviço</th>
                <th>Qtd</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="colgroup">
              <colgroup>
                <col style="width: 20px" />
                <!--                        <col style="width: 150px">-->
                <col style="width: 100%" />
                <col style="width: 50px" />
              </colgroup>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td [style]="'background-color: ' + item.color"></td>
                <!--                        <td>{{item.sdk}}</td>-->
                <td>
                  <span *ngIf="!hasChartPage(item.path)">{{ item.path }}</span>
                  <span id="summaryLink" *ngIf="hasChartPage(item.path)" (click)="routeToSummary(item)">
                    {{ item.path }}
                  </span>
                </td>
                <td>{{ item.count }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <div class="p-card p-card-shadow p-col-5">
        <p-chart width="100%" height="100%" type="pie" [data]="dashboardChart"
          [options]="{ legend: { display: false } }"></p-chart>
      </div>
    </div>
  </p-card>
</div>

<p-dialog [(visible)]="showTransactionsSummaryDialog">
  <ng-template pTemplate="header">
    <span>Gráfico de transações</span>
  </ng-template>
  <span>É necessário selecionar uma companhia no filtro para exibir o gráfico</span>
  <p-footer>
    <app-button label="Ok" type="raised" theme="primary" (onClick)="closeTransactionsSummaryDialog()"></app-button>
  </p-footer>
</p-dialog>