import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    if (!value) return ''

    let phone = value
    if (this.isMobile(value)) {
      phone = this.formatMobile(value)
    } else {
      phone = this.formatPhone(value)
    }

    return phone
  }

  private isMobile(phone: string): boolean {
    return phone.charAt(2) === '9' && phone.length === 11
  }

  private formatPhone(phone: string): string {
    try {
      return `(${phone.substring(0, 2)}) ${phone.substring(
        2,
        6
      )}-${phone.substring(6)}`
    } catch (error) {
      return phone
    }
  }

  private formatMobile(phone: string): string {
    try {
      return `(${phone.substring(0, 2)}) 9 ${phone.substring(
        3,
        7
      )}-${phone.substring(7)}`
    } catch (error) {
      return phone
    }
  }
}
