import { Injectable } from '@angular/core'
import {
  SummaryBySdkVersion,
  TransactionsSummary,
} from '../models/transactions-summary.model'
import { TransactionsSummaryChartData } from '../models/transactions-summary-chart-data'
import { NumberHelper } from 'src/app/core/helpers/number.helper'
import { EqualizeSdkVersionsSummaryByDay } from './equalize-sdk-versions-summary-by-day'
import { MapSummaryBySdkVersionToRejectedLineChartData } from './map-summary-by-sdk-version-to-rejected-line-chart-data'
import { RejectedTransactionsLineChartData } from '../models/rejected-transactions-line-chart-data'
import { UseCase } from 'src/app/core/domain/usecases/usecase'

@Injectable({ providedIn: 'root' })
export class MapTransactionSummaryToChartData
  implements UseCase<TransactionsSummary, TransactionsSummaryChartData>
{
  constructor(
    private numberHelper: NumberHelper,
    private equalizeSdkVersionsSummaryByDays: EqualizeSdkVersionsSummaryByDay,
    private mapSummaryBySdkVersionToRejectedLineChartData: MapSummaryBySdkVersionToRejectedLineChartData
  ) {}

  execute(summary: TransactionsSummary): TransactionsSummaryChartData {
    return {
      labels: summary.dateRange,
      successPercentageData: summary.dailiesSummary.map((it) =>
        this.numberHelper.roundWithPlaces(2, it.successRate).toString()
      ),
      failedPercentageData: summary.dailiesSummary.map((it) =>
        this.numberHelper.roundWithPlaces(2, it.failedRate).toString()
      ),
      successRawData: summary.dailiesSummary.map((it) =>
        Number(it.successAmount)
      ),
      failedRawData: summary.dailiesSummary.map((it) =>
        Number(it.failedAmount)
      ),
      totalAmount: this.numberHelper.format(summary.totalAmount),
      failedAmount: this.numberHelper.format(summary.failedAmount),
      successAmount: this.numberHelper.format(summary.successAmount),
      successRate: this.numberHelper.formatPercentage(summary.successRate),
      failedRate: this.numberHelper.formatPercentage(summary.failedRate),
      totalRawData: [summary.successAmount, summary.failedAmount],
      totalPercentageData: [
        this.numberHelper.roundWithPlaces(2, summary.successRate),
        this.numberHelper.roundWithPlaces(2, summary.failedRate),
      ],
      rejectionRateBySdkVersion: {
        browser: this.buildRejectedTransactionsLineChartData(
          summary.dailiesSummary.map((it) => it.browser)
        ),
        android: this.buildRejectedTransactionsLineChartData(
          summary.dailiesSummary.map((it) => it.android)
        ),
        ios: this.buildRejectedTransactionsLineChartData(
          summary.dailiesSummary.map((it) => it.ios)
        ),
      },
    }
  }

  private buildRejectedTransactionsLineChartData(
    sdkData: SummaryBySdkVersion[][]
  ): RejectedTransactionsLineChartData {
    const equalizedData = this.equalizeSdkVersionsSummaryByDays.execute(
      sdkData
        .filter((it) => Boolean(it))
        .reduce((list, current) => list.concat(current), [])
    )
    return this.mapSummaryBySdkVersionToRejectedLineChartData.execute(
      equalizedData
    )
  }
}
