import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { HasLoggedUserPermission } from './has-logged-user-permission'
import { UseCase } from './usecase'

@Injectable({ providedIn: 'root' })
export class HasLoggedUserConfigManagerPermission
  implements UseCase<void, boolean>
{
  private configManagerPermission = environment.configManagerPermission

  constructor(private hasLoggedUserPermission: HasLoggedUserPermission) {}

  execute(_: void): boolean {
    return this.hasLoggedUserPermission.execute(this.configManagerPermission)
  }
}
