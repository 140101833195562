export interface SdkRelease {
  id?: number
  version: string
  versionDate: string
  releaseDate: string
  type: SdkType
  download?: string
  active: boolean
  contents: string[]
}

export class SdkReleaseCreate implements SdkRelease {
  id?: number
  version: string
  versionDate: string
  releaseDate: string
  type: SdkType
  download?: string
  active: boolean
  contents: string[]

  constructor({
    type = undefined,
    version = '',
    versionDate = '',
    releaseDate = '',
    active = true,
    download = '',
    contents = [],
  }: {
    type?: SdkType
    version?: string
    versionDate?: string
    releaseDate?: string
    active?: boolean
    download?: string
    contents?: string[]
  } = {}) {
    this.id = undefined
    this.type = type
    this.version = version
    this.versionDate = versionDate
    this.releaseDate = releaseDate
    this.active = active
    this.download = download
    this.contents = contents
  }
}

export class SDkReleaseUpdate implements SdkRelease {
  id: number
  version: string
  versionDate: string
  releaseDate: string
  type: SdkType
  download?: string
  active: boolean
  contents: string[]

  constructor({
    id,
    type,
    version,
    versionDate,
    releaseDate,
    active,
    download,
    contents,
  }: {
    id?: number
    type?: SdkType
    version?: string
    versionDate?: string
    releaseDate?: string
    active?: boolean
    download?: string
    contents?: string[]
  } = {}) {
    this.id = id
    this.type = type
    this.version = version
    this.versionDate = versionDate
    this.releaseDate = releaseDate
    this.active = active
    this.download = download
    this.contents = contents
  }
}

export enum SdkType {
  BROWSER = 'BROWSER',
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  SERVER = 'SERVER',
}

export namespace SdkType {
  export function friendlyName(type: SdkType): string {
    const t = SdkType[type].toLocaleLowerCase()
    return `${t[0].toLocaleUpperCase()}${t.slice(1)}`
  }
}
