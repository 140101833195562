<app-title title="CONTROLE DE TRANSAÇÕES"></app-title>

<div class="p-col" style="max-width: 100%">
  <p-card>
    <div class="card" id="table-card">
      <p-table [value]="transactions?.list" [resizableColumns]="true" [rows]="5" [autoLayout]="true" [rowHover]="true"
        dataKey="id" [showCurrentPageReport]="true">
        <ng-template pTemplate="caption">
          <div class="p-d-flex p-ai-center p-jc-between">
            <!--<h4 class="p-m-0">Busca</h4>-->
            <span class="p-input" *ngIf="showCompanyFilter">
              <label for="filterCompany" class="p-mr-2">Companhia</label>
              <p-dropdown id="filterCompany" [options]="companiesFilter" optionLabel="name"
                [(ngModel)]="transactionFilter.company" (onChange)="searchTransactions()"></p-dropdown>
            </span>
            <!--                      <span class="p-input">-->
            <!--                        <label for="filterSdk" class="p-mr-2">SDK</label>-->
            <!--                        <p-dropdown id="filterSdk" [options]="sdkFilter" optionLabel="name" [(ngModel)]="transactionFilter.sdkFilter" (onChange)="loadEndpointFilter()"></p-dropdown>-->
            <!--                      </span>-->
            <span *ngIf="endpointsFilter" class="p-input">
              <label for="filterEndpoint" class="p-mr-2">Serviço</label>
              <p-dropdown id="filterEndpoint" [options]="endpointsFilter" optionLabel="description"
                [(ngModel)]="transactionFilter.endpointFilter"></p-dropdown>
            </span>

            <!-- <span class="p-input">
              TODO: removed bacause it should include the TID filter on transaction endpoint on core-api
              <label for="filterTid" class="p-mr-2">TID</label>
              <input pInputText id="filterTid" [(ngModel)]="transactionFilter.tid" type="text"
                (keyup.enter)="searchTransactions()" />
            </span> -->
            <span class="p-input">
              <label for="filterDatetimeFrom" class="p-mr-2">De</label>
              <p-calendar id="filterDatetimeFrom" appendTo="body" dateFormat="dd/mm/yy" hourFormat="HH:mm"
                [(ngModel)]="transactionFilter.dateFrom" showTime="true" showButtonBar="true"></p-calendar>
            </span>
            <span class="p-input">
              <label for="filterDatetimeTo" class="p-mr-2">Até</label>
              <p-calendar id="filterDatetimeTo" appendTo="body" dateFormat="dd/mm/yy" hourFormat="HH:mm"
                [(ngModel)]="transactionFilter.dateTo" showTime="true" showButtonBar="true"></p-calendar>
            </span>
            <button pButton pRipple class="primaryButton p-button-rounded" (click)="searchTransactions()"
              label="Filtrar" icon="pi pi-filter"></button>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th></th>
            <th>Companhia</th>
            <th>Serviço</th>
            <th>TID</th>
            <th>ExternalTID</th>
            <th>InstallationID</th>
            <th>Data/hora</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-transaction>
          <tr>
            <td><button pButton pRipple icon="pi pi-search-plus" title="Clique para visualizar a transação completa"
                class="secundaryButton p-button-rounded p-button-sm"
                (click)="showFullTransaction($event, transaction)"></button>
            </td>
            <td>
              {{ transaction.company?.name || '' }}
            </td>
            <td>{{ transaction.path }}</td>
            <td title="{{ transaction.tid }}">{{ transaction.tid }}</td>
            <td title="{{ transaction.externalTid }}">
              {{ transaction.externalTid }}
            </td>
            <td title="{{ transaction.installationId }}">
              {{ transaction.installationId }}
            </td>
            <td>
              {{ transaction.date | date : 'dd/MM/yyyy HH:mm:ss' }}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" *ngIf="withoutPermission">
          <tr>
            <td colspan="5" style="text-align: center">
              {{ withoutPermission }}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <div class="p-d-flex p-ai-center p-jc-between">
            Total: {{ transactions?.count || 0 }} transaç{{
            transactions?.count > 1 ? 'ões' : 'ão'
            }}
            <button pButton [disabled]="!checkPermission('transaction.report')" icon="pi pi-file-excel"
              (click)="searchTransactions(0, true)" class="p-align-end p-button-rounded primaryButton"
              label="Gerar relatório" [title]="
                checkPermission('transaction.report')
                  ? 'Clique para gerar a planilha (CSV)'
                  : 'Sem permissão para gerar a planilha'
              "></button>
          </div>
        </ng-template>
      </p-table>
      <p-paginator [rows]="pageSize" (onPageChange)="changePage($event)"
        [totalRecords]="transactions?.count"></p-paginator>
    </div>
  </p-card>
</div>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<p-toast></p-toast>