export class SummaryRate {
  successAmount: number
  failedAmount: number
  totalAmount: number
  successRate: number
  failedRate: number

  constructor(
    successAmount: number,
    failedAmount: number,
    totalAmount: number,
    successRate: number,
    failedRate: number
  ) {
    this.successAmount = successAmount
    this.failedAmount = failedAmount
    this.totalAmount = totalAmount
    this.successRate = successRate
    this.failedRate = failedRate
  }
}
export class TransactionsSummary extends SummaryRate {
  dateRange: string[]
  dailiesSummary: DailiesSummary[]

  constructor(
    dateRange: string[],
    dailiesSummary: DailiesSummary[],
    successAmount: number,
    failedAmount: number,
    totalAmount: number,
    successRate: number,
    failedRate: number
  ) {
    super(successAmount, failedAmount, totalAmount, successRate, failedRate)
    this.dateRange = dateRange
    this.dailiesSummary = dailiesSummary
  }
}

export class DailiesSummary extends SummaryRate {
  type: string
  date: string
  browser?: SummaryBySdkVersion[]
  android?: SummaryBySdkVersion[]
  ios?: SummaryBySdkVersion[]

  constructor(
    type: string,
    date: string,
    browser: SummaryBySdkVersion[] = [],
    android: SummaryBySdkVersion[] = [],
    ios: SummaryBySdkVersion[] = [],
    successAmount: number,
    failedAmount: number,
    totalAmount: number,
    successRate: number,
    failedRate: number
  ) {
    super(successAmount, failedAmount, totalAmount, successRate, failedRate)
    this.type = type
    this.date = date
    this.browser = browser
    this.android = android
    this.ios = ios
  }
}

export class SummaryBySdkVersion extends SummaryRate {
  date: string
  version: string

  constructor(
    date: string = '',
    version: string = '',
    successAmount: number = 0,
    failedAmount: number = 0,
    totalAmount: number = 0,
    successRate: number = 0,
    failedRate: number = 0
  ) {
    super(successAmount, failedAmount, totalAmount, successRate, failedRate)
    this.date = date
    this.version = version
  }
}
