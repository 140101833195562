import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'

interface LineChartData {
  labels: string[]
  datasets: {
    labels: string
    data: number[]
    fill: boolean
    borderColor: string
    backgroundColor?: string
  }[]
}

interface LineChartOptions {
  xAxes: {
    interval?: number[]
    formatLabel?: (label: string) => string
    title?: string
  }
  yAxes: {
    interval?: number[]
    stepSize?: number
    formatLabel?: (label: string) => string
    title?: string
  }
}

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css'],
})
export class LineChartComponent implements OnChanges {
  @Input() data: LineChartData
  @Input() options: LineChartOptions

  _data: any
  _plugin: any
  _options: any = {
    plugin: {},
    plugins: {},
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {},
          scaleLabel: {},
          gridLines: {
            // color: 'rgba(255,255,255,0.2)',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            // stepSize: 10,
            // beginAtZero: true,
          },
          scaleLabel: {},
          gridLines: {
            // color: 'rgba(255,255,255,0.2)',
          },
        },
      ],
    },
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this._data = changes.data.currentValue
    }

    if (changes.options?.currentValue) {
      this._plugin = changes.options?.currentValue.plugin
        ? changes.options?.currentValue.plugin
        : undefined
      this._options.plugins = changes.options.currentValue.plugins
      this.setXAxes(changes.options?.currentValue)
      this.setYAxes(changes.options?.currentValue)
    }
  }

  private setXAxes(options: LineChartOptions): void {
    if (options?.xAxes?.interval && options?.xAxes?.interval?.length == 2) {
      this._options.scales.xAxes[0].ticks.min = options.xAxes.interval[0]
      this._options.scales.xAxes[0].ticks.max = options.xAxes.interval[1]
    }

    if (options?.xAxes?.title) {
      this._options.scales.xAxes[0].scaleLabel.display = true
      this._options.scales.xAxes[0].scaleLabel.labelString = options.xAxes.title
    }

    if (options?.xAxes && options?.xAxes?.formatLabel) {
      this._options.scales.xAxes[0].ticks.callback = (label, index, labels) => {
        return options.xAxes.formatLabel(label)
      }
    }
  }

  private setYAxes(options: LineChartOptions): void {
    if (options?.yAxes?.interval && options?.yAxes?.interval?.length == 2) {
      this._options.scales.yAxes[0].ticks.min = options.yAxes.interval[0]
      this._options.scales.yAxes[0].ticks.max = options.yAxes.interval[1]
    }

    if (this.options?.yAxes?.stepSize) {
      this._options.scales.yAxes[0].ticks.stepSize = options.yAxes.stepSize
    }

    if (options?.yAxes?.title) {
      this._options.scales.yAxes[0].scaleLabel.display = true
      this._options.scales.yAxes[0].scaleLabel.labelString = options.yAxes.title
    }

    if (options?.yAxes?.formatLabel) {
      this._options.scales.yAxes[0].ticks.callback = (label, index, labels) => {
        return options.yAxes.formatLabel(label)
      }
    }
  }
}
