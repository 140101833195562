import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { SdkRelease, SdkType } from '../../domain/models/sdk-release'

@Component({
  selector: 'app-sdk-release',
  templateUrl: './sdk-release.component.html',
  styleUrls: ['./sdk-release.component.css'],
})
export class SdkReleaseComponent implements OnChanges {
  @Input() releaseNote: SdkRelease

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.releaseNote?.currentValue) {
      this.releaseNote = changes.releaseNote.currentValue
    }
  }

  showDownloadButton(): boolean {
    return this.releaseNote?.type != SdkType.SERVER
  }

  openLink(link?: string): void {
    if (link) {
      window.open(link, '_blank')
    }
  }
}
