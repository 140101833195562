import { Injectable } from '@angular/core'
import { UseCase } from './usecase'
import * as bcrypt from 'bcryptjs'
import { environment } from 'src/environments/environment'

@Injectable({ providedIn: 'root' })
export class EncryptPassword implements UseCase<string, string> {
  execute(password: string): string {
    return bcrypt.hashSync(password, environment.bcryptSalt)
  }
}
