import { Injectable } from '@angular/core'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { User, UserList } from '../models/user.model'
import { Observable } from 'rxjs'
import { UserService } from '../../services/user.service'
import { FilterData } from 'src/app/core/domain/models/filter-data'
@Injectable({ providedIn: 'root' })
export class FilterUsers
  implements UseCase<FilterData<User>, Observable<UserList>>
{
  constructor(private userService: UserService) {}

  execute(filter: FilterData<User>): Observable<UserList> {
    filter.page = filter.page || 0
    return this.userService.filterUsers(filter.data, filter.page)
  }
}
