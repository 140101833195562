import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Sdk } from 'src/app/core/domain/models/sdk.model'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { SdkService } from 'src/app/core/service/sdk.service'

@Injectable({ providedIn: 'root' })
export class SaveSdk implements UseCase<Sdk, Observable<string>> {
  constructor(private sdkService: SdkService) {}

  execute(sdk: Sdk): Observable<string> {
    sdk.description = sdk.descriptionOriginal // send english description
    sdk.endpointList.forEach((e) => (e.description = e.descriptionOriginal))

    return this.sdkService.saveSdk(sdk)
  }
}
