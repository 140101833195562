import { Injectable } from '@angular/core'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { CompanyNew } from '../models/company-new.model'
import { CompanyService } from 'src/app/core/service/company.service'
import { Observable } from 'rxjs'

type CompanyId = number
@Injectable({ providedIn: 'root' })
export class SaveNewCompany
  implements UseCase<CompanyNew, Observable<CompanyId>>
{
  constructor(private companyService: CompanyService) {}

  execute(company: CompanyNew): Observable<CompanyId> {
    return this.companyService.saveCompany(
      CompanyNew.parseToCreateCompany(company)
    )
  }
}
