import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { CopyDataToClipboard } from 'src/app/core/domain/usecases/copy-data-to-clipboard'
import {
  CompanyNew,
  SdkNew,
} from 'src/app/modules/companies/domain/models/company-new.model'

@Component({
  selector: 'app-company-sdks',
  templateUrl: './company-sdks.component.html',
  styleUrls: ['./company-sdks.component.css'],
})
export class CompanySdksComponent implements OnInit, OnChanges {
  @Input() company?: CompanyNew
  @Output() companySdks = new EventEmitter<SdkNew[]>()

  sdkList: SdkNew[] = []
  _sdkCompanyList: SdkNew[] = []

  _sdkCompanyNew: SdkNew
  _sdkDialogFormMode: 'create' | 'edit' = 'create'
  _previousSdk: SdkNew
  _showSdkDialog = false

  constructor(
    private snackBar: SnackBarService,
    private copyDataToClipboard: CopyDataToClipboard
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.company?.currentValue) {
      this.company = changes.company?.currentValue
      this._sdkCompanyList = this.company.sdks
      this._sdkDialogFormMode = 'edit'
    }
  }

  showSdkDialog(): void {
    this._showSdkDialog = true
  }

  hideSdkDialog(): void {
    this._showSdkDialog = false
  }

  editSdk(sdkCompany: SdkNew): void {
    this._sdkCompanyNew = SdkNew.copy(sdkCompany)
    this._previousSdk = SdkNew.copy(sdkCompany)

    this._sdkDialogFormMode = 'edit'
    this.showSdkDialog()
  }

  saveSdk(sdkCompany?: SdkNew): void {
    if (sdkCompany && this._sdkDialogFormMode === 'edit') {
      this.saveOnEditing(sdkCompany)
    } else if (sdkCompany && this._sdkDialogFormMode === 'create') {
      this.saveOnCreation(sdkCompany)
    }
    this.hideSdkDialog()
    this._sdkDialogFormMode = 'create'
    this.companySdks.emit(this._sdkCompanyList)
  }

  getEndpoints(sdk: SdkNew): string[] {
    return sdk.endpoints?.map((e) => e.description)
  }

  private saveOnEditing(sdkCompany?: SdkNew): void {
    this._sdkCompanyList = this._sdkCompanyList.filter(
      (s) => s.id !== this._previousSdk?.id
    )
    this._sdkCompanyList.push(sdkCompany)
  }

  private saveOnCreation(sdkCompany?: SdkNew): void {
    if (!this._sdkCompanyList?.find((s) => s.id === sdkCompany?.id)) {
      this._sdkCompanyList.push(sdkCompany)
    } else {
      this.snackBar.warn(
        `SDK ${sdkCompany.name} já foi incluído!`,
        8,
        'top',
        'right'
      )
    }
  }

  deleteSdk(sdk: SdkNew): void {
    if (this._sdkDialogFormMode === 'edit') {
      this.snackBar.warn(
        'O Sdk será efetivamente excluído somente após salvar a companhia!',
        5,
        'top',
        'right'
      )
    }

    this._sdkCompanyList = this._sdkCompanyList.filter((s) => s.id !== sdk.id)
    this.companySdks.emit(this._sdkCompanyList)
  }

  copyToken(sdkCompany: SdkNew): void {
    if (this.copyDataToClipboard.execute(sdkCompany.token)) {
      this.snackBar.info('Token foi copiado com sucesso!', 5, 'top', 'right')
    } else {
      this.snackBar.error('Não foi possível copiar o token!', 5, 'top', 'right')
    }
  }
}
