<div class="p-col p-pl-4 p-pt-4">
    <div class="p-grid">
        <div class="p-col-10 p-lg-10 p-xl-10 p-pl-3">
            <app-text text="Browser" classes="p-text-bold p-pl-6"></app-text>
            <app-rejected-transactions-rate-line-chart
                [data]="summary?.rejectionRateBySdkVersion?.browser"></app-rejected-transactions-rate-line-chart>
        </div>
        <div class="p-col-10 p-lg-10 p-xl-10 p-pl-3 p-pt-6">
            <app-text text="Android" classes="p-text-bold p-pl-6"></app-text>
            <app-rejected-transactions-rate-line-chart
                [data]="summary?.rejectionRateBySdkVersion?.android"></app-rejected-transactions-rate-line-chart>
        </div>
        <div class="p-col-10 p-lg-10 p-xl-10 p-pl-3 p-pt-6">
            <app-text text="iOS" classes="p-text-bold p-pl-6"></app-text>
            <app-rejected-transactions-rate-line-chart
                [data]="summary?.rejectionRateBySdkVersion?.ios"></app-rejected-transactions-rate-line-chart>
        </div>
    </div>
</div>