<app-title title="RESUMO DE TRANSAÇÕES"></app-title>
<div class="p-grid p-flex-column">
  <div class="p-col p-m-3">
    <div class="p-col p-pl-4 p-pb-6">
      <div class="p-d-flex p-jc-center p-pl-3">
        <app-company-filter class="p-mr-3" style="width: 25%" groupClasses="p-d-flex"
          labelClasses="p-as-center p-pr-2 p-text-bold" [activeCompany]="activeCompany" [hasAllCompanies]="false"
          (selectedCompany)="selectedCompany($event)"></app-company-filter>
        <app-date-range-filter class="p-mr-2" [initDateTo]="filterParams?.dateTo"
          [initDateFrom]="filterParams?.dateFrom" (selectedRange)="setDateRange($event)"
          [maxDateValue]="maxDateValue"></app-date-range-filter>
        <app-button class="p-ml-4" (onClick)="filter()" icon="filter" type="rounded" theme="primary"
          label="Filtrar"></app-button>
      </div>
    </div>

    <div class="p-col p-mt-2 p-pl-0 p-mb-0 p-pb-0">
      <app-transactions-summary-view-mode-options
        (activeViewMode)="setActiveViewMode($event)"></app-transactions-summary-view-mode-options>
    </div>

    <div *ngIf="activeViewMode == 1" class="transactions-summary-view-mode p-shadow-1">
      <app-transactions-summary-general-view-mode [summary]="summary"></app-transactions-summary-general-view-mode>
    </div>

    <div *ngIf="activeViewMode == 2" class="transactions-summary-view-mode p-shadow-1">
      <app-transactions-summary-sdk-view-mode [summary]="summary"></app-transactions-summary-sdk-view-mode>
    </div>
  </div>
</div>