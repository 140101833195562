import { Injectable } from '@angular/core'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { User } from '../models/user.model'
import { UserService } from '../../services/user.service'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class UpdateUserStatus implements UseCase<User, Observable<string>> {
  constructor(private userService: UserService) {}

  execute(user: User): Observable<string> {
    user.enabled = !user.enabled
    return this.userService.changeStatus(user)
  }
}
