import { Injectable } from '@angular/core'
import { StorageHelper } from './storage.helper'

@Injectable({
  providedIn: 'root',
})
export class PermissionsHelper {
  constructor(private storageHelper: StorageHelper) {}

  getPermissions(): string[] {
    return PermissionsHelper.getPermissions() || []
  }

  savePermissions(permissions: string[]): void {
    this.storageHelper.saveBy('shuffle', permissions?.join(','))
  }

  public static getPermissions(): string[] {
    return StorageHelper.getItem('shuffle')?.split(',')
  }
}
