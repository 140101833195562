import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { ResourceList } from '../models/resource.model'
import { ResourceService } from '../../services/resource.service'

type Page = number

@Injectable({ providedIn: 'root' })
export class GetUserPermissionsByPage
  implements UseCase<Page, Observable<ResourceList>>
{
  constructor(private resourceService: ResourceService) {}

  execute(page?: Page): Observable<ResourceList> {
    page = page || 0
    return this.resourceService.listResources(page)
  }
}
