import { Injectable } from '@angular/core'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { SdkRelease } from '../models/sdk-release'
import { SdkReleaseService } from '../../services/sdk-release.service'
import { Observable } from 'rxjs'

type SdkReleaseId = number

@Injectable({ providedIn: 'root' })
export class CreateSdkRelease
  implements UseCase<SdkRelease, Observable<SdkReleaseId>>
{
  constructor(private sdkReleaseService: SdkReleaseService) {}

  execute(param: SdkRelease): Observable<SdkReleaseId> {
    return this.sdkReleaseService.create(param)
  }
}
