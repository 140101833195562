import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { BarChartComponent } from './bar-chart/bar-chart.component'
import { BlockScreenComponent } from './block-screen/block-screen.component'
import { ButtonComponent } from './button/button.component'
import { CardComponent } from './card/card.component'
import { CompanyFilterComponent } from './company-filter/company-filter.component'
import { DateInputComponent } from './date-input/date-input.component'
import { DateRangeFilterComponent } from './date-range-filter/date-range-filter.component'
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component'
import { DropdownComponent } from './dropdown/dropdown.component'
import { FooterComponent } from './footer/footer.component'
import { InputComponent } from './input/input.component'
import { LabelComponent } from './label/label.component'
import { LocaleSelectComponent } from './locale-select/locale-select.component'
import { MaterialModule } from './material.module'
import { MenuComponent } from './menu/menu.component'
import { PrimeNgModule } from './prime-ng.module'
import { SpinnerComponent } from './spinner/spinner.component'
import { TitleComponent } from './title/title.component'
import { ChipsComponent } from './chips/chips.component'
import { RadioButtonComponent } from './radio-button/radio-button.component'
import { TabMenuComponent } from './tab-menu/tab-menu.component'
import { TextComponent } from './text/text.component'
import { LineChartComponent } from './line-chart/line-chart.component'
import { HeaderComponent } from './header/header.component'

const importedAndExportedModules = [MaterialModule, PrimeNgModule]

@NgModule({
  imports: [...importedAndExportedModules, CommonModule],
  exports: [
    ...importedAndExportedModules,
    FooterComponent,
    MenuComponent,
    SpinnerComponent,
    TitleComponent,
    BlockScreenComponent,
    BarChartComponent,
    LabelComponent,
    CardComponent,
    DateInputComponent,
    DateRangeFilterComponent,
    DoughnutChartComponent,
    CompanyFilterComponent,
    DropdownComponent,
    LocaleSelectComponent,
    InputComponent,
    ButtonComponent,
    ChipsComponent,
    RadioButtonComponent,
    TabMenuComponent,
    TextComponent,
    LineChartComponent,
    HeaderComponent,
  ],
  declarations: [
    FooterComponent,
    MenuComponent,
    SpinnerComponent,
    TitleComponent,
    BlockScreenComponent,
    BarChartComponent,
    LabelComponent,
    CardComponent,
    DateInputComponent,
    DateRangeFilterComponent,
    DoughnutChartComponent,
    CompanyFilterComponent,
    DropdownComponent,
    LocaleSelectComponent,
    InputComponent,
    ButtonComponent,
    ChipsComponent,
    RadioButtonComponent,
    TabMenuComponent,
    TextComponent,
    LineChartComponent,
    HeaderComponent,
  ],
})
export class ComponentsModule {}
