import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { CompanyNew } from 'src/app/modules/companies/domain/models/company-new.model'
import { SaveNewCompany } from '../../domain/usecases/save-new-company'

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.css'],
})
export class CreateCompanyComponent implements OnInit {
  _company: CompanyNew = new CompanyNew()

  constructor(
    private snackBar: SnackBarService,
    private saveNewCompany: SaveNewCompany,
    private router: Router
  ) {}

  ngOnInit(): void {}

  saveCompany(company: CompanyNew): void {
    this.saveNewCompany.execute(company).subscribe((_) => {
      this.snackBar.success('Companhia Salva!', 5, 'top', 'right')
      this.routeToCompanies()
    })
  }

  cancelForm(): void {
    this.routeToCompanies()
  }

  private routeToCompanies(): void {
    this.router.navigate(['/companies'])
  }
}
