import { Injectable } from '@angular/core'
import { UseCase } from 'src/app/core/domain/usecases/usecase'

const pathsWithTransactionsSummary = [
  'Liveness 3D',
  'Vivacidade 3D',
  'liveness3d',
]

@Injectable({ providedIn: 'root' })
export class HasToShowLinkToTransactionsSummary
  implements UseCase<string, boolean>
{
  execute(path: string): boolean {
    return pathsWithTransactionsSummary.includes(path)
  }
}
