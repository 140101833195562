import { Injectable } from '@angular/core'
import { UseCase } from './usecase'
import { GenericHelper } from '../../helpers/generic.helper'

@Injectable({ providedIn: 'root' })
export class CopyDataToClipboard implements UseCase<string, boolean> {
  constructor(private genericHelper: GenericHelper) {}

  execute(param: string): boolean {
    return this.genericHelper.copyToClipboard(param)
  }
}
