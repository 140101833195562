import { Component, Input, OnInit } from '@angular/core'
import { Transaction } from '../../domain/models/transaction.model'

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css'],
})
export class TransactionComponent implements OnInit {
  @Input() transaction: Transaction

  constructor() {}

  ngOnInit(): void {
    if (!this.transaction) this.transaction = new Transaction()
  }
}
