import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UseCase } from 'src/app/core/domain/usecases/usecase'
import { TranslationList } from '../models/translation.model'
import { TranslationService } from '../../services/translation.service'

type Page = number
@Injectable({ providedIn: 'root' })
export class GetTranslationsByPage
  implements UseCase<Page, Observable<TranslationList>>
{
  constructor(private translationService: TranslationService) {}

  execute(page: Page): Observable<TranslationList> {
    page = page || 0
    return this.translationService.listTranslations(page)
  }
}
