import { Component, OnInit } from '@angular/core'
import { ConfirmationService } from 'primeng/api'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { Endpoint } from 'src/app/core/domain/models/endpoint.model'
import { Sdk, SdkList } from 'src/app/core/domain/models/sdk.model'
import { GenericHelper } from 'src/app/core/helpers/generic.helper'
import { GetSdksByPage } from '../../domain/usecases/get-sdks-by-page'
import { SaveSdk } from '../../domain/usecases/save-sdk'

@Component({
  selector: 'app-list-sdks',
  templateUrl: './list-sdks.component.html',
  styleUrls: ['./list-sdks.component.css'],
})
export class ListSdksComponent implements OnInit {
  sdkDialog: boolean
  submitted: boolean
  sdkFilter: Sdk = new Sdk()

  sdks: Sdk[] = []

  count: number = 0

  sdkList: SdkList

  sdk = new Sdk()

  endpointDialog: boolean = false
  endpointSubmitted: boolean
  endpoint: Endpoint = new Endpoint()

  constructor(
    private snackBar: SnackBarService,
    private confirmationService: ConfirmationService,
    private genericHelper: GenericHelper,
    private getSdksByPage: GetSdksByPage,
    private saveNewSdk: SaveSdk
  ) {}

  ngOnInit() {
    this.listAllSdks()
  }

  listAllSdks() {
    this.sdkFilter = new Sdk()
    this.searchSdks()
  }

  searchSdks(page?: number) {
    this.getSdksByPage.execute(page).subscribe((data) => {
      this.sdkList = data
      this.sdks = this.sdkList.list
      this.count = this.sdkList.count
      // this.sdks.forEach(function(r) {
      //   let aux = r.description;
      //   r.description = r.descriptionOriginal;
      //   r.descriptionOriginal = aux;
      // });
    })
  }

  openForm(sdk: Sdk) {
    if (sdk) this.sdk = this.genericHelper.deepCopy(sdk)
    else {
      this.sdk = new Sdk()
      this.sdk.endpointList = []
      this.sdk.enabled = true
    }
    this.sdkDialog = true
  }

  hideDialog() {
    this.sdkDialog = false
    this.submitted = false
  }

  changePage(event) {
    this.searchSdks(event.page)
  }

  preSaveSdk() {
    this.submitted = true
    if (this.sdk.name && this.sdk.descriptionOriginal) {
      if (this.sdk.id) {
        this.saveSdk()
      } else {
        this.confirmationService.confirm({
          message:
            'O nome NÃO poderá ser alterado posteriormente.<br>Confirma a inclusão do sdk com o nome <b>' +
            this.sdk.name +
            '</b>?',
          header: 'Confirmação',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Sim',
          acceptButtonStyleClass: 'yesButton',
          rejectButtonStyleClass: 'noButton',
          rejectLabel: 'Não',
          accept: () => {
            this.saveSdk()
          },
        })
      }
    }
  }

  saveSdk() {
    this.saveNewSdk.execute(this.sdk).subscribe(
      (_) => {},
      (error) => {
        console.log(error)
        //TODO: it should be fixed on backend, the success response is coming from exception
        if (error.status == 200) {
          this.snackBar.success('SDK Salvo!', 5, 'top', 'right')
          this.hideDialog()
          this.listAllSdks()
        } else if (error.status == 400) {
          this.snackBar.error(
            'Dados de formulário inválidos!',
            5,
            'top',
            'right'
          )
        } else if (error.status == 405) {
          this.snackBar.error(
            'Sem permissões para esta ação!',
            5,
            'top',
            'right'
          )
        } else {
          this.snackBar.error('Erro desconhecido!', 5, 'top', 'right')
        }
      }
    )
  }

  // -----------------------------

  addEndpoint() {
    let e = new Endpoint()
    e.enabled = true
    this.openEndpointForm(e)
  }

  openEndpointForm(endpoint: Endpoint) {
    this.endpoint = this.genericHelper.deepCopy(endpoint)
    this.sdkDialog = false
    this.endpointDialog = true
  }

  hideEndpointDialog() {
    this.endpointSubmitted = false
    this.endpointDialog = false
    this.sdkDialog = true
  }

  preSaveEndpoint() {
    this.submitted = true
    if (this.endpoint.name && this.endpoint.descriptionOriginal) {
      if (this.endpoint.id) {
        this.saveEndpoint()
      } else {
        this.confirmationService.confirm({
          message:
            'O nome NÃO poderá ser alterado posteriormente.<br>Confirma a inclusão do endpoint com o nome <b>' +
            this.endpoint.name +
            '</b>?',
          header: 'Confirmação',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Sim',
          acceptButtonStyleClass: 'yesButton',
          rejectButtonStyleClass: 'noButton',
          rejectLabel: 'Não',
          accept: () => {
            this.saveEndpoint()
          },
        })
      }
    }
  }

  saveEndpoint() {
    this.endpointSubmitted = true
    if (!this.sdk.endpointList) this.sdk.endpointList = []
    if (!this.endpoint.id) this.sdk.endpointList.push(this.endpoint)
    else {
      for (let i = 0; i < this.sdk.endpointList.length; i++) {
        if (this.sdk.endpointList[i].id == this.endpoint.id) {
          this.sdk.endpointList[i] = this.endpoint
          break
        }
      }
    }
    this.snackBar.warn(
      'O endpoint será efetivamente salvo somente após salvar o SDK!',
      5,
      'top',
      'right'
    )
    this.hideEndpointDialog()
  }
}
