import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { Constants } from '../../helpers/constants'
import { Locale } from '../../domain/models/locale.enum'

interface LocaleData {
  id?: string
  name: string
}

@Component({
  selector: 'app-locale-select',
  templateUrl: './locale-select.component.html',
  styleUrls: ['./locale-select.component.css'],
})
export class LocaleSelectComponent implements OnInit, OnChanges {
  @Output() selected = new EventEmitter<LocaleData>()
  @Input() initialLocale?: LocaleData
  @Input() groupClasses?: string
  @Input() labelClasses?: string
  @Input() selectClasses?: string
  @Input() groupStyle?: string
  @Input() labelStyle?: string
  @Input() selectStyle?: string

  localeList: LocaleData[] = [
    { id: null, name: Constants.optionSelecione },
    { id: Locale[Locale.PT_BR], name: 'Português' },
    { id: Locale[Locale.EN_US], name: 'Inglês' },
    { id: Locale[Locale.ES_ES], name: 'Espanhol' },
  ]

  constructor() {}

  ngOnInit(): void {}

  selectedLocale(locale: any) {
    this.selected.emit(locale)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialLocale?.currentValue) {
      this.initialLocale = this.localeList.find(
        (l) => l.id === changes.initialLocale?.currentValue?.id
      )
    }
  }
}
