import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { ConvertIsoDateToString } from 'src/app/core/domain/usecases/convert-iso-date-to-string'
import { ConvertIsoStringToDate } from 'src/app/core/domain/usecases/convert-iso-string-to-date'
import { UrlHelper } from 'src/app/core/helpers/url.helper'
import {
  SdkRelease,
  SdkReleaseCreate,
  SdkType,
} from '../../domain/models/sdk-release'

@Component({
  selector: 'app-sdk-release-form',
  templateUrl: './sdk-release-form.component.html',
  styleUrls: ['./sdk-release-form.component.css'],
})
export class SdkReleaseFormComponent implements OnInit, OnChanges {
  @Input() releaseNote?: SdkRelease = new SdkReleaseCreate()
  @Output() onAction = new EventEmitter<SdkRelease>()
  @Output() onCancel = new EventEmitter<void>()

  private formClassesWithNoPreview = 'p-col-12 form-with-no-preview'
  private formClassesWithPreview = 'p-col-7 form-with-preview'
  _releaseNoteItem: string = ''
  _isPreviewEnabled = false
  _formClass = this.formClassesWithNoPreview
  _previousReleaseNoteItem = ''
  _isSubmitted = false
  _initialVersionDate: Date
  _initialReleaseDate: Date

  constructor(
    private convertIsoStringToDate: ConvertIsoStringToDate,
    private convertIsoDateToString: ConvertIsoDateToString,
    private urlHelper: UrlHelper,
    private snackBar: SnackBarService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.releaseNote?.currentValue) {
      this.releaseNote = changes.releaseNote.currentValue
      this._initialVersionDate = this.convertIsoStringToDate.execute(
        this.releaseNote.versionDate
      )
      this._initialReleaseDate = this.convertIsoStringToDate.execute(
        this.releaseNote.releaseDate
      )
    }
  }

  ngOnInit(): void {}

  setSdkType(sdkType: SdkType): void {
    this.releaseNote.type = sdkType
  }

  setReleaseDate(date: Date): void {
    this.releaseNote.releaseDate = this.convertIsoDateToString.execute(date)
  }

  setVersionDate(date: Date): void {
    this.releaseNote.versionDate = this.convertIsoDateToString.execute(date)
  }

  addReleaseNote(): void {
    if (!this._releaseNoteItem) return

    const isEditting = Boolean(this._previousReleaseNoteItem)
    if (isEditting) {
      this.replaceReleaseNote()
      this._previousReleaseNoteItem = ''
    } else {
      this.releaseNote.contents.push(this._releaseNoteItem)
    }
    this._releaseNoteItem = ''
  }

  private replaceReleaseNote(): void {
    const idx = this.releaseNote.contents.indexOf(this._previousReleaseNoteItem)
    if (idx !== -1) this.releaseNote.contents[idx] = this._releaseNoteItem
  }

  clearReleaseNote(): void {
    this._previousReleaseNoteItem = this._releaseNoteItem = ''
  }

  saveRelease(): void {
    this._isSubmitted = true
    if (!this.isFormValid()) {
      this.snackBar.warn('Fornulário inválido', 8, 'top', 'right')
      return
    }
    this.releaseNote.active = true
    this.onAction.emit(this.releaseNote)
  }

  cancelForm(): void {
    this.onCancel.emit()
    this.resetForm()
  }

  hasToEnablePreviewButton(): boolean {
    return Boolean(
      this.releaseNote.releaseDate ||
        this.releaseNote.versionDate ||
        this.releaseNote.version
    )
  }

  previewRelease(): void {
    if (!this._isPreviewEnabled) {
      this._isPreviewEnabled = true
      this._formClass = this.formClassesWithPreview
    } else {
      this._isPreviewEnabled = false
      this._formClass = this.formClassesWithNoPreview
    }
  }

  deleteNote(note: string): void {
    this.releaseNote.contents = this.releaseNote.contents.filter(
      (c) => c != note
    )
  }

  editNote(note: string): void {
    this._previousReleaseNoteItem = note
    this._releaseNoteItem = note
  }

  isFormValid(): boolean {
    return Boolean(
      this.releaseNote.version &&
        this.releaseNote.type &&
        this.releaseNote.releaseDate &&
        this.releaseNote.versionDate &&
        (!this.isSdkServer()
          ? this.releaseNote.download && this.isDownloadLinkValid()
          : true) &&
        this.releaseNote.contents &&
        this.releaseNote.contents?.length > 0
    )
  }

  isDownloadLinkValid(): boolean {
    return this.urlHelper.isUrlValid(this.releaseNote.download)
  }

  isSdkServer(): boolean {
    return this.releaseNote.type === SdkType.SERVER
  }

  private resetForm(): void {
    this._releaseNoteItem = ''
    this._isPreviewEnabled = false
    this._formClass = this.formClassesWithNoPreview
    this._previousReleaseNoteItem = ''
    this.releaseNote = new SdkReleaseCreate()
    this._isSubmitted = false
    this._initialReleaseDate = this._initialVersionDate = null
  }
}
