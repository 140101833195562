import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { SnackBarService } from '../components/snack-bar.service'
import { SpinnerService } from '../components/spinner/spinner.service'
import { environment } from 'src/environments/environment'
import { StorageHelper } from '../helpers/storage.helper'

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private spinnerService: SpinnerService,
    private snackBar: SnackBarService,
    private storageHelper: StorageHelper
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.spinnerService.requestStarted() // All requests start a spinner loading component

    const authHeaders = {}
    if (req.url.includes('/bio-analytics/api')) {
      authHeaders[environment.bioAnalyticsApi.authHeader] =
        environment.bioAnalyticsApi.authKey

      if (environment.bioAnalyticsApi.language)
        authHeaders['Accept-Language'] = environment.bioAnalyticsApi.language
    } else {
      authHeaders['Authorization'] = `Bearer ${this.storageHelper.getBy(
        'token'
      )}`
    }

    const r = req.clone({
      responseType: 'json',
      setHeaders: authHeaders,
    })
    return this.handle(next, r)
  }

  handle(next, req) {
    return next.handle(req).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            this.spinnerService.requestEnded() // closes spinner loading
          }
        },
        (error: HttpErrorResponse) => {
          this.spinnerService.resetSpinner() // closes spinner loading
          this.redirectAndShowError(error)
          throw error
        }
      )
    )
  }

  redirectAndShowError(error: HttpErrorResponse) {
    try {
      let msg = ''
      if (error.status == 401) {
        msg = 'Sessão expirada! Redirecionando para Login.'
        this.router.navigate(['/login'])
      } else if (error.error && error.error?.message) {
        msg = error.error.message
      } else {
        msg = error.message
      }
      this.snackBar.error(msg, 5, 'top', 'right')
    } catch (error) {
      console.error('error on redirectAndShowError: ', error)
    }
  }
}
