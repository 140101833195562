import { Component, OnInit } from '@angular/core'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { Constants } from 'src/app/core/helpers/constants'
import { GenericHelper } from 'src/app/core/helpers/generic.helper'
import {
  Translation,
  TranslationList,
} from '../../domain/models/translation.model'
import { GetTranslationsByPage } from '../../domain/usecases/get-translations-by-page'
import { SaveTranslation } from '../../domain/usecases/save-translation'

@Component({
  selector: 'app-list-translations',
  templateUrl: './list-translations.component.html',
  styleUrls: ['./list-translations.component.css'],
})
export class ListTranslationsComponent implements OnInit {
  translationDialog: boolean
  submitted: boolean
  translationFilter: Translation = new Translation()

  translations: Translation[] = []

  count: number = 0

  translationList: TranslationList

  translation = new Translation()

  localeList: any[] = [
    { value: null, label: Constants.optionSelecione },
    { value: 'pt_BR', label: 'Português' },
    { value: 'es_ES', label: 'Espanhol' },
  ] // english not needed here

  constructor(
    private snackBar: SnackBarService,
    private genericHelper: GenericHelper,
    private getTranslationsByPage: GetTranslationsByPage,
    private saveNewTranslation: SaveTranslation
  ) {}

  ngOnInit() {
    this.listAllTranslations()
  }

  listAllTranslations() {
    this.translationFilter = new Translation()
    this.searchTranslations()
  }

  searchTranslations(page?: number) {
    this.getTranslationsByPage.execute(page).subscribe((data) => {
      this.translationList = data
      this.translations = this.translationList.list
      this.count = this.translationList.count
    })
  }

  openForm(translation: Translation) {
    if (translation) {
      this.translation = this.genericHelper.deepCopy(translation)
      this.translation.locale = this.localeList.find(function (arg) {
        return arg.value == translation.locale
      })
    } else {
      this.translation = new Translation()
    }
    this.translationDialog = true
  }

  hideDialog() {
    this.translationDialog = false
    this.submitted = false
  }

  changePage(event) {
    this.searchTranslations(event.page)
  }

  saveTranslation() {
    this.submitted = true

    if (!this.translation.locale || !this.translation.locale.value)
      // if select 'Selecione' option
      this.translation.locale = null

    if (
      this.translation.value &&
      this.translation.locale &&
      this.translation.translation &&
      this.translation.tableName &&
      this.translation.columnName
    ) {
      let lastLocale = this.translation.locale
      this.translation.locale = this.translation.locale.value
      this.saveNewTranslation.execute(this.translation).subscribe(
        () => {},
        (error) => {
          this.translation.locale = lastLocale
          if (error.status == 200) {
            this.snackBar.success('Tradução Salva!', 5, 'top', 'right')
            this.hideDialog()
            this.listAllTranslations()
          } else if (error.status == 400) {
            this.snackBar.error(
              'Dados de formulário inválidos!',
              5,
              'top',
              'right'
            )
          } else if (error.status == 405) {
            this.snackBar.error(
              'Sem permissões para esta ação!',
              5,
              'top',
              'right'
            )
          } else {
            this.snackBar.error('Erro desconhecido!', 5, 'top', 'right')
          }
        }
      )
    }
  }
}
