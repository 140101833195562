import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { PrimeNGConfig } from 'primeng/api'
import { DynamicDialogRef } from 'primeng/dynamicdialog'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { Endpoint } from 'src/app/core/domain/models/endpoint.model'
import { Sdk } from 'src/app/core/domain/models/sdk.model'
import { HasLoggedUserPermission } from 'src/app/core/domain/usecases/has-logged-user-permission'
import { IsLoggedUserAdministrator } from 'src/app/core/domain/usecases/is-logged-user-administrator'
import { CompanyHelper } from 'src/app/core/helpers/company.helper'
import { Constants } from 'src/app/core/helpers/constants'
import { FileHelper } from 'src/app/core/helpers/file.helper'
import { GenericHelper } from 'src/app/core/helpers/generic.helper'
import { StorageHelper } from 'src/app/core/helpers/storage.helper'
import { CompanyService } from 'src/app/core/service/company.service'
import { CompanyNew } from 'src/app/modules/companies/domain/models/company-new.model'
import {
  Transaction,
  TransactionList,
} from '../../domain/models/transaction.model'
import { FilterTransactions } from '../../domain/usecases/filter-transactions'
import { RenameSdkOldToNew } from '../../domain/usecases/rename-sdk-old-to-new'
import { TransactionService } from '../../services/transaction.service'
import { Company } from 'src/app/modules/companies/domain/models/company.model'

@Component({
  selector: 'app-list-transactions',
  templateUrl: './list-transactions.component.html',
  styleUrls: ['./list-transactions.component.css'],
})
export class ListTransactionsComponent implements OnInit {
  pageSize = 10
  transactionFilter: Transaction = new Transaction()
  transactions: TransactionList
  sdkFilter: Sdk[] = []
  endpointsFilter: Endpoint[] = []
  showCompanyFilter: boolean
  companiesFilter: CompanyNew[] = []
  companyUser: CompanyNew

  withoutPermission: string
  dialogRef: DynamicDialogRef

  constructor(
    private transactionService: TransactionService,
    private companyService: CompanyService,
    private config: PrimeNGConfig,
    private snackBar: SnackBarService,
    private router: Router,
    private genericHelper: GenericHelper,
    private hasLoggedUserPermission: HasLoggedUserPermission,
    private fileHelper: FileHelper,
    private storageHelper: StorageHelper,
    private companyHelper: CompanyHelper,
    private filterTransactions: FilterTransactions,
    private renameSdkOldToNew: RenameSdkOldToNew,
    private isLoggedUserAdministrator: IsLoggedUserAdministrator
  ) {}

  ngOnInit() {
    this.pageSize = parseInt(this.storageHelper.getBy('pageSize'))
    this.config.setTranslation(this.genericHelper.getCalendarTranslations()) // show days translated at calendar component
    this.showCompanyFilter = this.isLoggedUserAdministrator.execute()
    this.listAllSdks()
    this.listAllCompanies()

    if (!this.checkPermission('transaction.list'))
      this.withoutPermission = 'Sem permissão para listar transações!'
  }

  listAllSdks() {
    if (this.checkPermission('sdk.list')) {
      this.transactionService.listAllSdks().subscribe((data) => {
        this.transactionFilter.sdk = Constants.optionTodos
        this.sdkFilter = data
        this.loadAllEndpoints()
      })
    } else {
      this.snackBar.warn('Sem permissão para listar sdks!', 5)
    }
  }

  listAllCompanies() {
    if (this.checkPermission('company.list')) {
      this.companyService.listAllCompanies().subscribe((data) => {
        let company = new CompanyNew(Constants.optionTodas)
        this.companiesFilter.push(company)
        this.transactionFilter = new Transaction()
        this.transactionFilter.company = company
        this.companiesFilter = this.companiesFilter.concat(data.content)
        let c: Company = this.companyHelper.getCompanyFromLoggedUser()
        if (c?.name) {
          this.companyUser = this.companiesFilter.find(
            (comp) => comp.name == c.name
          )
        }

        this.searchTransactions()
      })
    } else {
      this.snackBar.warn('Sem permissão para listar companhias!', 5)
    }
  }

  loadAllEndpoints() {
    this.endpointsFilter = [new Endpoint(Constants.optionTodos)]
    this.sdkFilter.forEach((sdk) => {
      this.endpointsFilter = this.endpointsFilter.concat(sdk.endpointList)
    })
  }

  searchTransactions(page?: number, report?: boolean) {
    if (this.checkPermission('transaction.list')) {
      this.withoutPermission = null

      if (this.companyUser) this.transactionFilter.company = this.companyUser
      else if (
        !this.transactionFilter.company ||
        this.transactionFilter.company.name == Constants.optionTodas
      )
        this.transactionFilter.company = new CompanyNew()

      if (this.transactionFilter.company)
        this.transactionFilter.cnpj = this.transactionFilter.company.cnpj

      // if (this.transactionFilter.sdkFilter && this.transactionFilter.sdkFilter.name != this.optionTodos)
      //   this.transactionFilter.sdk = this.transactionFilter.sdkFilter.name;
      // else
      //   this.transactionFilter.sdk = null;

      if (
        this.transactionFilter.endpointFilter &&
        this.transactionFilter.endpointFilter.description !=
          Constants.optionTodos
      ) {
        this.transactionFilter.sdk =
          this.transactionFilter.endpointFilter.sdk.name
        this.transactionFilter.path = this.transactionFilter.endpointFilter.name
      } else {
        this.transactionFilter.sdk = null
        this.transactionFilter.path = null
      }

      this.filterTransactions
        .execute({
          data: this.transactionFilter,
          page: page,
          purpose: report ? 'REPORT' : 'LIST',
        })
        .subscribe((data) => {
          let self = this
          data.list.forEach(function (d) {
            d.sdk = self.renameSdkOldToNew.execute(d.sdk)
            let sdk = self.sdkFilter.find((s) => s.name == d.sdk)
            if (sdk) {
              // gets endpoint object according path (internal name of endpoint called at CORE)
              let endpoint = sdk.endpointList?.find((e) => e.name == d.path)
              if (endpoint) d.path = endpoint.description
            }
            if (isNaN(Date.parse(d.date))) d.date = null
          })
          if (report) {
            // to generates transaction report
            if (data.count == 0) {
              this.snackBar.info(
                'Não há dados para gerar o relatório!',
                5,
                'top',
                'right'
              )
              return
            }
            this.report(data.list)
          } else {
            // to list transactions at table component
            this.transactions = data
            if (data.count > 0) {
              this.transactions.list.forEach(function (t) {
                // at CORE the CNPJ is identified by credentialUser (sent at header in each SDK endpoint call)
                t.company = self.companiesFilter.find(
                  (c) => c.cnpj == t.credentialUser
                )
                if (!t.date) t.date = new Date().toString()
              })
            }
          }
        })
    }
  }

  changePage(event) {
    this.searchTransactions(event.page)
  }

  // Shows details of transaction
  showFullTransaction(event: Event, transaction: Transaction) {
    this.router.navigateByUrl('transactions/detail', {
      state: {
        data: transaction,
      },
    })
  }

  report(list: Transaction[]) {
    import('xlsx').then((xlsx) => {
      let report = [...list]
      report.forEach(function (t) {
        // format CNPJ with mask
        t.credentialUser = t.credentialUser.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5'
        )
        t.success = t.success ? 'Sim' : 'Não'
        t.error = t.error ? 'Sim' : 'Não'
        t.sdk = null
      })
      const headers: any = {
        _id: 'ID',
        tid: 'TID',
        credentialUser: 'CNPJ',
        path: 'SERVIÇO',
        date: 'DATA/HORA',
        success: 'SUCESSO',
        error: 'ERRO',
        status: 'STATUS',
      }
      report.unshift(headers) // adds headers at beginning to show ordered columns
      let order = {
        header: [
          '_id',
          'tid',
          'credentialUser',
          'path',
          'date',
          'success',
          'error',
          'status',
        ],
        skipHeader: true,
      }
      let worksheet = xlsx.utils.json_to_sheet(report, order)
      let csv = xlsx.utils.sheet_to_csv(worksheet, { FS: ';' })
      this.fileHelper.saveAsCsvFile(csv, 'transactions')
    })
  }

  checkPermission(permission: string): boolean {
    return this.hasLoggedUserPermission.execute(permission)
  }
}
