<form>
  <div class="transaction-field">
    <label>ID:</label>
    <span>{{ transaction?._id || '' }}</span>
  </div>
  <div class="transaction-field">
    <label>TID:</label>
    <span>{{ transaction.tid || '' }}</span>
  </div>
  <div class="transaction-field">
    <label>ExternalTID:</label>
    <span>{{ transaction.externalTid || '' }}</span>
  </div>
  <div class="transaction-field">
    <label>InstallationID:</label>
    <span>{{ transaction.installationId || '' }}</span>
  </div>
  <div class="transaction-field">
    <label>SDK:</label>
    <span>{{ transaction.sdk || '' }}</span>
  </div>
  <div class="transaction-field">
    <label>Companhia:</label>
    <span>{{ transaction.company?.name || '' }}</span>
  </div>
  <div class="transaction-field">
    <label>Companhia CNPJ:</label>
    <span>{{ transaction.company?.cnpj || '' }}</span>
  </div>
  <div class="transaction-field">
    <label>Serviço:</label>
    <span>{{ transaction.path || '' }}</span>
  </div>
  <div class="transaction-field">
    <label>Data/hora:</label>
    <span>{{ transaction.date ? (transaction.date | date : 'dd/MM/yyyy HH:mm:ss'):'' }}</span>
  </div>
  <div class="transaction-field">
    <label>Sucesso:</label>
    <span>{{ transaction.success ? 'Sim' : 'Não' }}</span>
  </div>
  <div class="transaction-field">
    <label>Error: </label>
    <span>{{ transaction.error ? 'Sim': 'Não' }}</span>
  </div>
  <div class="transaction-field">
    <label>IP do cliente:</label>
    <span>{{ transaction.clientIp || '' }}</span>
  </div>
  <div class="transaction-field" *ngIf="transaction.error">
    <label>Mensagem de erro:</label>
    <span>{{ transaction.errorMessage || '' }}</span>
  </div>
</form>