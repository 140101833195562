import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Log, LogList } from '../domain/models/log.model'

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private apiUrl = `${environment.apiUrl}`
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  }

  constructor(private httpClient: HttpClient) {}

  filterLog(logFilter: Log, page: number): Observable<LogList> {
    return this.httpClient.post<LogList>(
      this.apiUrl + 'log/list?page=' + page,
      logFilter,
      this.httpOptions
    )
  }
}
