import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import * as ChartDataLabels from 'chartjs-plugin-datalabels'
import { TransactionsPercentageSummaryBarChartData } from '../../domain/models/transactions-percentage-summary-bar-chart-data'

@Component({
  selector: 'app-transactions-percentage-summary-bar-chart',
  templateUrl: './transactions-percentage-summary-bar-chart.component.html',
  styleUrls: ['./transactions-percentage-summary-bar-chart.component.css'],
})
export class TransactionsPercentageSummaryBarChartComponent
  implements OnChanges
{
  @Input() data!: TransactionsPercentageSummaryBarChartData

  _datasets: {
    labels: string[]
    values: { label: string; backgroundColor: string; data: number[] }[]
  }

  _options = {
    plugin: ChartDataLabels,
    plugins: {
      datalabels: {
        rotation: {},
        formatter: (value, ctx) => {
          return value == 0 ? '' : value + '%'
        },
        color: 'white',
        font: {
          weight: 'bold',
          size: 14,
        },
      },
    },
    yAxes: {
      interval: [0, 100],
      formatLabel: (label: number) => {
        return `${label}%`
      },
    },
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data.currentValue) {
      this.setData(changes.data.currentValue)
      this.setOptions(changes.data.currentValue)
    }
  }

  private setData(data: TransactionsPercentageSummaryBarChartData): void {
    if (
      data.labels &&
      data.successPercentageData &&
      data.failedPercentageData
    ) {
      this._datasets = {
        labels: data.labels,
        values: [
          {
            label: 'Aprovados',
            backgroundColor: '#211858',
            data: data.successPercentageData.map((it) => Number(it)),
          },
          {
            label: 'Rejeitados',
            backgroundColor: '#FF6D40',
            data: data.failedPercentageData.map((it) => Number(it)),
          },
        ],
      }
    }
  }

  private setOptions(
    datasets: TransactionsPercentageSummaryBarChartData
  ): void {
    this._options.plugins.datalabels.rotation = (ctx) => {
      return datasets?.labels?.length >= 15 ? 270 : 0
    }
  }
}
