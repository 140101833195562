import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CompanyNew } from '../../../modules/companies/domain/models/company-new.model'
import { HasLoggedUserPermission } from '../../domain/usecases/has-logged-user-permission'
import { IsLoggedUserAdministrator } from '../../domain/usecases/is-logged-user-administrator'
import { CompanyHelper } from '../../helpers/company.helper'
import { Constants } from '../../helpers/constants'
import { CompanyService } from '../../service/company.service'

interface CompanyFilterData {
  id: string
  name: string
}
@Component({
  selector: 'app-company-filter',
  templateUrl: './company-filter.component.html',
  styleUrls: ['./company-filter.component.css'],
})
export class CompanyFilterComponent implements OnInit {
  @Input() companiesFilterS: string[]
  @Input() companyFromDropdown: CompanyNew
  @Input() activeCompany?: string
  @Input() hasAllCompanies: boolean
  @Input() groupClasses?: string
  @Input() labelClasses?: string
  @Input() selectClasses?: string
  @Input() groupStyle?: string
  @Input() labelStyle?: string
  @Input() selectStyle?: string
  @Output() selectedCompany = new EventEmitter<CompanyFilterData>()

  showCompanyFilter = false
  companiesFilter: CompanyNew[] = []

  constructor(
    private hasLoggedUserPermission: HasLoggedUserPermission,
    private companyService: CompanyService,
    private companyHelper: CompanyHelper,
    private isLoggedUserAdministrator: IsLoggedUserAdministrator
  ) {}

  ngOnInit(): void {
    if (this.isLoggedUserAdministrator.execute()) {
      this.listAllCompanies()
      this.showCompanyFilter = true
    }
  }

  listAllCompanies(): void {
    if (this.hasLoggedUserPermission.execute('company.list')) {
      this.companyService.listAllCompanies().subscribe((data) => {
        this.companiesFilter = <any>(data.content || []).map((c) => ({
          id: c.cnpj,
          name: c.name,
        }))

        const c = this.companyHelper.getCompanyFromLoggedUser()
        if (this.activeCompany) {
          this.companyFromDropdown = this.companiesFilter.find(
            (comp) => comp.id.toString() == this.activeCompany
          )
        } else if (c?.name) {
          this.companyFromDropdown = this.companiesFilter.find(
            (comp) => comp.name === c.name
          )
        } else {
          this.companyFromDropdown = this.companiesFilter.find(
            (comp) => comp.id.toString() === Constants.certisignCNPJ
          )
        }

        if (this.hasAllCompanies) {
          this.companiesFilter.unshift(
            new CompanyNew(Constants.optionTodas, '')
          )
        }
      })
    } else {
      console.warn('Sem permissão para listar companhias!')
    }
  }

  onSelectedCompany(data: CompanyFilterData): void {
    this.selectedCompany.emit(data)
  }
}
